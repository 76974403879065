import React, { Component } from 'react';
import {
  Button, Container, Grid, Switch, Typography, Divider, withStyles,
} from '@material-ui/core';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import PlaceIcon from '@material-ui/icons/Place';
import { isEmpty, isEqual } from 'lodash';
import { withRouter } from 'react-router';
import { ContactInfoStyle } from '../../css/style';
import LoadingBar from '../subcomponents/LoadingBar';
import { IMG_STORAGE_LINK } from '../constants/Constants';

const data = [
  {
    id: 'globalChanelNotification',
    emailKey: 'isGlobalEmailNotification',
    smsKey: 'isGlobalSmsNotification',
    title: '',
    isGlobal: true,
    isLastItem: true,
  }, {
    id: 'realTimeNotification',
    title: 'Direct Messages from Practices (*important)',
    emailKey: 'isRealTimeEmailNotification',
    smsKey: 'isRealTimeSmsNotification',
    isRequiredOne: true,
    subHeading: 'Notify me about:',
  }, {
    id: 'boostNotification',
    title: 'Boosted Shifts (extra incentive)',
    emailKey: 'isBoostEmailNotification',
    smsKey: 'isBoostSmsNotification',
  }, {
    id: 'isConfirmedShiftEmailNotification',
    title: 'Confirmed Shifts (includes calendar invite)',
    emailKey: 'isConfirmedShiftEmailNotification',
  }, {
    id: 'isFastpassLocationEmailNotification',
    title: 'Fastpass Locations Granted',
    emailKey: 'isFastpassLocationEmailNotification',
  }, {
    id: 'isNewConnectionEmailNotification',
    title: 'New Connections Made',
    emailKey: 'isNewConnectionEmailNotification',
  }, {
    id: 'isNewShiftsConnectedEmailNotification',
    title: 'Connected Match New Dates (Daily):',
    emailKey: 'isNewShiftsConnectedEmailNotification',
  }, {
    id: 'isNewShiftsUnconnectedEmailNotification',
    title: 'Unconnected Match New Dates (Weekly):',
    emailKey: 'isNewShiftsUnconnectedEmailNotification',
    isLastItem: true,
  }
];

const subTierSmsFields = [
  'isRealTimeSmsNotification',
  'isBoostSmsNotification',
];

const subTierEmailFields = [
  'isRealTimeEmailNotification',
  'isBoostEmailNotification',
  'isConfirmedShiftEmailNotification',
  'isFastpassLocationEmailNotification',
  'isNewConnectionEmailNotification',
  'isNewShiftsConnectedEmailNotification',
  'isNewShiftsUnconnectedEmailNotification',
];

class NotificationPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  initState() {
    const { user, resources } = this.props;
    const { savedsearchs } = resources;
    const userNotifications = resources[`user-notifications/user/${user.id}`];

    this.setState({
      isGlobalNotification: userNotifications.isGlobalNotification,
      isGlobalEmailNotification: userNotifications.isGlobalEmailNotification,
      isGlobalSmsNotification: userNotifications.isGlobalSmsNotification,

      isRealTimeSmsNotification: userNotifications.isRealTimeSmsNotification,
      isRealTimeEmailNotification: userNotifications.isRealTimeEmailNotification,
      isBoostSmsNotification: userNotifications.isBoostSmsNotification,
      isBoostEmailNotification: userNotifications.isBoostEmailNotification,

      isNewConnectionEmailNotification: userNotifications.isNewConnectionEmailNotification,
      isNewShiftsConnectedEmailNotification: userNotifications.isNewShiftsConnectedEmailNotification,
      isNewShiftsUnconnectedEmailNotification: userNotifications.isNewShiftsUnconnectedEmailNotification,
      isConfirmedShiftEmailNotification: userNotifications.isConfirmedShiftEmailNotification,
      isFastpassLocationEmailNotification: userNotifications.isFastpassLocationEmailNotification,
      savedSearchNotifications: [],
      userNotifications,
    });

    if (!isEmpty(savedsearchs)) {
      const savedSearchNotifications = savedsearchs.map(search => ({
        id: search.id,
        title: search.title,
        color: search.color,
        address: search.address,
        hasEmail: search.hasEmail,
        hasSms: search.hasSms,
      }));
      this.setState({ savedSearchNotifications });
    }
  }

  async componentDidMount() {
    const {
      actions, user, childRef,
    } = this.props;
    childRef(this);

    await actions.getAllResources(user.token, `user-notifications/user/${user.id}`);
    await actions.getAllResources(user.token, 'savedsearchs');
    this.initState();
  }

  componentDidUpdate(prevProps) {
    const { resources } = this.props;
    const { savedsearchs } = resources;

    if (savedsearchs) {
      const preSavedsearchs = prevProps?.resources?.savedsearchs;
      if (preSavedsearchs && !isEqual(preSavedsearchs, savedsearchs)) {
        const savedSearchNotifications = savedsearchs.map(search => ({
          id: search.id,
          title: search.title,
          color: search.color,
          address: search.address,
          hasEmail: search.hasEmail,
          hasSms: search.hasSms,
        }));
        this.setState({ savedSearchNotifications });
      }
    }
  }

  saveChanges = () => {
    const { actions, user } = this.props;
    const {
      isGlobalNotification,
      isGlobalEmailNotification,
      isGlobalSmsNotification,

      isRealTimeSmsNotification,
      isRealTimeEmailNotification,

      isBoostEmailNotification,
      isBoostSmsNotification,

      isNewConnectionEmailNotification,
      isConfirmedShiftEmailNotification,
      isFastpassLocationEmailNotification,
      isNewShiftsConnectedEmailNotification,
      isNewShiftsUnconnectedEmailNotification,
      savedSearchNotifications,
    } = this.state;

    const updatedNotification = {
      isGlobalNotification,
      isGlobalEmailNotification,
      isGlobalSmsNotification,

      isRealTimeSmsNotification,
      isRealTimeEmailNotification,

      isBoostEmailNotification,
      isBoostSmsNotification,

      isNewConnectionEmailNotification,
      isConfirmedShiftEmailNotification,
      isFastpassLocationEmailNotification,
      isNewShiftsConnectedEmailNotification,
      isNewShiftsUnconnectedEmailNotification,
      savedSearchNotifications: savedSearchNotifications?.map(ss => ({ id: ss.id, hasEmail: ss.hasEmail, hasSms: ss.hasSms })),
    };

    actions.updateResource('Notification', user.token, updatedNotification, 'user-notifications')
    .then(() => {
      actions.addResource('log-event', user.token, { action: 'talent_profile' }, 'users', 'log-event');
      actions.getAllResources(user.token, 'savedsearchs');
    });
  };

  renderSavedSearchNotifications = () => {
    const { classes } = this.props;
    const { isGlobalNotification, savedSearchNotifications } = this.state;
    if (!isGlobalNotification || isEmpty(savedSearchNotifications)) return null;

    return (
      <>
        <Grid
          key='savedSearchLabel'
          item
          xs={12}
        >
          <Typography color="secondary" className={classes.heading}>Whisker Bot Search Notifications</Typography>
        </Grid>

        {savedSearchNotifications.map(search => {
          return (
            <Grid container item xs={12} className={classes.item} key={`${search.id}_container`}>
              <Grid item xs={12} className={classes.item} key={`${search.id}_title`}>
                <FiberManualRecordRoundedIcon className={classes.searchIcon} style={{color: `#${search.color}`}}/>
                <Typography color="secondary" className={classes.searchTitle}>
                  {search.title === 'Externship Work Near Me' ? 'Externships Near Me' : search.title}
                </Typography>
                <img src={`${IMG_STORAGE_LINK}wb_search.png`} style={{ marginLeft: 8, width: '65px', height: '14px' }} />  
              </Grid>
              {search.address && (<Grid item xs={12} className={classes.item} key={`${search.id}_location`}>
                <PlaceIcon fontSize="small" className={classes.icon} />
                <Typography variant="body2" className={classes.ellipsis}>{search.address}</Typography>
              </Grid>
              )}
              {this.renderNotificationItem({
                subHeading: 'New Match Notifications:',
                smsKey: 'hasSms',
                emailKey: 'hasEmail',
                savedSearchId: search.id,
                isLastItem: true,
              }, `ss_${search.id}`)}
            </Grid>
          );
        })}
        
      </>
    );


  };

  renderNotificationItem = (item, key) => {
    const { classes, user } = this.props;
    const { isMobileVerified, isVerified } = user;
    const { isGlobalNotification, isGlobalSmsNotification, isGlobalEmailNotification, savedSearchNotifications } = this.state;

    if (!isGlobalNotification) return null;

    const switchClassObj = {
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    };

    let savedSearchesArr = [...savedSearchNotifications];
    let savedSearchNoti = savedSearchesArr && item.savedSearchId ? savedSearchesArr.find(s => s.id === item.savedSearchId) : {};
    const smsValue = item.savedSearchId ? savedSearchNoti?.hasSms : this.state[item.smsKey];
    const emailValue = item.savedSearchId ? savedSearchNoti?.hasEmail : this.state[item.emailKey];
    const smsElement = document.getElementById('SMS Notifications');

    return (
      <>
        {item.subHeading && (
          <Grid
            key={`${key}_subheading`}
            item
            xs={12}
          >
            <Typography color="secondary" className={classes.subHeading} key={`${key}_subheading_text`}>
              {item.subHeading}
            </Typography>
          </Grid>)
        }
        <Grid
          key={`${key}_item`}
          item
          xs={12}
        >
          {item.title && (<Typography className={classes.label} key={`${key}_subheading_title`}>{item.title}</Typography>)}
          
          <div className={classes.notificationContainer}>
            {item.smsKey && (
              <div className={classes.switchContainer}>
                <Typography className={classes.smallLabel} key={`${key}_sLabel`}>{item.isGlobal ? 'Global SMS' : 'SMS'}</Typography>
                <Switch
                  checked={!!smsValue}
                  focusVisibleClassName={classes.focusVisible}
                  disableRipple
                  classes={switchClassObj}
                  key={`${key}_${item.smsKey}`}
                  disabled={!isMobileVerified || (!isGlobalSmsNotification && !!(item.savedSearchId || subTierSmsFields.includes(item.smsKey)))}
                  onChange={e => {
                    // jump to mobile field
                    if(!isMobileVerified && smsElement) {
                      smsElement.focus();
                      return;
                    }
                    const value = e.target.checked;
                    if (item.savedSearchId) {
                      savedSearchesArr = savedSearchesArr.map(ss => ({ ...ss, hasSms: ss.id === item.savedSearchId ? value : ss.hasSms }));
                      this.setState({ savedSearchNotifications: savedSearchesArr });
                    } else {
                      this.setState({[item.smsKey]: value });
                      if (item.isRequiredOne && !value) {
                        this.setState({[item.emailKey]: !value });

                        // Keep SMS on b/c the email is not verified --> can't send email
                        if (!isVerified) {
                          this.setState({[item.smsKey]: true });
                        }
                      }
                    }

                    // Set off the non-global ones if the global is off
                    if (!value && item.smsKey === 'isGlobalSmsNotification') {
                      subTierSmsFields.forEach(field => {
                        this.setState({[field] : value});
                      });
                      savedSearchesArr = savedSearchesArr.map(ss => ({ ...ss, hasSms: value }));
                      this.setState({ savedSearchNotifications: savedSearchesArr });
                    }
                  }}
                />
              </div>
            )}
            <div className={classes.switchContainer}>
              <Typography className={classes.smallLabel} key={`${key}_sLabel_email`}>{item.isGlobal ? 'Global Email' : 'Email'}</Typography>
              <Switch
                checked={!!emailValue}
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                classes={switchClassObj}
                key={`${key}_${item.emailKey}`}
                disabled={!isVerified
                    || (!isGlobalEmailNotification && !!(item.savedSearchId || subTierEmailFields.includes(item.emailKey)))}
                onChange={e => {
                  const value = e.target.checked;
                  if (item.savedSearchId) {
                    savedSearchesArr = savedSearchesArr.map(ss => ({ ...ss, hasEmail: ss.id === item.savedSearchId ? value : ss.hasEmail }));
                    this.setState({ savedSearchNotifications: savedSearchesArr });
                  } else {
                    this.setState({[item.emailKey]: value });
                    if (item.isRequiredOne && !value) {
                      this.setState({[item.smsKey]: !value });

                      // Keep email is on b/c the mobile is not verified --> can't send SMS
                      if (!isMobileVerified) {
                        this.setState({[item.emailKey]: true });
                      }
                    }
                  }

                  // Set off the non-global ones if the global is off
                  if (!value && item.emailKey === 'isGlobalEmailNotification') {
                    subTierEmailFields.forEach(field => {
                      this.setState({[field] : value});
                    });
                    savedSearchesArr = savedSearchesArr.map(ss => ({ ...ss, hasEmail: value }));
                    this.setState({ savedSearchNotifications: savedSearchesArr });
                  }
                }}
              />
            </div>
          </div>
        </Grid>
        {item.isLastItem && (
          <Grid
            key={`${key}_divider_item`}
            item
            xs={12}
          >
            <Divider style={{ margin: '8px 0' }} key={`${key}_divider`} />
          </Grid>
        )}
      </>
    );
  };

  render() {
    const { classes } = this.props;
    const { userNotifications, isGlobalNotification } = this.state;

    const switchClassObj = {
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    };

    return (
      <Container className={classes.container}>
        <Typography className={classes.title}>
          NOTIFICATIONS
        </Typography>
        { isEmpty(userNotifications) ? (<LoadingBar size={40} noText />)
        : (<Grid direction="column" container justifyContent="center" alignItems="center" key='noti_loading'>
          <Grid
            className={classes.grid}
            key='noti_container_1'
            direction="row"
            alignContent="space-between"
            container
            spacing={2}
          >
            <Grid
              key='globalLabel'
              item
              xs={6}
              md={4}
            >
              <Typography color="secondary" className={classes.heading} key='globalText'>
                {`Notifications ${isGlobalNotification ? 'On' : 'Off'}`}
              </Typography>
            </Grid>
            <Grid
              key='globalItem'
              item
              xs={6}
              md={8}
              style={{display: 'flex', justifyContent: 'flex-start'}}
            > 
              <Switch
                checked={!!isGlobalNotification}
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                classes={switchClassObj}
                key='globalSwitch'
                onChange={e => {
                  const value = e.target.checked;
                  this.setState({ isGlobalNotification: value });
                }}
              />
            </Grid>
            <Grid
              key='globalDesc'
              item
              xs={12}
            >
              <Typography key='globalInfo'>
                {isGlobalNotification
                  ? `If toggled off, you won\'t get any notifications. You will only be able to see new messages, 
                  connections and matches when you log into Jobs Unleashed.`
                  : 'When notifications are turned on, you may choose to receive the ones relevant to you.'
                }
              </Typography>
            </Grid>
            {data?.map((item, index) => this.renderNotificationItem(item, index))}
            {this.renderSavedSearchNotifications()}
            <Grid item xs={12} key='saveItem'>
              <Button
                color="primary"
                variant="contained"
                className={classes.buttonSecondary}
                key='saveBtn'
                onClick={e => this.saveChanges()}
              >
                SAVE CHANGES
              </Button>
            </Grid>
          </Grid>
        </Grid>
        )}
      </Container>
    );
  }
}

export default withStyles(ContactInfoStyle)(withRouter(NotificationPanel));
