import Grid from '@material-ui/core/Grid/index';
import { Typography } from '@material-ui/core/index';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { VETENARIAN_COLLEGE_SPECIALITIES, VETENARIAN_COLLEGES } from '../../../config/enums';
import { OnboardingPageStyle, SimpleSelectableRectangleNoIconStyle } from '../../../css/style';
import OnboardingItemComponent from './OnboardingItemComponent';
import SimpleSelectableIconComponent from './SimpleSelectableIconComponent';


class SpecialityCollegeItemComponent extends OnboardingItemComponent {
  constructor(props) {
    const { initState, handleStateChange, colleges } = props;
    super(props);
    const st = {};
    const items = [];
    initState.map((item) => {
      st[`${item.strValue}-${item.str2Value}`] = true;
      st[`${item.strValue}`] = 0;
      items.push({
        criteria: 'speciality',
        strValue: item.strValue,
        str2Value: item.str2Value,
      });
    });
    colleges.map((college) => {
      st[college] = 0;
    });
    handleStateChange && handleStateChange(items);
    this.state = st;
    this.handleState();
  }

  handleState() {
    const {
      verifyCriteriaDataFilled, colleges, setOnboardingItems, disableContinue,
    } = this.props;
    const stateObj = this.state;
    const items = [];
    const collegesFilled = new Map();
    colleges.forEach(college => collegesFilled.set(college, false));
    VETENARIAN_COLLEGES.forEach((college) => {
      if (VETENARIAN_COLLEGE_SPECIALITIES[college]) {
        VETENARIAN_COLLEGE_SPECIALITIES[college].forEach((speciality) => {
          if (this.state[`${college}-${speciality}`]) {
            collegesFilled.set(college, true);
            items.push({
              criteria: 'speciality',
              strValue: college,
              str2Value: speciality,
            });
          }
        });
      }
    });
    setOnboardingItems && setOnboardingItems(items);
    const allCollegesHasValues = ![...collegesFilled.values()]
      .filter(x => !x).length;
    if (allCollegesHasValues) {
      verifyCriteriaDataFilled();
    } else {
      disableContinue();
    }
  }

  typeClicked(college, specialty) {
    const stateObj = this.state;
    let collegeSpecialtySelected = stateObj[`${college}-${specialty}`];
    const st = {};
    st[`${college}-${specialty}`] = !collegeSpecialtySelected;
    if (!collegeSpecialtySelected) {
      st[college] = this.state[college] + 1;
    } else {
      st[college] = this.state[college] - 1;
    }
    this.setState(st, () => this.handleState());
  }

  render() {
    const { colleges, classes } = this.props;
    return (
      <div>
        {
          colleges.map(college => (
            <div key={college}>
              <Typography color="secondary" className={classes.title}>
                {`${college.toUpperCase()} SPECIALTY`}
              </Typography>
              <Grid container spacing={1} justifyContent="center">
                {
                  VETENARIAN_COLLEGE_SPECIALITIES[college].map(speciality => (
                    <Grid item key={speciality}>
                      <SimpleSelectableIconComponent
                        title={speciality.replace('-', ' ')
                          .toUpperCase()}
                        selected={this.state[`${college}-${speciality}`]}
                        style={SimpleSelectableRectangleNoIconStyle}
                        onClick={e => this.typeClicked(college, speciality)}
                      />
                    </Grid>
                  ))}
              </Grid>
            </div>
          ))}
      </div>
    );
  }
}

SpecialityCollegeItemComponent.propTypes = {
  handleStateChange: PropTypes.func.isRequired,
  colleges: PropTypes.array.isRequired,
  initState: PropTypes.array.isRequired,
  setOnboardingItems: PropTypes.func.isRequired,
  verifyCriteriaDataFilled: PropTypes.func.isRequired,
};

SpecialityCollegeItemComponent.defaultProps = {
  initState: [],
  colleges: [],
};

export default withStyles(OnboardingPageStyle)(withRouter(SpecialityCollegeItemComponent));
