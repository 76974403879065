import { Button, TextField, Typography, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { PropTypes } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { EmailBarStyle, orange } from '../../css/style';
import { LocationSearchInput } from './LocationSearchInput';

class EmailBar extends Component {
  constructor(props) {
    super(props);
    const { defaultEmail, defaultPassword, invalidLoginEmail, invalidLoginPassword } = props;
    this.state = {
      email: defaultEmail,
      password: defaultPassword,
      firstname: defaultEmail,
      lastname: defaultPassword,
      location: JSON.parse(sessionStorage.getItem('firstWBSearchLocation')) || { address: '' },
      invalidLoginEmail,
      invalidLoginPassword,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      invalidLoginEmail: nextProps.invalidLoginEmail,
      invalidLoginPassword: nextProps.invalidLoginPassword,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
  };

  render() {
    const {
      classes,
      emailPlaceHolder,
      passwordPlaceHolder,
      buttonColor,
      buttonText,
      onClick,
      isNameBar,
      locationField,
      isLoginForm,
      onEmailChange,
      onPasswordChange,
      isExtern,
    } = this.props;

    const {
      email,
      password,
      firstname,
      lastname,
      location,
      invalidLoginPassword,
      invalidLoginEmail,
    } = this.state;

    const firstField = isNameBar ? firstname : email;
    const secondField = isNameBar ? lastname : password;
    const thirdField = locationField && location;

    return isLoginForm
      ? (
        <div
          className={classes.emailBarContainer}
          style={{ height: 'auto' }}
        >
          <Grid container spacing={1}>
            <Grid item lg={6} xs={12}>
              <TextField
                autoComplete="off"
                id="email"
                className={classes.emailInput}
                variant="outlined"
                placeholder={emailPlaceHolder}
                onChange={(event) => {
                  this.handleChange(event);
                  onEmailChange();
                }}
                defaultValue={email}
                InputProps={{ className: classes.emailInputText }}
                inputProps={{ autoCapitalize: 'off' }}
                error={Boolean(invalidLoginEmail)}
              />
            </Grid>
            <Grid item xs={12} lg={6} className={classes.loginErrorContainer}>
              { invalidLoginEmail
                && (
                <Typography
                  id="emailError"
                  className={classes.loginError}
                >
                  {invalidLoginEmail.includes('Wrong user')
                    ? (
                    <Fragment>
                      {`${invalidLoginEmail} - Go to `}
                      <a style={{ color: orange }} href='https://employers.jobsunleashed.com'>Practice login</a>
                    </Fragment>
                    )
                    : invalidLoginEmail
                  }
                </Typography>
                )
              }
            </Grid>
            <Grid item xs={12} lg={6}>
              <TextField
                autoComplete="off"
                id="password"
                className={classes.emailInput}
                variant="outlined"
                type="password"
                placeholder={passwordPlaceHolder}
                onChange={(event) => {
                  this.handleChange(event);
                  onPasswordChange();
                }}
                defaultValue={password}
                InputProps={{ className: classes.emailInputText }}
                inputProps={{ autoCapitalize: 'off' }}
                onKeyPress={event => (event.key === 'Enter') && onClick(firstField, secondField)}
                error={Boolean(invalidLoginPassword)}
              />
            </Grid>
            <Grid item xs={12} lg={6} className={classes.loginErrorContainer}>
              { invalidLoginPassword
                && (
                <Typography
                  id="passwordError"
                  className={classes.loginError}
                >
                  {invalidLoginPassword}
                </Typography>
                )
              }
            </Grid>
            <Grid item xs={12} lg={6}>
              <Button
                className={classes.emailSubmitButton}
                variant="contained"
                color={buttonColor}
                onClick={() => onClick(firstField, secondField)}
              >
                {buttonText}
              </Button>
            </Grid>
            <Grid item xs={false} lg={6} />
          </Grid>
        </div>
      )
      : (
        <div
          className={classes.emailBarContainer}
          style={{ height: (locationField && !isExtern) ? '240px' : '155px' }}
        >
          <TextField
            autoComplete="off"
            id={isNameBar ? 'firstname' : 'email'}
            className={classes.emailInput}
            variant="outlined"
            placeholder={emailPlaceHolder}
            onChange={(event) => {
              this.handleChange(event);
            }}
            defaultValue={email}
            InputProps={{ className: classes.emailInputText }}
            inputProps={{ autoCapitalize: isNameBar ? 'on' : 'off', className: isNameBar ? classes.capitalize : '' }}
          />
          <TextField
            autoComplete="off"
            id={isNameBar ? 'lastname' : 'password'}
            className={classes.emailInput}
            variant="outlined"
            type={isNameBar ? 'text' : 'password'}
            placeholder={passwordPlaceHolder}
            onChange={(event) => {
              this.handleChange(event);
            }}
            defaultValue={password}
            InputProps={{ className: classes.emailInputText }}
            inputProps={{ autoCapitalize: isNameBar ? 'on' : 'off', className: isNameBar ? classes.capitalize : '' }}
            onKeyPress={event => !locationField && (event.key === 'Enter') && onClick(firstField, secondField)}
          />
          {
            locationField && !isExtern
            && (
              <LocationSearchInput
                variant="outlined"
                address={location.address}
                onChange={event => this.setState({ location: event })}
                fullWidth
                placeholder="State, City or Zip Code"
                errorBorder={Boolean(!location.address)}
                onKeyUp={event => (event.key === 'Enter') && onClick(firstField, secondField, thirdField)}
              />
            )
          }
          <Button
            className={classes.emailSubmitButton}
            variant="contained"
            color={buttonColor}
            onClick={() => onClick(firstField, secondField, thirdField)}
          >
            {buttonText}
          </Button>
        </div>
      );
  }
}

EmailBar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  emailPlaceHolder: PropTypes.string,
  passwordPlaceHolder: PropTypes.string,
  buttonText: PropTypes.string,
  buttonColor: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isNameBar: PropTypes.bool,
  defaultEmail: PropTypes.string,
  defaultPassword: PropTypes.string,
  invalidLoginEmail: PropTypes.string,
  invalidLoginPassword: PropTypes.string,
  isLoginForm: PropTypes.bool,
  onEmailChange: PropTypes.func,
  onPasswordChange: PropTypes.func,
};

EmailBar.defaultProps = {
  emailPlaceHolder: 'Email',
  passwordPlaceHolder: 'Password',
  buttonText: 'Continue',
  buttonColor: 'primary',
  isNameBar: false,
  defaultEmail: '',
  defaultPassword: '',
  invalidLoginEmail: '',
  invalidLoginPassword: '',
  isLoginForm: false,
  onEmailChange: () => {},
  onPasswordChange: () => {},

};

export default withStyles(EmailBarStyle)(EmailBar);
