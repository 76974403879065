import {
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  CERTIFIED_VET_COMFORT_LEVEL,
  CURRENT_ROLE_CRITERIA,
  JOB_TYPE_SUBCATEGORIES,
  VET_CATEGORIES,
  STEPS,
} from '../../config/enums';
import { VetTechOrAssistantOnboardingStyle } from '../../css/style';
import AnimalPatientTypeComponent from './itemTypes/AnimalPatientTypeComponent';
import CertificationSpecialtyComponent from './itemTypes/CertificationSpecialtyComponent';
import CertificationTypeComponent from './itemTypes/CertificationTypeComponent';
import ComfortLevelSelectionComponent from './itemTypes/ComfortLevelSelectionComponent';
import CurrentRoleComponent from './itemTypes/CurrentRoleComponent';
import SpecialistTypeComponent from './itemTypes/SpecialistTypeComponent';
import OnboardingFlowComponent from './OnboardingFlowComponent';
import SimpleSelectableIconComponent from './itemTypes/SimpleSelectableIconComponent';
import { JOB_TYPE_SUB_CATEGORY, VET_SUB_CATEGORY } from '../constants/Constants';

const CERTIFICATED_VET_TECH = 'certified-vet-tech';
const VET_ASSISTANT = 'vet-assistant';

class VetTechOrAssistantOnboarding extends OnboardingFlowComponent {
  constructor(props) {
    super(props, 5);
    const st = this.state;
    st.step0 = [false];
    st.step1 = [false];
    st.step2 = [false, true];
    st.step3 = [false];
    st.step4 = [false];
    st.step5 = [false];
    st.step6 = [false];
    st.step7 = [false, false];
    this.initSubCategoryItems(st, 'selectedVet', VET_SUB_CATEGORY);
    var maxSteps = st.selectedVet === CERTIFICATED_VET_TECH ? 7 : 5;
    st.maxSteps = maxSteps;
    this.state = st;

    /* Remove telemed options when a worker edits details page
    (skip the page) and assign the worker to be in-person */
    this.addSkippedStep(1);
    this.autoSaveTelemedPrefAsInPerson();
  }

  componentDidUpdate(prevProps, prevState) {
    const { step } = this.state;
    if (step === STEPS.one && prevState.step === STEPS.zero) {
      this.verifyCriteriaDataFilled(JOB_TYPE_SUB_CATEGORY, step, 0);
    }
  }

  handleOnClick = (key, step) => {
    if (step === STEPS.one) {
      this.handleStateChange([{
        criteria: JOB_TYPE_SUB_CATEGORY,
        strValue: key,
      }], JOB_TYPE_SUB_CATEGORY, step, 0);
      this.setState({
        jobTypeSubCategory: key,
      });
    } else {
      this.handleStateChange([{
        criteria: VET_SUB_CATEGORY,
        strValue: key,
      }], VET_SUB_CATEGORY, step, 0);
      var maxSteps = key === CERTIFICATED_VET_TECH ? 7 : 5;
      this.setState({
        selectedVet: key,
        maxSteps,
        step2: (key === CERTIFICATED_VET_TECH) ? [false, true] : [false], // certified-vet-tech and 'vet-assistant' have different step1 setup
        step5: (key === CERTIFICATED_VET_TECH) ? [false] : [false, false], // certified-vet-tech and 'vet-assistant' have different step1 setup
      });
    }
  };

  /**
   * this page is only used for certified-vet-tech at step1, no params need
   */
  certificationsPage = () => {
    const { classes } = this.props;
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography className={classes.title}>WHICH OF THESE CERTIFICATIONS DO YOU HAVE?</Typography>
        </Grid>
        <CertificationTypeComponent
          criteria="vet-certification"
          initState={this.getOnboardingItems('vet-certification')}
          handleStateChange={items => this.handleStateChange(items, 'vet-certification', STEPS.two, 0)}
        />
        <Grid item xs={12}>
          <Typography className={classes.title}>ARE YOU A SPECIALIST?</Typography>
        </Grid>
        <SpecialistTypeComponent
          criteria="specialist"
          initState={this.getOnboardingItems('specialist')}
          setOnboardingItems={items => this.setOnboardingItems('specialist', items)}
          handleStateChange={items => this.handleStateChange(items, 'specialist', STEPS.two, 1)}
          addSkippedStep={() => this.addSkippedStep(3)}
          removeSkippedStep={() => this.removeSkippedStep(3)}
        />
      </Grid>
    );
  };

  /**
   * this page is only used for certified-vet-tech at step2, no params need
   */
  specialtyPage = () => {
    const { classes } = this.props;
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography className={classes.title}>WHICH IS YOUR SPECIALTY?</Typography>
          <Typography color="primary" className={classes.description}>You can select more than one</Typography>
        </Grid>
        <CertificationSpecialtyComponent
          initState={this.getOnboardingItems('cert-specialty')}
          handleStateChange={items => this.handleStateChange(items, 'cert-specialty', 3, 0)}
        />
      </Grid>
    );
  };

  /**
   * @param {*} step step that this page will be used for
   */
  clinicalSkillsAndExperiencePage = (step) => {
    const { classes } = this.props;
    const stepDescription = 'So we can find your best match, respond with your clinical comfort levels below. (5 is super confident)';
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
      >
        <Typography color="secondary" className={classes.title}>CLINICAL SKILLS AND EXPERIENCE</Typography>
        <Typography color="primary" className={classes.description}>
          {stepDescription}
        </Typography>
        <ComfortLevelSelectionComponent
          keyValue="comfort-level-certified-vet"
          info={CERTIFIED_VET_COMFORT_LEVEL}
          enableContinue={() => this.enableContinueButton()}
          initState={this.getOnboardingItems('comfort-level-certified-vet')}
          handleStateChange={items => this.handleStateChange(items, 'comfort-level-certified-vet', step, 0)}
        />
      </Grid>
    );
  };

  /**
   * @param {*} step step that this page will be used for
   */
  currentRolePage = (step) => {
    const { classes } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <Typography color="secondary" className={classes.titleNoMarginBottom}>TELL US ABOUT YOUR CURRENT ROLE</Typography>
        <CurrentRoleComponent
          keyValue="current-roles"
          info={CURRENT_ROLE_CRITERIA}
          enableContinue={() => this.enableContinueButton()}
          initState={this.getOnboardingItems('current-roles')}
          handleStateChange={items => this.handleStateChange(items, 'current-roles', step, 0)}
        />
      </div>
    );
  };

  /**
   * @param {*} step step that this page will be used for
   */
  animalPatientsPage = (step) => {
    const { classes } = this.props;
    return (
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography color="secondary" className={classes.title}>WHAT TYPE OF ANIMALS MAKE UP A SIGNIFICANT PORTION OF YOUR PATIENTS?</Typography>
        </Grid>
        <AnimalPatientTypeComponent
          criteria="patient-type"
          initState={this.getOnboardingItems('patient-type')}
          handleStateChange={items => this.handleStateChange(items, 'patient-type', step, 0)}
        />
      </Grid>
    );
  };

  renderStep0() {
    const { classes, user } = this.props;
    const disabled = !!user.externalId;
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography color="secondary" className={classes.title}>WHAT TYPE OF VET?</Typography>
          {disabled && (<Typography className={classes.resourceAlert}>{'>>>To edit unavailable fields, please update information in Workday.<<<'}</Typography>)}
        </Grid>
        {
          VET_CATEGORIES.map(type => (
            <Grid
              item
              key={type.key}
            >
              <SimpleSelectableIconComponent
                icon={type.key}
                title={type.title.toUpperCase()}
                selected={type.key === this.state.selectedVet}
                onClick={() => !disabled && this.handleOnClick(type.key, STEPS.zero)}
              />
            </Grid>
          ))
        }
      </Grid>
    );
  }

  renderStep1() {
    const { classes } = this.props;
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography color="secondary" className={classes.title}>TELEMEDICINE PREFERENCE?</Typography>
        </Grid>
        {
          JOB_TYPE_SUBCATEGORIES.map(type => (
            <Grid item key={type.key}>
              <SimpleSelectableIconComponent
                icon={type.key}
                title={type.title.toUpperCase()}
                selected={type.key === this.state.jobTypeSubCategory}
                onClick={() => this.handleOnClick(type.key, STEPS.one)}
              />
            </Grid>
          ))
        }
      </Grid>
    );
  }

  renderStep2() {
    switch (this.state.selectedVet) {
      case CERTIFICATED_VET_TECH:
        return this.certificationsPage();
      case VET_ASSISTANT:
        return this.clinicalSkillsAndExperiencePage(STEPS.two);
      default:
        return;
    }
  }

  renderStep3() {
    switch (this.state.selectedVet) {
      case CERTIFICATED_VET_TECH:
        return this.specialtyPage();
      case VET_ASSISTANT:
        return this.animalPatientsPage(STEPS.three);
      default:
        return;
    }
  }

  renderStep4() {
    switch (this.state.selectedVet) {
      case CERTIFICATED_VET_TECH:
        return this.clinicalSkillsAndExperiencePage(STEPS.four);
      case VET_ASSISTANT:
        return this.currentRolePage(STEPS.four);
      default:
        return;
    }
  }

  renderStep5() {
    if (this.state.selectedVet === CERTIFICATED_VET_TECH) {
      return this.currentRolePage(STEPS.five);
    }
  }

  renderStep6() {
    if (this.state.selectedVet === CERTIFICATED_VET_TECH) {
      return this.animalPatientsPage(STEPS.six);
    }
  }
}

export default withStyles(VetTechOrAssistantOnboardingStyle)(withRouter(VetTechOrAssistantOnboarding));
