/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from './config/configureStore';
import { createGenerateClassName } from '@material-ui/core/styles';

import App from './components/App';

/**
 * In the production version, the material-ui class names are binded into shorter format.
 * eg. change from 'MuiTypography-root-1' to 'jss1'
 * but some classes are given the same name. eg. multiple classes have the same name such as 'jss1' , 'jss2' , 'jss3' ...
 * this causes wrong styling is matched for some material-ui components
 * comment that gives idea for solving this issue:
 * https://github.com/mui-org/material-ui/issues/11397#issuecomment-390675123
 * link for solving issue, using JssProvider:
 * https://material-ui.com/customization/css-in-js/#jssprovider
 */

import StylesProvider from '@material-ui/styles/StylesProvider';

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});

ReactDOM.render(
  <Provider store={store}>
    <StylesProvider generateClassName={generateClassName}>
      <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
        <App />
      </PersistGate>
    </StylesProvider>
  </Provider>,
  document.getElementById('app'),
);
