import moment from 'moment';
import React, { useState, useEffect, memo } from 'react';
import { GoogleMap, useJsApiLoader, Marker, } from '@react-google-maps/api';
import { isEmpty, isNumber, max } from 'lodash';
import {
  Popover,
  Typography,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  ButtonBase,
  IconButton,
  Box,
  Icon,
  Grid,
  withStyles,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import { navy, orange, lightBlue, aquaGreen, textColor, closeDrawer } from '../../css/style';
import { GOOGLE_MAPS_API_KEY, IMG_STORAGE_LINK } from '../constants/Constants';
import { geodistance, formatPostTitle, isReliefOrExternshipType } from '../../utils/Functions';

const fastpassIcon = `${IMG_STORAGE_LINK}green-ticket.png`;

const styles = theme => ({
  infoGrid: {
    width: '100%',
    padding: '0px 8px',
  },
  infoTypography: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '20px',
    textTransform: 'capitalize',
    color: navy,
  },
  matchIteminfoItem: {
    display: 'flex',
    alignItems: 'center',
    //padding: '0px 8px',
  },
  matchIteminfoIcon: {
    color: '#9e9e9e',
    marginRight: 5,
  },
  linkIcon: {
    color: orange,
    marginRight: 5,
  },
  matchItemInfoText: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '15.6px',
    color: textColor,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  fastpassText: {
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '12px',
    padding: '0px',
    textTransform: 'capitalize',
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    //padding: '8px 16px 16px 0px',
  },
  link: {
    color: '#243060',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '15.6px',
  },
  prevBtn: {
    ...closeDrawer,
    marginLeft: '-18px !important',
    marginTop: '-125px !important',

    '& .MuiIconButton-root': {
      color: orange,
    },
    
  },
  nextBtn: {
    ...closeDrawer,
    marginLeft: '333px !important',
    marginTop: '-125px !important',

    '& .MuiIconButton-root': {
      color: orange,
    },
  },
  closeButton: {
    padding: '8px',
  }
});

const defaultContainerStyle = {
  width: '100%',
  height: '602px'
};

const thriveDefaultLocation = {
  lat: 39.337263546349234,
  lng: -77.10519014257994
};

const LocationGoogleMap = ({ markers, handleMarkerClick, containerStyle, totalItems, fastpassData = [], classes, isMobile }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY
  });

  const options = {
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: window.google?.maps?.MapTypeControlStyle?.HORIZONTAL_BAR,
      position: window.google?.maps?.ControlPosition?.TOP_LEFT,
    },
    zoomControl: true,
    zoomControlOptions: {
      position: window.google?.maps?.ControlPosition?.TOP_RIGHT,
    },
    scaleControl: true,
    streetViewControl: true,
    streetViewControlOptions: {
      position: window.google?.maps?.ControlPosition?.TOP_RIGHT,
    },
    fullscreenControl: false,
  }

  let { lat, lng } = !isEmpty(markers) ? markers[0]?.position : {
    lat: thriveDefaultLocation.lat,
    lng: thriveDefaultLocation.lng
  };

  const [latitude, setLat] = useState(lat);
  const [longitude, setLng] = useState(lng);
  const [zoom, setZoom] = useState(15);

  const [map, setMap] = React.useState(null);
  const [activeMarker, setActiveMarker] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedMatches, setSelectedMatches] = React.useState([]);
  const [currentPage, setCurrentPage] = React.useState(0);

  // Navigate to previous/next step
  const go = (back) => {
    const page = back ? (currentPage - 1) : (currentPage + 1);
    setCurrentPage(page);
  };

  const handleActiveMarker = (match) => {
    if (handleMarkerClick) {
      handleMarkerClick(match);
    }
  };

  useEffect(
    () => {
      if (isEmpty(markers)) {
        setLat(thriveDefaultLocation?.lat);
        setLng(thriveDefaultLocation?.lng);
        setZoom(5);
        return;
      };
      setZoomFunc(markers);
      if (markers[0]?.position?.lat && markers[0]?.position?.lng) {
        setLat(markers[0]?.position?.lat);
        setLng(markers[0]?.position?.lng);
      }
    },
    [markers],
  );

  const setZoomFunc = (markers) => {
    if (!isEmpty(markers)) {
      let distanceArr = [];
      for (let i = 0; i < markers.length; i++) {
        const pos = markers[i].position;
        const otherItems = markers.slice(i + 1);
        if (!isEmpty(otherItems)) {
          for (let j = 0; j < otherItems.length; j++) {
            const otherPos = otherItems[j].position;
            distanceArr.push(geodistance(pos.lat, pos.lng, otherPos.lat, otherPos.lng));
          }
        }
      }
      const furthestDistance = max(distanceArr);
      let calZoom;
      if (!distanceArr.length) {
        calZoom = 15; // only 1 location
      } else {
        if (furthestDistance < 10) { // 5.4 --> zoom 12;
          calZoom = 12;
        } else if (furthestDistance < 50) {
          calZoom = 8;                       // 49 --> 8; 163 --> 8
        } else if (furthestDistance < 180) {
          calZoom = 6;
        }  else if (furthestDistance < 250) {
          calZoom = 5; 
        } else if (furthestDistance < 500) {
          calZoom = 4;
        } else {
          calZoom = 2; // min is 2
        }
      }
      setZoom(calZoom);
    }
  };
  const onLoad = React.useCallback(function callback(map) {
    if (isEmpty(markers)) return;
    const bounds = new window.google.maps.LatLngBounds();

    markers?.forEach(({ position }) => {
      if (isNumber(position?.lat) && isNumber(position?.lng)) {
        bounds.extend(position);
      }
  });
    setZoomFunc(markers);
    setMap(map);
  }, [markers])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [markers]);

  const getDates = (item) => {
    return isReliefOrExternshipType(item?.jobPosting?.typeOfEmployment) && (item?.dateList || !isEmpty(item?.available))
      ? item?.dateList || item.available.map(a => moment(a).format('MMM.DD')).join(', ')
      : item?.jobPosting?.typeOfEmployment;
  };

  const renderCards = () => {
    const itemId = currentItem?.index || currentItem?.id;
    return (
      <Popover
        id={`${itemId}_popover`}
        key={`${itemId}_popover`}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setCurrentPage(0);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          style: { width: '357px', padding: '0px', boxShadow: '0px 4px 4px 0px #00000040' },
        }}
      >
      {!isEmpty(currentItem) && ( 
        <Card
          style={{ boxShadow: 'none' }}
          key={`${itemId}_popover_card`}
        >
          <CardHeader
            key={`${itemId}_popover_cardheader`}
            action={(
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => {
                  setAnchorEl(null);
                  setCurrentPage(0);
                }}
                key={`${itemId}_close_icon_btn`}
              >
                <CloseIcon style={{ fontSize: '20px' }}  key={`${itemId}_close_icon`}/>
              </IconButton>
            )
            }
            title={(
              <Grid container
                style={{ display: 'flex', alignItems: 'center', width: '100%'}}
                justifyContent='flex-start'
                key={`${itemId}_title_grid`}
              >
                {isFastPass &&
                <Grid item xs={6}
                  style={{ alignItems: 'center'}}
                  key={`${itemId}_title_grid_item`}
                >
                  <img style={{ width: 20, height: 20, marginRight: '5px' }} src={fastpassIcon} alt='fastpass' title='fastpass'/>
                  <Typography
                    key={`${itemId}_fastpass`}
                    className={classes.fastpassText}
                  >
                    FAST PASS
                  </Typography>
                </Grid>
                }
                <Grid item xs={isFastPass ? 6 : 12}
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}
                  key={`${itemId}_ss`}
                > 
                  {currentItem?.savedSearch && (
                    <>
                    <div
                      key={`${itemId}_color`}
                      style={{
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        backgroundColor: `#${currentItem?.savedSearch?.color}`,
                      }}
                    />
                    <Typography
                      key={`${itemId}_ss_title`}
                      className={classes.fastpassText}
                      style={{color: lightBlue, fontWeight: 500, paddingLeft: '5px' }}
                    >
                      {currentItem?.savedSearch?.title}
                    </Typography>
                  </>
                  )}
                </Grid>
              </Grid>
            )}
            style={{ padding: '8px' }}
          />
          <CardContent style={{ padding: '0px 16px' }} key={`${itemId}_card_content`}>
            <Grid
              className={classes.infoGrid}
              direction="row"
              container
              key={`${itemId}_container`}
            >
              <Grid item xs={12} key={`${itemId}_title_container`} className={classes.matchIteminfoItem}>
                {currentItem.status === 'connected' && (
                  <EmojiEmotionsIcon key={`${itemId}_connected`}
                    style={{ fontSize: '20px', marginRight: '4px', color: aquaGreen }}
                  />
                )}
                <Typography key={`${itemId}_post_title`} color="secondary" className={classes.infoTypography}>
                  {formatPostTitle(currentItem?.jobPosting?.title || currentItem?.title)}
                </Typography>
              </Grid>
              <Grid item xs={10} key={`${itemId}_dates_container`} className={classes.matchIteminfoItem}>
                <Typography
                  key={`${itemId}_date_list`}
                  className={classes.matchItemInfoText}
                  style={{ fontWeight: 600 }}
                >
                  {getDates(currentItem)}
                </Typography>
              </Grid>

              <Grid item xs={2} key={`${itemId}_page_container`} className={classes.matchIteminfoItem}>
                <Typography
                  key={`${itemId}_page`}
                  className={classes.matchItemInfoText}
                  style={{ fontWeight: 600, color: orange }}
                >
                  {`${currentPage + 1} of ${selectedMatches.length}`}
                </Typography>
              </Grid>
              <Grid item xs={12} key={`${itemId}_practice_container`} className={classes.matchIteminfoItem}>
                <Typography
                  key={`${itemId}_practice_name`}
                  className={classes.matchItemInfoText}
                >
                  {currentItem?.practice?.name}
                </Typography>
              </Grid>
              <Grid item xs={12} key={`${itemId}_location_container`}
                className={classes.matchIteminfoItem}
              >
                <Typography
                  key={`${itemId}_location`}
                  className={classes.matchItemInfoText}
                >
                  {currentItem?.practice?.location}
                </Typography>
              </Grid>
            </Grid>
            
          </CardContent>
          
          <CardActions  style={{ padding: '8px' }} key={`${itemId}_card_actions`}>
            { currentPage > 0 && currentPage < selectedMatches.length && (
              <Box className={classes.prevBtn} key={`${itemId}_prev_box`}>
                <IconButton onClick={() => go('back') } size='small' key={`${itemId}_prev_btn`}>
                  <ChevronLeftIcon key={`${itemId}_prev_icon`} style={{ fontSize: '16px' }}/>
                </IconButton>
              </Box>
            )}
            <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }} key={`${itemId}_get_dir`}>
              <ButtonBase
                target='_blank'
                href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
                style={{ textDecoration: 'underline', float: 'right' }}
                key={`${itemId}_get_dir_btn`}
              >
                <Typography className={classes.link} key={`${itemId}_get_dir_text`}>Get Directions</Typography>
              </ButtonBase>
            </div>
            { currentPage < selectedMatches.length - 1
              && (
                <Box className={classes.nextBtn} key={`${itemId}_next_box`}>
                  <IconButton onClick={() => go()} size='small' key={`${itemId}_next_btn`}>
                    <ChevronRightIcon key={`${itemId}_next_icon`} style={{ fontSize: '16px' }}/>
                  </IconButton>
                </Box>
              )
            }
          </CardActions>
        </Card>
      )}
      </Popover>
    );
  };

  const renderMobileCards = () => {
    return activeMarker &&
    (<div
      id={`${activeMarker}_cont`}
      key={`${activeMarker}_cont`}
      style= {{
        position: 'sticky',
        bottom: '200px',
        padding: '0px',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 'calc(50% - 172px)',
        width: '100%',
      }}
    >
      <Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={() => {
            setActiveMarker(null);
          }}
          key={`${activeMarker}_close_icon_btn`}
        >
          <CloseIcon style={{ fontSize: '24px', color: orange }}  key={`${activeMarker}_close_icon`}/>
        </IconButton>
      </Typography>

      <div
        id={`${activeMarker}_div`}
        key={`${activeMarker}_div`}
        style= {{
          padding: '0px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          width: '100%',
          maxWidth: 'calc(50vw + 172px)',
        }}
      >
      {selectedMatches.map((currentItem, index) => {
        const itemId = currentItem?.index || currentItem?.id;
        return  (
        <Card
            style={{ boxShadow: 'none', minWidth: 357, width: 357, marginRight: 8 }}
            key={`${itemId}_popover_card`}
          >
            <CardHeader
              key={`${itemId}_popover_cardheader`}
              title={(
                <Grid container
                  style={{ display: 'flex', alignItems: 'center', width: '100%'}}
                  justifyContent='flex-start'
                  key={`${itemId}_title_grid`}
                >
                  {isFastPass &&
                  <Grid item xs={6}
                    style={{ alignItems: 'center'}}
                    key={`${itemId}_title_grid_item`}
                  >
                    <img style={{ width: 20, height: 20, marginRight: '5px' }} src={fastpassIcon} alt='fastpass' title='fastpass'/>
                    <Typography
                      key={`${itemId}_fastpass`}
                      className={classes.fastpassText}
                    >
                      FAST PASS
                    </Typography>
                  </Grid>
                  }
                  <Grid item xs={isFastPass ? 6 : 12}
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}
                    key={`${itemId}_ss`}
                  >
                  { currentItem?.savedSearch && (<>
                    <div
                      key={`${itemId}_color`}
                      style={{
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        backgroundColor: `#${currentItem?.savedSearch?.color}`,
                      }}
                    />
                    <Typography
                      key={`${itemId}_ss_title`}
                      className={classes.fastpassText}
                      style={{color: lightBlue, fontWeight: 500, paddingLeft: '5px' }}
                    >
                      {currentItem?.savedSearch?.title}
                    </Typography>
                    </>)}
                  </Grid>
                </Grid>
              )}
              style={{ padding: '8px' }}
            />
            <CardContent style={{ padding: '0px 16px' }} key={`${itemId}_card_content`}>
              <Grid
                className={classes.infoGrid}
                direction="row"
                container
                key={`${itemId}_container`}
              >
                <Grid item xs={12} key={`${itemId}_title_container`} className={classes.matchIteminfoItem}>
                  {currentItem.status === 'connected' && (
                    <EmojiEmotionsIcon key={`${itemId}_connected`}
                      style={{ fontSize: '20px', marginRight: '4px', color: aquaGreen }}
                    />
                  )}
                  <Typography key={`${itemId}_post_title`} color="secondary" className={classes.infoTypography}>
                    {formatPostTitle(currentItem?.jobPosting?.title || currentItem?.title)}
                  </Typography>
                </Grid>
                <Grid item xs={10} key={`${itemId}_dates_container`} className={classes.matchIteminfoItem}>
                  <Typography
                    key={`${itemId}_date_list`}
                    className={classes.matchItemInfoText}
                    style={{ fontWeight: 600 }}
                  >
                    {getDates(currentItem)}
                  </Typography>
                </Grid>

                <Grid item xs={2} key={`${itemId}_page_container`} className={classes.matchIteminfoItem}>
                  <Typography
                    key={`${itemId}_page`}
                    className={classes.matchItemInfoText}
                    style={{ fontWeight: 600, color: orange }}
                  >
                    {`${index + 1} of ${selectedMatches.length}`}
                  </Typography>
                </Grid>
                <Grid item xs={12} key={`${itemId}_practice_container`} className={classes.matchIteminfoItem}>
                  <Typography
                    key={`${itemId}_practice_name`}
                    className={classes.matchItemInfoText}
                  >
                    {currentItem?.practice?.name}
                  </Typography>
                </Grid>
                <Grid item xs={12} key={`${itemId}_location_container`}
                  className={classes.matchIteminfoItem}
                >
                  <Typography
                    key={`${itemId}_location`}
                    className={classes.matchItemInfoText}
                  >
                    {currentItem?.practice?.location}
                  </Typography>
                </Grid>
              </Grid>
              
            </CardContent>
            
            <CardActions  style={{ padding: '8px' }} key={`${itemId}_card_actions`}>
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }} key={`${itemId}_get_dir`}>
                <ButtonBase
                  target='_blank'
                  href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
                  style={{ textDecoration: 'underline', float: 'right' }}
                  key={`${itemId}_get_dir_btn`}
                >
                  <Typography className={classes.link} key={`${itemId}_get_dir_text`}>Get Directions</Typography>
                </ButtonBase>
              </div>
            </CardActions>
          </Card>
      );
      })}
      </div>
    </div>
  );
  };

  const currentItem = !isEmpty(selectedMatches) ? selectedMatches[currentPage] : {};
  const isFastPass = !isEmpty(currentItem) &&  currentItem.jobPosting?.typeOfEmployment === 'Relief'
    && fastpassData?.find(fp => fp.practice_id === currentItem.practiceId);
  let destination = currentItem?.practice?.location;
    destination = destination?.replace(/\//g, '%2F');
    destination = destination?.replace(/:/g, '%3A');

  return isLoaded ?
    (<>
      <GoogleMap
        mapContainerStyle={containerStyle || defaultContainerStyle}
        center={{
          lat: latitude || thriveDefaultLocation.lat,
          lng: longitude || thriveDefaultLocation.lng
        }}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={options}
      >
        {markers.length && markers.map(({ id, name, position, matchCount }) => (
        <div>
          <Marker
            key={id}
            position={ position?.lat && position?.lng ? { lat : position.lat, lng: position.lng} : thriveDefaultLocation }
            icon={{
              url: activeMarker === id ? `${IMG_STORAGE_LINK}thrive-green-pin.svg` :`${IMG_STORAGE_LINK}thrive-pin.svg`,
              scaledSize: new google.maps.Size(42, 45),
            }}
            zIndex={100}
            onClick={(e) => {
              e.domEvent.stopPropagation();
              setActiveMarker(id);
              setLat(position?.lat);
              setLng(position?.lng);

              const selectedMatches = (totalItems || []).filter(item => item.practiceId === id);
              if (!selectedMatches.length) return 
              setTimeout(() => {
                setSelectedMatches(selectedMatches);
                setAnchorEl(e.domEvent.target);
              }, 400);

            }}
            title={name}
          >
          </Marker>
          
        </div>
        ))}
      </GoogleMap>
      {isMobile ? renderMobileCards() : renderCards()}
  </>) : <></>
};

export default withStyles(styles)(memo(LocationGoogleMap));
