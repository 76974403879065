import CssBaseLine from '@material-ui/core/CssBaseline';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as Actions from '../actions/Actions';
import {
  getErrorMessage,
  getLoading,
  getResources,
  getSuccessMessage,
  getUser,
} from '../selectors';
import { isTemporaryUser } from '../utils/Functions';
import LoginPage from './authentication/LoginPage';
import ResetPasswordDialog from './authentication/subcomponents/ResetPasswordDialog';
import MessageSnackbar from './core/components/MessageSnackbar';
import CoreRoute from './core/utils/CoreRoute';
import ErrorBoundary from './ErrorBoundary';
import OauthResult from './OauthResult';
import OnbardingPage from './onboarding/OnbardingPage';
import TalentType from './onboarding/TalentType';
import SignUp from './SignUp/components/SignUp';
import MatchDetailsPage from './talent/MatchDetailsPage';
import UserProfilePage from './talent/UserProfilePage';
import TalnetHomePageTabs from './talent/TalnetHomePageTabs';
import ThankYou from './ThankYou/components/ThankYou';
import UserInfo from './UserInfo/components/UserInfo';
import MatchesComponent from './talent/MatchesComponent';
import Messages from './Messages/Messages';
import ExternHome from './talent/ExternHome';
import MobileSearch from './talent/MobileSearch';
import SettingsComponent from './talent/SettingsComponent';

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      du: 540, // dou (2 screens)
      sm: 600,
      md: 960, //mobile, tablet
      lg: 1290,
      xl: 1636,
    },
  },
  palette: {
    primary: {
      main: '#4bbdad',
      contrastText: '#fff',
    },
    secondary: {
      main: '#243060',
      dark: '#000736',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Asap',
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        position: 'relative',
        '& $notchedOutline': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: '#4bbdad',
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
        },
        '&$focused $notchedOutline': {
          borderColor: '#4bbdad',
          borderWidth: 2,
        },
      },
      input: {
        padding: '14px',
      },
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          backgroundColor: '#4bbdad',
          color: 'white',
          '&:hover': {
            backgroundColor: '#4bbdad',
            opacity: 0.75,
          },
        },
        '&:hover': {
          backgroundColor: '#4bbdad',
          color: 'white',
          opacity: 0.75,
        },
      },
    },
    MuiLink: {
      root: {
        color: '#4bbdad',
      },
    },
    MuiButton: {
      root: {
        '&:disabled': {
          backgroundColor: '#f0f0f0',
        },
      },
    },
  },
});

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeDialogBox: false,
      localUser: null,
    };
  }

  componentDidUpdate(prevProps) {
    const { errors, actions } = this.props;
    if (!prevProps.errors && errors === 'Token expired.') {
      actions.logoutUser();
      // Display error message after re-render
      actions.displayErrorMessage('Authentication token is expired, please login again.');
    }
  }

  handlePasswordReset = (type, data, localAdmin) => {
    const { actions } = this.props;
    const { id, token } = localAdmin;
    const { password } = data;
    actions.updateResource('users', token, { password, resetPassword: false }, 'users', `${id}`)
      .then(() => {
        this.setState({ closeDialogBox: true });
        if (!localAdmin || !localAdmin.name || localAdmin.name === '-') {
          window.location.assign('/userinfo');
        }
      });
  }

  renderPasswordResetDialog = (localUser) => {
    const { closeDialogBox } = this.state;
    const resetPassword = localUser && localUser.resetPassword;
    return (
      <ResetPasswordDialog
        type='changePassword'
        open={!closeDialogBox && resetPassword || false}
        requireVerification={false}
        title='Change Password'
        description='Please create a password'
        handleCloseDialog={() => { }}
        disableClose
        handleOnClick={(type, data) => this.handlePasswordReset(type, data, localUser)}
        disableBackdropClick
        disableEscapeKeyDown
      />
    );
  };

  redirectComponent = (externSignup) => {
    sessionStorage.setItem('intent', window.location.pathname);

    if (externSignup) {
      return (
        <Redirect to='/extern-signup' />
      );
    }

    return (
      <Redirect to='/login' />
    );
  }

  checkTemporaryUser(user) {
    return user && isTemporaryUser(user.email);
  }

  render() {
    const {
      actions, loading, user, errors,
      success, resources,
    } = this.props;
    const localUser = (localStorage.getItem('user') !== null
      ? JSON.parse(localStorage.getItem('user'))
      : (user && user.user) ? user.user : null);
    const coreViewProps = {
      actions,
      loading,
      user: localUser,
      onCloseReleaseNoteDialog: this.onCloseReleaseNoteDialog,
      onOpenReleaseNoteDialog: this.onOpenReleaseNoteDialog,
    };
    let temporaryUser = this.checkTemporaryUser(localUser);
    const isSignUpStudentUser = localUser && localStorage.getItem(`student_user_${localUser?.id}`) === 'true';
    return (
      <React.Fragment>
        <CssBaseLine />
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <div className="App">
              <ErrorBoundary>
                <Switch>
                  <CoreRoute exact path="/" coreview={coreViewProps} render={() => ((localUser && (!temporaryUser || user.isInvitedUser)) ? localUser.isStudent || isSignUpStudentUser ? (<Redirect to='/externhome' />) : (<Redirect to='/matches' />) : (<LoginPage user={localUser} actions={actions} />))} />
                  <CoreRoute exact path="/verify/:verificationUuid" coreview={coreViewProps} render={() => (<TalnetHomePageTabs user={{}} actions={actions} loading={loading} resources={resources} />)} />
                  <CoreRoute exact path="/userinfo" coreview={coreViewProps} render={() => ((!localUser || localUser.isCompleted) ? (this.redirectComponent()) : (<UserInfo user={localUser} actions={actions} loading={loading} resources={resources} />))} />
                  <CoreRoute exact path="/externinfo" coreview={coreViewProps} render={() => ((!localUser || localUser.isCompleted) ? (this.redirectComponent(true)) : (<UserInfo user={localUser} actions={actions} loading={loading} resources={resources} />))} />
                  <CoreRoute exact path="/talent" coreview={coreViewProps} render={() => ((!localUser || localUser.isCompleted) ? (this.redirectComponent()) : (<TalentType user={localUser} actions={actions} loading={loading} resources={resources} />))} />
                  <CoreRoute exact path="/onboarding" coreview={coreViewProps} render={() => ((!localUser || localUser.isCompleted) ? (this.redirectComponent()) : (<OnbardingPage user={localUser} actions={actions} loading={loading} resources={resources} />))} />
                  <CoreRoute exact path="/signup" coreview={coreViewProps} render={() => (<SignUp user={localUser} actions={actions} loading={loading} resources={resources} />)} />
                  <CoreRoute exact path="/extern-signup" coreview={coreViewProps} render={() => (<SignUp user={localUser} actions={actions} loading={loading} resources={resources} />)} />
                  <CoreRoute exact path="/login" name="login" render={() => <LoginPage user={localUser} actions={actions} />} />
                  <CoreRoute exact path="/externlogin" name="login" render={() => <LoginPage user={localUser} actions={actions} />} />
                  <CoreRoute exact path="/matches" coreview={coreViewProps} render={() => ((!localUser || temporaryUser) ? (this.redirectComponent()) : (<MatchesComponent user={localUser} actions={actions} loading={loading} resources={resources} />))} />
                  <CoreRoute exact path="/externhome" coreview={coreViewProps} render={() => ((!localUser || temporaryUser) ? (this.redirectComponent()) : (<ExternHome user={localUser} actions={actions} loading={loading} resources={resources} />))} />
                  <CoreRoute exact path="/search" coreview={coreViewProps} render={() => ((!localUser || temporaryUser) ? (this.redirectComponent()) : (<MobileSearch user={localUser} actions={actions} loading={loading} resources={resources} />))} />
                  <CoreRoute exact path="/messages/:matchId" coreview={coreViewProps} render={() => ((!localUser || temporaryUser) ? (this.redirectComponent()) : (<Messages user={localUser} actions={actions} loading={loading} activeTab={null} resources={resources} />))} />
                  <CoreRoute exact path="/jobpostings/:id" coreview={coreViewProps} render={() => (<MatchDetailsPage jobposting user={localUser} actions={actions} loading={loading} activeTab={0} resources={resources} />)} />
                  <CoreRoute exact path="/matches/:id" coreview={coreViewProps} render={() => ((!localUser || temporaryUser) ? (this.redirectComponent()) : (<MatchDetailsPage user={localUser} actions={actions} loading={loading} activeTab={0} resources={resources} />))} />
                  <CoreRoute exact path="/profile" coreview={coreViewProps} render={() => ((!localUser || temporaryUser) ? (this.redirectComponent()) : (<UserProfilePage user={localUser} actions={actions} loading={loading} resources={resources} />))} />
                  <CoreRoute exact path="/settings" coreview={coreViewProps} render={() => ((!localUser || temporaryUser) ? (this.redirectComponent()) : (<SettingsComponent user={localUser} actions={actions} loading={loading} resources={resources} />))} />
                  <CoreRoute exact path="/:oauthAction/:oauthProvider" coreview={coreViewProps} render={() => (<OauthResult user={localUser} actions={actions} loading={loading} activeTab={2} resources={resources} />)} />
                  <CoreRoute exact path="/messages" coreview={coreViewProps} render={() => ((!localUser || temporaryUser) ? (this.redirectComponent()) : (<Messages user={localUser} actions={actions} loading={loading} activeTab={null} resources={resources} />))} />
                  <CoreRoute exact path="/thankyou" coreview={coreViewProps} render={() => (!localUser ? (this.redirectComponent()) : (<ThankYou user={localUser} actions={actions} loading={loading} resources={resources} />))} />
                </Switch>
              </ErrorBoundary>
              <MessageSnackbar openMessageSnackbar={!!(errors || success)} errorMsg={errors} successMsg={success} actions={actions} />
              {this.renderPasswordResetDialog(localUser)}
            </div>
          </ThemeProvider>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, props) => ({
  user: getUser(state),
  loading: getLoading(state),
  errors: getErrorMessage(state),
  success: getSuccessMessage(state),
  resources: getResources(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(Actions, dispatch),
});

App.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  user: PropTypes.object.isRequired,
  loading: PropTypes.number.isRequired,
  errors: PropTypes.string,
  success: PropTypes.string,
  resources: PropTypes.object.isRequired,
};

App.defaultProps = {
  errors: '',
  success: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
