import { navy, tiffany, orange, lightGray, lightAquaGreen, textColor, iconGray, closeDrawer } from '../../../css/style';

const drawerWidth = '22%';
const drawerWidthMd = '30%';

const fabBtn = {
  position: 'fixed',
  height: 31,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  fontFamily: 'Asap',
  fontSize: '16px',
  fontWeight: 700,
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: '4px',
  padding: '6px 12px',
  gap: '10px',
  textTransform: 'none',
};

export const MessagesStyles = theme => ({
  desktop: {
    display: 'flex',
    background: '#FBFBFE',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mobile: {
    display: 'none',
    background: '#FBFBFE',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down('md')]: {
      width: drawerWidthMd,
    },
  },
  mobileDrawer: {
    width: '100%',
    flexShrink: 0,
  },
  mobileDrawerPaper: {
    width: '100%',
    zIndex: 100,
    background: '#F9F9F9',
  },
  drawerPaper: {
    width: drawerWidth,
    zIndex: 100,
    overflowY: 'hidden',
    paddingTop: '63px',

    [theme.breakpoints.down('md')]: {
      width: drawerWidthMd,
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#FBFBFE',
  },
  leftDrawerCloseBtn: {
    ...closeDrawer,
    marginLeft: drawerWidth,
    [theme.breakpoints.down('md')]: {
      marginLeft: drawerWidthMd,
    },
  },

  rightDrawerCloseBtn: {
    ...closeDrawer,
    marginLeft: '-12px',
    marginTop: '30px',
    color: '#233160',
  },

  drawerFabBtn: {
    ...fabBtn,
    backgroundColor: 'white',
    color: navy,
    borderWidth: '1px 1px 0px 1px',
    borderColor: lightGray,
    zIndex: 200,

    '&:hover': {
      backgroundColor: 'white',
    },
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}`,
    marginRight: `-${drawerWidth}`,
    [theme.breakpoints.down('md')]: {
      marginLeft: `-${drawerWidthMd}`,
      marginRight: `-${drawerWidthMd}`,
    },
  },
  contentShiftLeft: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  contentShiftRight: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  messagesHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 20px',
    height: '84px',
    fontSize: 20,
    fontFamily: 'Asap',
    fontWeight: 700,
    color: '#233160',
    borderBottom: '1px solid #D9D9D9',
  },
  messageCard: {
    padding: '10px 20px 10px 40px',
    borderBottom: '1px solid #D9D9D9',
    cursor: 'pointer',
    position: 'relative',
  },
  unread: {
    position: 'absolute',
    top: '56%',
    left: '3%',
    transform: 'translateY(-50%)',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardTitle: {
    fontFamily: 'Asap',
    fontSize: 16,
    fontWeight: 600,
    color: '#364D96',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  },
  cardTitleActive: {
    fontFamily: 'Asap',
    fontSize: 16,
    fontWeight: 600,
    color: '#297065',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  },
  active: {
    color: '#233160',
  },
  unreadText: {
    color: '#297065 !important',
  },
  cardSubTitle: {
    fontFamily: 'Asap',
    fontSize: 13,
    fontWeight: 400,
    color: '#64748B',
    textAlign: 'right',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    textTransform: 'capitalize',
  },
  cardTextBody: {
    fontFamily: 'Asap',
    fontSize: 13,
    fontWeight: 400,
    color: '#364D96',
    textTransform: 'capitalize',
  },
  contentRight: {
    textAlign: 'right',
  },
  messageText: {
    fontFamily: 'Asap',
    fontSize: 13,
    fontWeight: 400,
    color: '#233160',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  },
  jobMatchContainer: {
    // mobile styles
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
    },
    // tablet styles
    [theme.breakpoints.between('sm', 'md')]: {
      justifyContent: 'center',
    },
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
    },
  },
  mainHeader: {
    padding: '20px',
    width: '100%',
    height: 87,
    borderBottom: '1px solid #D9D9D9',
    background: '#FBFBFE',
    cursor: 'pointer',
  },
  jobTitle: {
    fontFamily: 'Asap',
    fontSize: 20,
    fontWeight: 700,
    color: '#233160',
    textTransform: 'capitalize',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',

    [theme.breakpoints.down('md')]: {
      maxWidth: '370px',
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
    },
  },
  jobSubTitle: {
    fontFamily: 'Asap',
    fontSize: 16,
    fontWeight: 700,
    color: '#233160',
    textTransform: 'capitalize',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',

    [theme.breakpoints.down('md')]: {
      maxWidth: '370px',
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
    },
  },
  mainContent: {
    padding: 20,
    minHeight: 'calc(100vh - 64px - 87px - 78px)',
    overflow: 'auto',
    maxHeight: 'calc(100vh - 64px - 87px - 78px)',
  },
  mainChatbox: {
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#E1E5EA',
  },
  mMainChatbox: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#E1E5EA',
  },
  fixedLeftContent: {
    left: drawerWidth,
    [theme.breakpoints.down('md')]: {
      left: drawerWidthMd,
    },
  },
  fixedRightContent: {
    right: drawerWidth,
    [theme.breakpoints.down('md')]: {
      right: drawerWidthMd,
    },
  },
  directionLink: {
    fontFamily: 'Asap',
    fontSize: 16,
    fontWeight: 400,
    color: lightAquaGreen,
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  detailCard: {
    padding: 20,
    borderBottom: '1px solid #D9D9D9',
  },
  popupDetailCard: {
    padding: 8,
    borderBottom: '1px solid #D9D9D9',
  },
  sectionTitle: {
    fontFamily: 'Asap',
    fontSize: 16,
    fontWeight: 700,
    color: '#233160',
  },
  address: {
    margin: '15px 0',
  },
  jobDescription: {
    padding: 0,
    margin: 0,
  },
  map: {
    marginTop: 20,
    marginBottom: 10,
    width: '100%',
    height: '280px',
  },
  locationTitle: {
    display: 'flex',
    justifyContent:
      'space-between',
    alignItems: 'center',
    marginTop: 20,
  },
  fastpassImg: {
    width: 20,
    height: 20,
    marginLeft: '5px',
  },
  mChatHeader: {
    position: 'sticky',
    width: '100%',
    top: 0,
    zIndex: 100,
  },
  mChatHeaderContainer: {
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    background: '#E1E5EA',
    borderBottom: '1px solid #D9D9D9',
  },
  backBtn: {
    color: '#364D96',
  },
  calendarBtn: {
    color: '#364D96',
    border: '1px solid #4DBEAD',
    background: '#ffffff',
    boxShadow: '0 4px 4px 0 rgba(0,0,0,0.25)',
    cursor: 'pointer',
    padding: '3px',

    '&:disabled': {
      boxShadow: 'inset 0 4px 4px 0 rgba(0,0,0,.25)',
      border: '1px solid #C4CBD4',
    }
  },
  mJobTitle: {
    fontFamily: 'Asap',
    fontSize: 20,
    fontWeight: 700,
    color: '#233160',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    cursor: 'pointer',
  },
  mMainContent: {
    marginBottom: 100,
  },
  mCalendarPicker: {
    zIndex: 10,
    width: '100%',
    height: '400px',
    background: '#ffffff',
    borderRadius: '3px',
    border: '1px solid #D9D9D9',
  },
  calendarPaper: {
    background: 'red',
  },
  mCalendarDialog: {
    '& .MuiDialog-container': {
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        alignItems: 'flex-end',
      },
    },
    '& .MuiDialog-paper': { 
      border: '1px solid #D9D9D9',
      borderRadius: 0,
      borderTopLeftRadius: 3,
      borderTopRightRadius: 3,
      borderBottom: 'none',
      width: '100%',
      margin: 0,
      maxWidth: 400,
      marginBottom: 80,
      boxShadow: 'none',
    },
  },
  mCalendarDialogHeader: {
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 100,
    
  },
  mCalendarTextDialogHeader: {
    fontFamily: 'Asap',
    fontSize: 16,
    fontWeight: 500,
    color: '#233160',
  },
  mCalendarDetailBody: {
    paddingBottom: 15,
    borderBottom: '1px solid #D9D9D9',
  },
  mDetailHeaderContainer: {
    padding: 15,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: '#E1E5EA',
    borderBottom: '1px solid #D9D9D9',
  },
  mDetailSubTitle: {
    fontFamily: 'Asap',
    fontSize: 16,
    fontWeight: 400,
    color: lightAquaGreen,
    display: 'flex',
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#233160',
  },
  filterButton: {
    color: navy,
    border: `1px solid ${tiffany}`,
  },
  takeShipFabBtn: {
    ...fabBtn,
    backgroundColor: orange,
    color: 'white',
    borderWidth: '1px',
    borderColor: orange,
    position: 'sticky',
    bottom: '20px',
    left: 'calc(50% - 60px)',
    height: '40px',
    width: '127px',

    '& > * + *': {
      fontSize: '16px',
      fontWeight: 600,
    },

    '&:hover': {
      backgroundColor: orange,
    },

    // mobile styles
    [theme.breakpoints.down('md')]: {
      bottom: '70px', 
    },
  },
  takeShiftInfoIcon: {
    color: 'white',
    marginRight: 5,
  },
  shiftInfoIcon: {
    color: iconGray,
    marginRight: 5,
    fontSize: '20px',
  },
  shiftInfo: {
    display: 'flex',
    alignItems: 'center',
  },
});