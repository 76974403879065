import moment from 'moment';
import React, {
  useRef, useCallback, useState, useEffect,
} from 'react';
import { withRouter } from 'react-router-dom';
import { isEmpty, isEqual } from 'lodash';
import {
  Grid,
  makeStyles,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import WeekHeader from './WeekHeader';
import Month from './Month';
import { defaultUtils as utils, addDays } from './dateUtils';
import DateUtilities, { capitalizeFirstLetter } from './utils';
import { green, navy, disableGray } from '../../css/style';
import { IMG_STORAGE_LINK } from '../constants/Constants';

const useStyles = makeStyles(theme => ({
  root: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    maxHeight: '100%',
    overflow: 'hidden',

  },
  selectorContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'flex-start',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: '1px',
  },

  mobileClearAllContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '8px 20px',
    alignItems: 'center',
    backgroundColor: navy,
  },

  toolbarContainer: {
    textAlign: 'center',
    color: '#243060',
    padding: '20px',
    alignItems: 'center',

    [theme.breakpoints.between('lg', 'md')]: {
      padding: '0px 20px',
    },

    '@media screen and (max-width: 1366px) and (min-width: 1024px)' : {
      padding: '0px 20px',
    },

    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      alignItems: 'center',
      alignContent: 'center',
      justifyContent: 'space-between',
    },
  },
  calendarContainer: {
    width: '100%',
    maxWidth: '380px',
    padding: '8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',

    '@media screen and (max-width: 1366px) and (min-width: 1024px)' : {
      maxHeight: 'calc(100vh - 284px)',
      flexDirection: 'row',
      overflowY: 'auto',
    },

    // mobile styles
    [theme.breakpoints.down('xs')]: {
      maxWidth: '300px',
    },
    // tablet styles
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: '360px',
      maxHeight: 'calc(100vh - 284px)',
      overflowY: 'auto',
    },

    '@media screen and (max-height: 460px)' : {
      maxWidth: '60vw',
      flexDirection: 'row',
    },
  },
  calendarItem: {
    marginBottom: '16px',

    '@media screen and (max-width: 1366px) and (min-width: 1024px)' : {
      marginBottom: '0px',
    },
  },
  helper: {
    borderTop: 'solid 1px #f4f4f4',
    paddingTop: 8,
    paddingLeft: 20,
  },
  dateTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 24,
    marginTop: '16px',
    marginBottom: '8px',

    '@media screen and (max-width: 1366px) and (min-width: 1024px)' : {
      marginTop: '0px',
    },
  },
  dateText: {
    fontFamily: 'Asap',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: navy,

    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  changeMonth: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    width: '24px',
    height: '24px',
    padding: '8px',

    '& > *': {
      color: '#243060',
    },
  },
  buttonContainer: {
    width: '100%',
    maxWidth: '380px',
    margin: '0px',
    padding: '0px 20px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '32px',

    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  resetButton: {
    minWidth: '56px',
    height: '32px',
    fontFamily: 'Asap',
    fontSize: '13px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    borderRadius: '4px',
    backgroundColor: 'transparent',
    color: '#243060',
    border: '1px solid #243060',
    textTransform: 'none',

    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto',
    },

    '&:disabled': {
      border: `1px solid ${disableGray}`,
    },
  },
  tag: {
    margin: '0 5px 0 10px',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
  },
  squareTag: {
    margin: '0 5px 0 10px',
    width: '12px',
    height: '12px',
    borderRadius: '2px',
  },
  tagContainer: {
    fontSize: '10px',
    color: '#404A59',
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down('md')]: {
      padding: '10px 0px',
    },
  },
}));

const CalendarFilter = ({
  availableDates,
  confirmedDates,
  initialDate,
  handleStateChange,
  outerFilterDates,
  isMobile = false,
  buttonText = 'Reset',
  surgeryNeededList = [],
  boosted = [],
}) => {
  const calendar = useRef(null);
  const classes = useStyles();

  const [displayDate, setDisplayDate] = useState(() => {
    const dateInStorage = localStorage.getItem('displayDateInStr');
    const date = dateInStorage ? new Date(dateInStorage) : initialDate;
    return utils.getFirstDayOfMonth(date || new Date());
  });

  const [displayDate2, setDisplayDate2] = useState(addDays(displayDate, 31));


  const [filterDates, setFilterDates] = useState(() => {
    const filterDatesInStr = localStorage.getItem('filterDatesInStr');
    let filteredDateArr = filterDatesInStr ? filterDatesInStr.split(',').map(dateText => DateUtilities.midDayDate(dateText)) : [...outerFilterDates] || [];
    filteredDateArr = filteredDateArr.filter(d => DateUtilities.dateIn([...availableDates, ...confirmedDates], d));
    return filteredDateArr;
  });

  const dateTimeFormatted = moment(displayDate).format('MMMM YYYY');
  const dateTimeFormatted2 = moment(displayDate2).format('MMMM YYYY');

  const handleMonthChange = useCallback(
    (months) => {
      setDisplayDate(displayDate => utils.addMonths(displayDate, months));
    },
    [setDisplayDate],
  );

  useEffect(() => {
    if(!isEqual(outerFilterDates, filterDates)) {
      setFilterDates([...outerFilterDates]);
    }
  }, [outerFilterDates]);

  useEffect(() => {
    localStorage.setItem('displayDateInStr', displayDate.toISOString());
    setDisplayDate2(addDays(displayDate, 31));
  }, [displayDate]);

  const maxDate = utils.addYears(new Date(), 100);
  const minDate = utils.addYears(new Date(), -100);

  const toolbarInteractions = {
    prevMonth: utils.monthDiff(displayDate, minDate) > 0,
    nextMonth: utils.monthDiff(displayDate, maxDate) < 0,
  };

  const handleTouchTapPrevMonth = (e) => {
    e.preventDefault();
    const months = isMobile ? -1 : -2;
    handleMonthChange(months);
  };

  const handleTouchTapNextMonth = (e) => {
    e.preventDefault();
    const months = isMobile ? 1 : 2;
    handleMonthChange(months);
  };

  const renderCalendar = (displayDate, dateTimeFormatted) => (
    <>
      { !isMobile && (<Grid item xs={12} className={classes.dateTextContainer} key={`monthName_${dateTimeFormatted}`}>
        <Typography variant='subtitle1' className={classes.dateText}>{capitalizeFirstLetter(dateTimeFormatted)}</Typography>
      </Grid>)}
      
      <Grid item xs={12} key={`weekHeader_${dateTimeFormatted}`}>
        <WeekHeader largeSize/>
      </Grid>

      <Grid item xs={12} className={classes.calendarItem} key={`month_${dateTimeFormatted}`}>
        <Month
          displayDate={displayDate}
          key={displayDate.toDateString()}
          selectedDates={availableDates}
          requestedDates={[]}
          confirmedDates={confirmedDates || []}
          minDate={minDate}
          maxDate={maxDate}
          onSelect={(date) => {
            if (!date || (!DateUtilities.dateIn(availableDates, date) && !DateUtilities.dateIn(confirmedDates, date))) return;
            let dateList;
            if (isEmpty(filterDates)) {
              dateList = [date];
            } else if (!DateUtilities.dateIn(filterDates, date)) {
              dateList = [...filterDates, date];
            } else {
              dateList = filterDates.filter(d => !DateUtilities.isSameDay(d, date));
            }
            setFilterDates(dateList);
            handleStateChange(dateList);
          }}
          ref={calendar}
          highlightedList={!isEmpty(filterDates)
            ? filterDates.filter(d => DateUtilities.dateIn(availableDates, d) || DateUtilities.dateIn(confirmedDates, d))
            : []}
          largeSize
          surgeryNeededList={surgeryNeededList}
          boosted={boosted}
        />
      </Grid>
    </>
  )

  return (
    <div className={classes.root}>
      <div className={classes.selectorContainer}>
      {!isMobile ? (
        <Grid container className={classes.toolbarContainer}>
          <Grid item xs={1} className={classes.changeMonth}>
            <IconButton disabled={!toolbarInteractions.prevMonth} onClick={handleTouchTapPrevMonth}>
              <ArrowBackIosIcon fontSize='small' />
            </IconButton>
          </Grid>
          <Grid item xs={10} className={classes.tagContainer}>
            <div className={classes.tag} style={{ backgroundColor: green }} />
              Available
            <div className={classes.squareTag} style={{ backgroundColor: navy }} />
              Confirmed
            { !isEmpty(boosted) && (<><img
              src={`${IMG_STORAGE_LINK}dollar.svg`}
              width='14'
              alt='boosted'
              style={{ marginLeft: '8px' }}
            />
              Boosted
              </>)}
              {/* <span>
                S<small>x</small>
              </span>
              Surgery */}
          </Grid>
          <Grid item xs={1} className={classes.changeMonth}>
            <IconButton disabled={!toolbarInteractions.nextMonth} onClick={handleTouchTapNextMonth}>
              <ArrowForwardIosIcon fontSize='small' />
            </IconButton>
          </Grid>
        </Grid>
      )
      : (
        <Grid container item className={classes.mobileClearAllContainer}>
          <IconButton
            onClick={() => {
              setFilterDates([]);
              handleStateChange([]);
            }}
            style={{ padding : 0 }}
          >
            <Typography style={{ color: '#fff', fontSize: '13px', fontWeight: 500 }}>{buttonText}</Typography>
          </IconButton>
        </Grid>
      )}

      {isMobile && (
        <Grid container className={classes.toolbarContainer}>
          <Grid item xs={1} className={classes.changeMonth}>
            <IconButton disabled={!toolbarInteractions.prevMonth} onClick={handleTouchTapPrevMonth}>
              <ArrowBackIosIcon fontSize='small' />
            </IconButton>
          </Grid>
          <Grid item xs={10} className={classes.dateTextContainer} key={`monthName_${dateTimeFormatted}`}>
            <Typography variant='subtitle1' className={classes.dateText}>{capitalizeFirstLetter(dateTimeFormatted)}</Typography>
          </Grid>
          <Grid item xs={1} className={classes.changeMonth}>
            <IconButton disabled={!toolbarInteractions.nextMonth} onClick={handleTouchTapNextMonth}>
              <ArrowForwardIosIcon fontSize='small' />
            </IconButton>
          </Grid>
        </Grid>
      )}

          {/* Calendars start*/}
          <Grid container
            className={classes.calendarContainer}
          >
            {renderCalendar(displayDate, dateTimeFormatted)}
            {!isMobile && renderCalendar(displayDate2, dateTimeFormatted2)}
            {/* Calendars end*/}
            {isMobile &&
              (
              <Grid item xs={12} className={classes.tagContainer}>
                <div className={classes.tag} style={{ backgroundColor: green }} />
                  Available
                <div className={classes.squareTag} style={{ backgroundColor: navy }} />
                  Confirmed
              </Grid>
            )}
            {!isMobile && (
              <div className={classes.buttonContainer}>
                <Button
                  onClick={() => {
                    setFilterDates([]);
                    handleStateChange([]);
                  }}
                  className={classes.resetButton}
                  disabled={isEmpty(filterDates)}
                >
                  {buttonText}
                </Button>
              </div>
            )}
        </Grid>
          
      </div>
    </div>
  );
};

export default withRouter(CalendarFilter);
