import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { coreViewStyle } from '../../css/style';
import DrawerView from './components/DrawerView';
import NavAppBar from './components/NavAppBar';
import BottomNav from './components/BottomNav';
import ReleaseNoteDialog from './../subcomponents/ReleaseNoteDialog';
import { updateLocalUser } from '../../utils/Functions';
import { DESKTOP_BREAKPOINT } from '../constants/Constants';

class CoreView extends React.Component {
  state = {
    open: false,
    isReleaseNoteDialogOpen: false,
    releaseNote: null,
  };

  componentDidMount() {
    const { user } = this.props;
    if (user && user.releaseNote) {
      this.setState({ releaseNote: user.releaseNote })
    }
  }

  handleDrawerOpenAndClose = () => {
    this.setState({ open: !this.state.open });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.path !== this.props.match.path) {
      try {
        if (this.props.match.path.includes('matches')) {
          this.scrollToSelectedMatch();
        } else {
          this.scrollToTopLeft();
        }
      } catch (e) {
        console.log(e);
      }
    }

    if (prevProps.user !== this.props.user) {
      if (this.props.user && this.props.user.releaseNote) {
        this.setState({ releaseNote: this.props.user.releaseNote })
      }
    }
  }

  scrollToTopLeft() {
    window.scrollTo(0, 0);
    document.getElementById('main').scrollTo(0, 0);
  }

  scrollToSelectedMatch() {
    const scollToMatchId = localStorage.getItem('viewDetailsMatchIdStr');
    if (scollToMatchId) {
      const jobCardElement = document.getElementById(`${scollToMatchId}_card`);
      const jobCardElementCoor = jobCardElement ? jobCardElement.getBoundingClientRect() : null;
      if (jobCardElementCoor) {
        document.getElementById('main').scrollTo(jobCardElementCoor.x, jobCardElementCoor.y - jobCardElementCoor.height);
        localStorage.setItem('viewDetailsMatchIdStr', '');
      } else {
        this.scrollToTopLeft();
      }
    }
  }

  checkTemporaryUser(email) {
    return email.includes('temp-user-signup--') ? true : false;
  }

  onCloseReleaseNoteDialog = () => {
    this.setState({ isReleaseNoteDialogOpen: false })
  }

  onOpenReleaseNoteDialog = () => {
    const { actions, user } = this.props;

    this.setState({ isReleaseNoteDialogOpen: true });

    if (!user.isReadReleaseNote) {
      actions.updateResource('releasenotes', user.token, {}, 'release-notes', 'read', this.state.releaseNote.id);
      const localUser = user;
      localUser.isReadReleaseNote = true;
      updateLocalUser(localUser);
    }
  }

  render() {
    const {
      classes, children, name, user, actions, resources, width,
    } = this.props;

    const { open } = this.state;
    let isTemporaryUser = false;
    if (user) isTemporaryUser = this.checkTemporaryUser(user.email);
    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <NavAppBar
            onClickDrawerOpenAndClose={this.handleDrawerOpenAndClose}
            name={name}
            user={user}
            actions={actions}
            resources={resources}
            isTemporaryUser={isTemporaryUser}
            onOpenReleaseNoteDialog={this.onOpenReleaseNoteDialog}
          />
          <DrawerView
            open={open}
            onClickDrawerOpenAndClose={this.handleDrawerOpenAndClose}
            user={user}
            actions={actions}
            isTemporaryUser={isTemporaryUser}
            onOpenReleaseNoteDialog={this.onOpenReleaseNoteDialog}
          />
          <ReleaseNoteDialog
            isDialogOpen={this.state.isReleaseNoteDialogOpen}
            onCloseDialog={this.onCloseReleaseNoteDialog}
            content={this.state.releaseNote}
          />
          <main id='main' className={classes.content}>
            {children}
            {/* <footer className={classes.footer}>
              <Typography className={classes.footerText}>
                {new Date().getFullYear()}
                |
                Jobs Unleashed
              </Typography>
              <Hidden xsDown>
                <Typography className={classes.footerLink}>
                  Terms & Conditions | Privacy Policy
                </Typography>
              </Hidden>
            </footer> */}
          </main>
          { !isWidthUp(DESKTOP_BREAKPOINT, width) && (<BottomNav
            onClickDrawerOpenAndClose={this.handleDrawerOpenAndClose}
            name={name}
            user={user}
            actions={actions}
            resources={resources}
            isTemporaryUser={isTemporaryUser}
            onOpenReleaseNoteDialog={this.onOpenReleaseNoteDialog}
          />
          )}
        </div>
      </div>
    );
  }
}

CoreView.propTypes = {};

export default withStyles(coreViewStyle)(withWidth()(withRouter(CoreView)));