import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { isEqual } from 'lodash';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { TalentHomePageStyle } from '../../css/style';
import { countUnreadMessagesByMatch } from '../../utils/Functions';
import ConnectionsComponent from './ConnectionsComponent';
import SettingsComponent from './SettingsComponent';

const tabs = [
  {
    title: 'My Matches',
    link: '/matches',
  },
  {
    title: 'Whisker Search',
    link: '/search',
  },
  {
    title: 'My Profile',
    link: '/profile',
  },
];

export class TalnetHomePageTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unreadMsgCounts: {},
    };
  }

  componentDidUpdate = async (prevProps) => {
    const { activeTab } = this.props;
    const oldActiveTab = prevProps.activeTab;
    if (activeTab !== oldActiveTab) {
      if (activeTab === 0) {
        this.matchesPageRef && this.matchesPageRef.refreshItems();
      }
      if (activeTab === 2) {
        this.profilePageRef && this.profilePageRef.refreshState();
      }
    }
    if (!isEqual(prevProps.resources.messages, this.props.resources.messages)) {
      const { resources, user } = this.props;
      const { messages } = resources;
      const unreadMsgCounts = countUnreadMessagesByMatch(messages, user);
      this.setState({ unreadMsgCounts });
    }
  }

  componentDidMount = async () => {
    const { actions, user, match } = this.props;
    const { path } = match;
    if (!path.includes('verify')) {
      await actions.getAllResources(user.token, 'messages/count-unread-msg-by-match')
        .then((res) => {
          if (!res.error) {
            const unreadMsgCounts = res.response || {};
            this.setState({ unreadMsgCounts });
          }
        });
    }
  }

  // render those components with activate tab that is set to null
  renderNullTabs = () => {
    const {
      actions, resources, user, match,
    } = this.props;
    const { unreadMsgCounts } = this.state;
    const { path } = match;
    const { matchId } = match.params;
    if (path.includes('connections')) {
      return (
        <ConnectionsComponent
          user={user}
          actions={actions}
          resources={resources}
          unreadMsgCounts={unreadMsgCounts}
        />
      );
    } else if (path.includes('settings') || path.includes('verify')) {
      return (
        <SettingsComponent
          user={user}
          actions={actions}
          resources={resources}
        />
      );
    }
  };


  render() {
    const {
      classes, history, activeTab, actions, resources, user, match,
    } = this.props;
    const { path } = match;
    const { unreadMsgCounts } = this.state;

    return (
      <Box className={classes.boxContainer}>
        <Paper square>
          <Tabs
            className={classes.allTabsContainer}
            value={activeTab === null ? false : activeTab}
            onChange={(e, tab) => {
              history.push(tabs[tab].link);
              if (tab === 0) {
                this.matchesPageRef && this.matchesPageRef.refreshItems();
              }
              if (tab === 2) {
                this.profilePageRef && this.profilePageRef.refreshState();
              }
            }}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            {tabs.map(tab => <Tab className={classes.tabs} key={tab.link} label={tab.title} />)}
          </Tabs>
        </Paper>
        <Box hidden={activeTab !== null} className={classes.nullPages}>
          {this.renderNullTabs()}
        </Box>
      </Box>
    );
  }
}

TalnetHomePageTabs.propTypes = {
  activeTab: PropTypes.number,
};

TalnetHomePageTabs.defaultProps = {
  activeTab: null,
};

export default withStyles(TalentHomePageStyle)(withRouter(TalnetHomePageTabs));
