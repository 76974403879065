/* eslint-disable no-unused-vars */
import { IMG_STORAGE_LINK } from '../components/constants/Constants';

const photoBannerDesktop = `${IMG_STORAGE_LINK}homeBannerDesktop.jpg`;
const photoBannerMobile = `${IMG_STORAGE_LINK}homeBannerMobile.jpg`;
const icCheck = `${IMG_STORAGE_LINK}ic-check.svg`;

export const drawerWidth = 300;
export const lightNavy = '#364D96';
export const navy = '#243060';
export const navyDark = '#000736';
export const lightBlue = '#556377';
export const tiffany = '#4bbdad';
export const tiffanyDark = '#008c7e';
export const aquaGreen = '#4DBEAD';
export const green = '#5aac44';
export const  lightAquaGreen = '#379587';
export const orange = '#FC4319';
export const orangeDark = '#c10000';
export const orangeLight = '#ff8f00';
export const gray = '#9e9e9e';
export const iconGray = '#8896AA';
export const lightGray = '#C4CBD4';
export const disableGray = '#CACACE';
export const textColor = '#2B323B';
export const bgGray = '#FBFBFE';
export const bgLightGray = '#F9F9F9';
export const armyGreen = '#297065';
export const errorRed = '#CC0202';


export const drawerClosedWidth = 0;
export const appBarHeight = 60;

export const fabBtn = {
  position: 'fixed',
  height: 31,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  fontFamily: 'Asap',
  fontSize: '16px',
  fontWeight: 700,
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: '4px',
  padding: '6px 12px',
  gap: '10px',
  textTransform: 'none',
};

export const ellipsis = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

export const closeDrawer = {
  position: 'fixed',
  border: `1px solid ${tiffany}`,
  borderRadius: '50%',
  cursor: 'pointer!important',
  backgroundColor: 'white',
  boxShadow: '0 0 7px 0 rgba(0, 0, 0, 0.12)',
  justifyContent: 'center',
  alignItems: 'center',
  color: navy,
};

export const UserInfoStyle = theme => ({
  userInfoGridContainer: {
    height: '100%',
  },
  infoTitle: {
    height: 16,
    fontFamily: 'Asap',
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: `${navy}`,
    marginTop: '24px',
    minWidth: '300px',
    marginBottom: 24,
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 0,
      marginBottom: '12px',
    },
  },
  // divider styles
  divider: {
    width: '90%',
    height: '10px',
    borderBottom: '1px solid black',
    textAlign: 'center',
    margin: '10px 5% 10px 5%',
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      width: 10,
      height: 250,
      borderRight: '1px solid black',
      borderBottom: 0,
      marginLeft: -30,
      marginRight: -15,
      paddingTop: 120,
      paddingRight: 15,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  dividerText: {
    width: '14px',
    height: 14,
    fontFamily: 'Asap',
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    padding: '0 10px',
    backgroundColor: '#fafafa',
  },
  // Email Bar Style
  emailBarContainer: {
    width: '80%',
    margin: '0 10%',
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      margin: 0,
      padding: '0px 15% 12px 5%',
    },
  },
  // Social Bar STyle
  socialBarContainer: {
    // width: '80%',
    // margin: '0 10%',
    // // desktop styles
    // [theme.breakpoints.up('lg')]: {
    //   margin: 0,
    //   padding: '12px 5% 12px 15%',
    // },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '5%',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  socialMediaTitle: {
    fontFamily: 'Asap',
    fontSize: '16px',
    width: '80%',
    margin: '22px 0',
    color: '#808080',
  },
});

export const BackButtonStyle = theme => ({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  back: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontStyle: 'italic',
    fontWeight: '700',
    fontSize: '14px',
    fontFamily: 'Asap',
    color: `${navy}`,
  },
});

export const SignUpPageStyle = theme => ({
  // leaving this here for when we fix the footer
  signUpGridContainer: {
    [theme.breakpoints.down('sm')]: {
      marginTop: appBarHeight,
    },
  },
  signUpTopContainer: {
    height: 210,
    width: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // // desktop styles
    [theme.breakpoints.up('lg')]: {
      maxWidth: 300,
      marginRight: 45,
    },
  },
  signInTopContainer: {
    height: 210,
    width: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      maxWidth: 600,
      marginRight: 45,
    },
  },
  signUpTitle: {
    width: 332,
    height: 90,
    fontFamily: 'Asap',
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: `${navy}`,
    marginTop: '24px',
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      width: '80%',
      margin: '24px 10% 0 10%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  hello: {
    backgroundColor: 'black',
  },
  // Email Input Styles
  emailInputContainer: {
    // height: 210,
    width: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    // paddingBottom: 20,
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      maxWidth: 350,
      alignItems: 'flex-start',
    },
  },
  // Email Input in Login Form
  emailInputLoginContainer: {
    width: 300,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingBottom: 20,

    // desktop styles
    [theme.breakpoints.up('lg')]: {
      maxWidth: 700,
      alignItems: 'flex-start',
      marginLeft: '22%',
    },

    '@media (min-width: 1536px)': {
      marginLeft: '18%',
    },

    // tablet & mobile styles
    [theme.breakpoints.down('md')]: {
      margin: 10,
    },
  },
  privacyPolicyContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '15px 0',
  },
  privacyPolicyText: {
    height: 29,
    fontFamily: 'Asap',
    fontSize: 13,
    fontWeight: 'normal',
    fontStyle: 'italic',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#000000',
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      textAlign: 'left',
    },
  },
  newLineDesktop: {
    display: 'block',
  },
  loginTitle: {
    display: 'inline-block',
    color: '#243060',
    fontFamily: 'Asap',
    fontSize: 16,
    fontWeight: 'bold',
    height: 27,
  },
  link: {
    display: 'inline-block',
    cursor: 'pointer',
    fontWeight: 700,
    textDecoration: 'underline',
    fontFamily: 'Asap',
    color: orange,
    fontStyle: 'italic',
  },
  memberSignIn: {
    height: '60px',
    width: '80%',
    margin: '0 10%',
    fontFamily: 'Asap',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    [theme.breakpoints.up('lg')]: {
      width: '20%',
      margin: '0 40%',
    },
  },
  signInButton: {
    backgroundColor: `${orange}`,
    color: '#FFFFFF',
    fontFamily: 'Asap',
    fontStyle: 'Bold',
    fontSize: '12px',
    fontWeight: 700,
    padding: 0,
    height: '15px',
    minHeight: '30px',
    minWidth: '60px',
    '&:hover': {
      backgroundColor: `${orangeDark}`,
    },
  },
  hiringContainer: {
    height: '75px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    fontFamily: 'Asap',
    fontSize: 16,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  hiringText: {
    // [theme.breakpoints.up('lg')]: {
    //   marginRight: '12px',
    // },
    display: 'flex',
    alignItems: 'center',
  },
  // divider styles
  divider: {
    width: '90%',
    height: '10px',
    borderBottom: '1px solid black',
    textAlign: 'center',
    margin: '10px 5% 10px 5%',
    [theme.breakpoints.up('lg')]: {
      width: '10px',
      height: 250,
      borderRight: '1px solid black',
      borderBottom: '0',
      marginLeft: '-30px',
      marginRight: '-15px',
      paddingTop: 120,
      paddingRight: 15,
      marginTop: -20,
    },
  },
  buttonText: {
    fontWeight: 700,
  },
  dividerText: {
    width: '14px',
    height: 14,
    fontFamily: 'Asap',
    fontSize: 14,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    padding: '0 10px',
    backgroundColor: '#fafafa',
  },
  whiskerBotBanner: {
    marginTop: 60,
  },
  signupButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '200px',
    justifyContent: 'space-around',
    margin: '10px 0 10px 0',
  },
});

export const EmailBarStyle = theme => ({
  emailBarContainer: {
    width: '100%',
    minWidth: '300px',
    height: '155px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  emailInput: {
    width: '100%',
    height: 45,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  emailInputText: {
    height: 45,
    fontFamily: 'Asap',
    fontSize: 14,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#505063',
  },
  emailSubmitButton: {
    backgroundColor: orange,
    color: '#fff',
    width: '100%',
    height: 45,
    borderRadius: 5,
    fontFamily: 'Asap',
    fontSize: 12,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: orange,
      color: '#fff',
    },
  },
  loginErrorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  loginError: {
    fontSize: '14px',
    color: orange,
  },
});

export const SocialBarStyle = theme => ({
  socialBarContainer: {
    height: '210px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    minWidth: '300px',
  },
  socialButton: {
    width: '100%',
    borderRadius: 5,
    height: '45px',
    display: 'flex',
    flexDirection: 'column',
  },
  socialButtonContent: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '275px',
    minWidth: '210px',
    height: 40,
    fontFamily: 'Asap',
    fontSize: 13,
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
  },
  socialButtonLogo: {
    height: '34px',
    width: '34px',
    marginRight: '12px',
  },
  shrinkLogo: {
    padding: '6px',
    marginRight: '12px',
  },
  offSetLeft: {
    height: '39px',
    width: '39px',
    marginRight: '5px',
    marginLeft: '2px',
  },
  facebook: {
    backgroundColor: '#3b5998',
    '&:hover': {
      backgroundColor: '#3b5998',
    },
  },
  twitter: {
    backgroundColor: '#50abf1',
    '&:hover': {
      backgroundColor: '#50abf1',
    },
  },
  google: {
    backgroundColor: '#dc4e41',
    '&:hover': {
      backgroundColor: '#dc4e41',
    },
  },
  linkedin: {
    backgroundColor: '#007aaa',
    '&:hover': {
      backgroundColor: '#007aaa',
    },
  },
});

export const JobSearchBarStyle = theme => ({
  // jobSearchContainer styles
  jobSearchForm: {
    fontFamily: 'Asap',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  jobSearchInputContainer: {
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  jobSearchButton: {
    background: `${navy}`,
    color: '#FFFFFF',
    height: '32px',
    fontFamily: 'Asap',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '6px',
    // mobile styles
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      top: '5px',
    },
    // tablet styles
    [theme.breakpoints.between('sm', 'md')]: {
      width: '100%',
      top: '5px',
    },
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      width: '200px',
      top: 0,
    },
  },
  jobSearchInput: {
    width: '100%',
    fontFamily: 'Asap',
    fontSize: 14,
  },
  jobSearchInputText: {
    fontFamily: 'Asap',
    fontSize: 14,
    color: '#505063',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export const WhiskerBotStyle = theme => ({
  whiskerBotBanner: {
    width: '100%',
  },
});

export const JobCardStyle = theme => ({
  searchResultCard: {
    height: '125px',
    minWidth: '225px',
    // mobile styles
    [theme.breakpoints.down('xs')]: {
      marginRight: 12,
    },
    // tablet styles
    [theme.breakpoints.between('sm', 'md')]: {
      marginRight: 12,
    },
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      width: '47%',
    },
  },
  searchResultLocation: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '24px',
  },
  searchResultJobTitle: {
    fontFamily: 'Asap',
    fontSize: '16px',
    fontWeight: '700',
    color: `${navy}`,
  },
});

export const loginPageStyle = theme => ({
  inputFieldContainer: {
    marginTop: '50px',
  },
  inputTitle: {
    textAlign: 'left',
    marginBottom: '8px',
    color: '#a7a9ac',
    fontWeight: 600,
  },
  inputFieldStyle: {
    width: '380px',
    color: '#000000',
  },
  inputStyle: {
    width: '380px',
    height: '25px',
    textAlign: 'center',
    fontWeight: 600,
  },
  loginButton: {
    width: '380px',
    height: '51px',
    fontSize: '14px',
    fontWeight: 600,
    marginTop: '42px',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: '400px',
    minHeight: '635px',
  },
});

export const coreViewStyle = theme => ({
  root: {
    width: '100%',
    zIndex: 1,
    height: '100vh',
    overflowY: 'hidden',
  },
  appFrame: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  content: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    marginTop: `${appBarHeight}px`,
    marginBottom: 0,
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerClosedWidth,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',

    '@media screen and (max-height: 460px)' : {
      overflow: 'auto',
    },

    [theme.breakpoints.down('sm')]: {
      minHeight: '100vh',
      marginTop: 0,
    },
  },
  footer: {
    height: 64,
    minHeight: 64,
    backgroundColor: `${navy}`,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  footerText: {
    fontSize: 14,
    color: '#4bbdad',
    fontStyle: 'italic',
  },
  footerLink: {
    fontSize: 14,
    color: '#4bbdad',
    fontStyle: 'italic',
  },
});

export const NavAppBarStyle = theme => ({
  appBar: {
    position: 'fixed',
    height: `${appBarHeight}px`,
    flexDirection: 'row',
    width: `calc(100% - ${drawerClosedWidth + 1}px)`,
    zIndex: theme.zIndex.drawer - 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    boxShadow: 'none',

  },
  appBarPaper: {
    backgroundColor: '#fafafa',
    boxShadow: '0px 4px 4px 0px rgba(0,0,0,0.25)',
    width: '100%',
  },
  appBarInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    paddingRight: '24px',
  },
  // mobileHeader: {

  // }
  signInButton: {
    backgroundColor: `${orange}`,
    color: '#FFFFFF',
    fontFamily: 'Asap',
    fontWeight: 700,
    fontSize: '12px',
    padding: 0,
    minHeight: '25px',
    minWidth: '60px',
    '&:hover': {
      backgroundColor: `${orangeDark}`,
    },
  },
  menuIcon: {
    padding: 0,
    minWidth: '24px',
    minHeight: '24px',
    position: 'absolute',
    zIndex: 201,
    top: '4px',
    left: '4px',
  },
  userMenu: {
    fontWeight: 700,
    textTransform: 'capitalize',
    fontStyle: 'italic',
  },
  userInfoNickName: {
    fontFamily: 'Asap',
    fontWeight: '700',
    color: `${navy}`,
    paddingLeft: 5,
    textTransform: 'uppercase',
  },
  version: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 15px',
    fontSize: '12px',
    cursor: 'pointer',
  },
  animatedIcon: {
    animation: '$scale-effect 1.5s infinite',
    fontSize: '10px',
    color: `${orange}`,
    fontSize: '10px',
    transformOrigin: 'center',
    paddingRight: '15px',
    paddingBottom: '12px',
    margin: '0 5px',
  },
  '@keyframes scale-effect': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.5)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  userInfo: {
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: '15px',
  },
  signoutLink: {
    color: `${tiffany}`,
    margin: '0 5px',
    fontStyle: 'italic',
    fontFamily: 'Asap',
    fontSize: '12px',
  },
  menuItem: {
    justifyContent: 'flex-end',
  },
  logo: {
    padding: 0,
    height: 44,
    marginRight: '16px',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      paddingLeft: 24,
    },
  },

  navMenuButton: {
    color: `${navy}`,
    fontFamily: 'Asap',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'none',
    '&:hover': {
      background: 'none',
    },
  },
  avatarIcon: {
    backgroundColor: iconGray,
    width: '24px',
    height: '24px',
    color: '#f0f0f0',
    fontSize: '16px'
  },

  toolBarIcon: {
    color: iconGray,
  },
  toolBarIconBtn: {
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  toolBarIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: '20px',
  },
  lensIcon: {
    color: orange,
    fontSize: '12px',
    padding: '0',
    position: 'absolute',
    bottom: '15px',
    left: '20px',
  },
  userNameBtn: {
    display: 'flex',
    padding: 0,
  },
  moreIcon: {
    color: iconGray,
    width: '16px',
    height: '16px',
  },
  rightContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  chatBubbleText: {
    color: lightBlue,
    fontSize: '10px',
    textTransform: 'capitalize',
  },
});

export const DrawerViewStyle = theme => ({
  drawerInner: {
    // Make the items inside not wrap when transitioning:
    width: drawerWidth,
    height: '100%',
  },
  drawerNoHead: {
    width: drawerWidth,
    height: '42%',
  },
  drawerHead: {
    width: drawerWidth,
    height: '42%',
    backgroundColor: `${navy}`,
  },
  drawerHeadCloseButton: {
    height: '10%',
    width: '24px',
    minWidth: '24px',
    position: 'relative',
    left: `${drawerWidth - 36}px`,
  },
  drawerHeadCreateAccount: {
    width: '80%',
    marginLeft: '10%',
    textAlign: 'center',
    height: '65%',
    fontSize: '15px',
    lineHeight: '21px',
    color: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Asap',
  },
  drawerHeadCreateAccountButton: {
    width: '80%',
    height: '40px',
    background: `${tiffany}`,
    color: '#FFFFFF',
    fontFamily: 'Asap',
    fontSize: '12px',
    fontStyle: 'Bold',
    marginTop: '30px',
    fontWeight: '500',
  },
  drawerHeadSignIn: {
    height: '22%',
  },
  drawerHeadHorizontalRule: {
    width: '80%',
    borderColor: '#596699',
  },
  drawerHeadMemberSignIn: {
    color: '#ffffff',
    width: '80%',
    marginLeft: '10%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 'calc(100% - 18px)',
  },
  drawerHeadMemberSignInButton: {
    backgroundColor: `${orange}`,
    color: '#FFFFFF',
    fontFamily: 'Asap',
    fontSize: '12px',
    padding: 0,
    minHeight: '25px',
    minWidth: '60px',
    '&:hover': {
      backgroundColor: `${orangeDark}`,
    },
  },
  drawerBody: {
    width: drawerWidth,
    height: '50%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  drawerUserInfoNickName: {
    fontFamily: 'Asap',
    fontWeight: '700',
    color: `${navy}`,
    paddingLeft: 5,
    fontStyle: 'italic',
    textTransform: 'uppercase',
  },
  drawerUserInfo: {
    width: drawerWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: '15px',
  },
  drawerFooter: {
    width: drawerWidth,
    height: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px',
    marginTop: '20px',
  },
  drawerVersion: {
    fontFamily: 'Asap',
    fontSize: '16px',
    width: drawerWidth,
    height: '10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '15px 15px',
    cursor: 'pointer',
  },
  animatedIcon: {
    animation: '$scale-effect 1.5s infinite',
    fontSize: '10px',
    color: `${orange}`,
    fontSize: '10px',
    transformOrigin: 'center',
    paddingRight: '15px',
    paddingBottom: '12px',
    margin: '0 5px',
  },
  '@keyframes scale-effect': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.5)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  drawerFooterLinkContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  drawerFooterLink: {
    color: `${navy}`,
    fontStyle: 'italic',
    fontFamily: 'Asap',
    fontSize: '12px',
  },
  drawerSignoutLink: {
    color: `${tiffany}`,
    margin: '0 5px',
    fontStyle: 'italic',
    fontFamily: 'Asap',
    fontSize: '12px',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
  },
  listItem: {
    width: '100%',
    height: '55px',
    padding: '0',

    '@media screen and (max-height: 460px)' : {
      height: '35px',
    },
  },
  drawerButton: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: '20px',
    fontFamily: 'Asap',
    fontSize: '16px',
    color: '#000000',
    textTransform: 'none',
  },
  drawerHeaderButton: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: '20px',
    fontFamily: 'Asap',
    fontSize: '16px',
    color: '#fff',
    textTransform: 'none',
  },
});


export const TalentTypePageStyle = theme => ({
  talentTypeContainer: {},
  talentTypeInnerContainer: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  talentTypeGridContainer: {
    width: '90%',
    margin: '0 5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    // mobile styles
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      width: '100%',
    },
    // tablet styles
    [theme.breakpoints.between('sm', 'md')]: {
      width: '90%',
      margin: '0 5%',
    },
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      width: '90%',
      margin: '0 5%',
    },
  },
  talentTypeGridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  talentTitle: {
    width: '100%',
    height: '60px',
    fontSize: '20px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  selectableIconContainer: {
    // mobile styles
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      width: 302,
    },
    // tablet styles
    [theme.breakpoints.between('sm', 'md')]: {
      margin: 0,
      width: 516,
    },
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      margin: 0,
      width: 1146,
    },
  },
  narrowSelectableIconContainer: {
    margin: 0,
    width: 400,

    // tablet & mobile styles
    [theme.breakpoints.down('xs')]: {
      width: 302,
    },
  },
});

export const SimpleSelectableSpecialty = theme => ({
  square: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '10px 10px',
    [theme.breakpoints.down('xs')]: {
      width: 160,
      height: 160,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 170,
      height: 170,
    },
    [theme.breakpoints.up('lg')]: {
      width: 175,
      height: 175,
    },
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
    '&:active': {
      backgroundColor: '#D3D3D3',
    },
  },
  selectedSquare: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '10px 10px',
    [theme.breakpoints.down('xs')]: {
      width: 160,
      height: 160,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 170,
      height: 170,
    },
    [theme.breakpoints.up('lg')]: {
      width: 175,
      height: 175,
    },
  },
  imageContainer: {
    height: 70,
    maxWidth: '100%',
    textAlign: 'center',
  },
  image: {
    height: 64,
    maxWidth: 'inherit',
  },
  title: {
    display: 'flex',
    fontFamily: 'Asap',
    alignItems: 'flex-end',
    justifyContent: 'center',
    fontSize: '14px',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  squareText: {
    fontWeight: 700,
    fontSize: '14px',
  },
});

export const SimpleSelectableSquareStyle = theme => ({
  square: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '10px 10px',
    [theme.breakpoints.down('xs')]: {
      width: 145,
      height: 145,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 156,
      height: 156,
    },
    [theme.breakpoints.up('lg')]: {
      width: 175,
      height: 175,
    },
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
    '&:active': {
      backgroundColor: '#D3D3D3',
    },
  },
  selectedSquare: {
    '& img': {
      filter: 'brightness(100)',
    },
    width: 156,
    height: 156,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '10px 10px',
    [theme.breakpoints.down('xs')]: {
      width: 145,
      height: 145,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 156,
      height: 156,
    },
    [theme.breakpoints.up('lg')]: {
      width: 175,
      height: 175,
    },
  },
  imageContainer: {
    height: 70,
    maxWidth: '100%',
    textAlign: 'center',
  },
  centeredImageContainer: {
    height: 70,
    maxWidth: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    height: 64,
    maxWidth: 'inherit',
  },
  largeWidthImage: {
    height: 50,
    maxWidth: 'inherit',
  },
  title: {
    display: 'flex',
    fontFamily: 'Asap',
    alignItems: 'flex-end',
    justifyContent: 'center',
    fontSize: '14px',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  squareText: {
    fontWeight: 700,
  },
});

export const OnboardingSearchesSimpleSelectableSquareStyle = theme => ({
  square: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '10px 10px',
    [theme.breakpoints.down('xs')]: {
      width: 135,
      height: 135,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 156,
      height: 156,
    },
    [theme.breakpoints.up('lg')]: {
      width: 175,
      height: 175,
    },
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
    '&:active': {
      backgroundColor: '#D3D3D3',
    },
  },
  selectedSquare: {
    '& img': {
      filter: 'brightness(100)',
    },
    width: 156,
    height: 156,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '10px 10px',
    [theme.breakpoints.down('xs')]: {
      width: 135,
      height: 135,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 156,
      height: 156,
    },
    [theme.breakpoints.up('lg')]: {
      width: 175,
      height: 175,
    },
  },
  imageContainer: {
    height: 70,
    maxWidth: '100%',
    textAlign: 'center',
  },
  centeredImageContainer: {
    height: 70,
    maxWidth: '100%',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  image: {
    height: 64,
    maxWidth: 'inherit',
  },
  largeWidthImage: {
    height: 50,
    maxWidth: 'inherit',
  },
  title: {
    display: 'flex',
    fontFamily: 'Asap',
    alignItems: 'flex-end',
    justifyContent: 'center',
    fontSize: '14px',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  squareText: {
    fontWeight: 700,
    lineHeight: 1.2,
  },
});
export const SimpleSelectableSquareNoIconStyle = theme => ({
  square: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '10px 10px',
    [theme.breakpoints.down('xs')]: {
      width: 90,
      height: 90,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 156,
      height: 156,
    },
    [theme.breakpoints.up('lg')]: {
      width: 175,
      height: 175,
    },
    '&:hover': {
      backgroundColor: '#EDEDED',
    },
    '&:active': {
      backgroundColor: '#D3D3D3',
    },
  },
  selectedSquare: {
    width: 156,
    height: 156,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '10px 10px',
    [theme.breakpoints.down('xs')]: {
      width: 90,
      height: 90,
    },
    [theme.breakpoints.between('sm', 'md')]: {
      width: 156,
      height: 156,
    },
    [theme.breakpoints.up('lg')]: {
      width: 175,
      height: 175,
    },
  },
  imageContainer: {
    height: 70,
    maxWidth: '100%',
    textAlign: 'center',
  },
  image: {
    height: 64,
    maxWidth: 'inherit',
  },
  title: {
    display: 'flex',
    fontFamily: 'Asap',
    alignItems: 'flex-end',
    justifyContent: 'center',
    fontSize: '14px',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  squareText: {
    fontWeight: 700,
  },
});
export const SimpleSelectableRectangleNoIconStyle = theme => ({
  square: {
    width: 150,
    height: 80,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '10px 10px',
  },
  selectedSquare: {
    width: 150,
    height: 80,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '10px 10px',
  },
  title: {
    display: 'flex',
    fontWeight: 700,
    alignItems: 'flex-end',
    justifyContent: 'center',
    fontSize: '14px',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
  },
  squareText: {
    fontWeight: 700,
  },
});

export const TalentHomePageStyle = theme => ({
  tabs: {
    '&.Mui-selected': {
      fontWeight: 'bold',
    },
    fontSize: '0.78rem',
  },
  allTabsContainer: {
    height: '50px',
  },
  boxContainer: {
    height: '100%',
  },
  nullPages: {
    height: 'calc(100% - 50px)',
  },
});


export const CertificationSpecialtyStyle = theme => ({
  selectableIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 350,
    // mobile styles
    [theme.breakpoints.down('xs')]: {
      width: 350,
      maxWidth: '350px',
    },
  },
});
export const SpecialityCollegeComponentStyle = theme => ({});
const headerButtonStyle = {
  width: '186px',
  height: '40px',
  borderRadius: '5px',
  position: 'absolute',
  border: 'unset',
  fontFamily: 'Asap',
  fontSize: '12px',
  fontWeight: 'bold',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: 'normal',
  letterSpacing: 'normal',
  textAlign: 'center',
  right: '0',
  top: '5%',
  boxShadow: 'none',
};

const textOverflowProtection = {
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  wordBreak: 'break-all',
  hyphens: 'auto',
};

export const SimpleSelectableRectangleStyle = theme => ({
  square: {
    width: 140,
    height: 100,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '& p': {
      height: 30,
      lineHeight: '30px',
    },
  },
  selectedSquare: {
    width: 140,
    height: 100,
    cursor: 'pointer',
    position: 'relative',
    '&:before': {
      content: '\'\'',
      width: 20,
      height: 20,
      background: theme.palette.primary.main,
      backgroundImage: `url(${icCheck})`,
      backgroundSize: 'contain',
      top: -5,
      left: -5,
      borderRadius: '50%',
      position: 'absolute',
    },
    '& p': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      height: 30,
      lineHeight: '30px',
    },
  },
  imageContainer: {
    textAlign: 'center',
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    maxWidth: 140,
    maxHeight: 60,
    marginTop: 4,
  },
});
export const AnimalTypeStyle = theme => ({});
export const OnboardingItemComponentStyle = theme => ({
  textField: {
    width: '100%',
    fontSize: '14px',
    backgroundColor: '#ffffff',
  },
  input: {
    fontSize: '14px',
    backgroundColor: '#ffffff',
  },
  select: {
    width: '100%',
    fontSize: '14px',
    backgroundColor: '#ffffff',
  },
  textFieldInput: {
    fontSize: '14px',
    MozAppearance: 'textfield',
  },
});
export const AnimalPatientStyle = theme => ({
  gridContainer: {
    justifyContent: 'center',
    // // mobile styles
    [theme.breakpoints.down('xs')]: {
      width: 288,
      justifyContent: 'flex-start',
    },
    // // tablet styles
    [theme.breakpoints.between('sm', 'sm')]: {
      margin: 0,
      width: 492,
      justifyContent: 'center',
    },
    [theme.breakpoints.between('md', 'md')]: {
      margin: 0,
      width: 686,
      justifyContent: 'center',
    },
    // // desktop styles
    [theme.breakpoints.up('lg')]: {
      margin: 0,
      width: 1329,
      justifyContent: 'center',
    },
  },
});
export const SearchesComponentStyle = theme => ({
  gridContainer: {
    justifyContent: 'center',
    // // mobile styles
    [theme.breakpoints.down('xs')]: {
      width: 288,
      justifyContent: 'center',
    },
    // // tablet styles
    [theme.breakpoints.between('sm', 'sm')]: {
      margin: 0,
      width: 492,
      justifyContent: 'center',
    },
    [theme.breakpoints.between('md', 'md')]: {
      margin: 0,
      width: 686,
      justifyContent: 'center',
    },
    // // desktop styles
    [theme.breakpoints.up('lg')]: {
      margin: 0,
      width: 1329,
      justifyContent: 'center',
    },
  },
});
export const CertificationTypeStyle = theme => ({
  gridContainer: {
    // mobile styles
    [theme.breakpoints.down('xs')]: {
      width: 340,
    },
    // tablet styles
    [theme.breakpoints.between('sm', 'md')]: {
      margin: 0,
      width: 500,
    },
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      margin: 0,
      width: 573,
    },
  },
});
export const SpecialistTypeStyle = theme => ({
  radioContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export const commonResourceStyle = theme => ({
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: '85px',
    position: 'relative',
  },
  paginationButton: {
    ...headerButtonStyle,
    color: '#fff',
    backgroundColor: orange,
    cursor: 'pointer',
    textAlign: 'center',
    right: '40px',
    top: '20px',
  },
  pageNumberSpan: {
    fontSize: '12px',
    width: '32px',
    height: '32px',
    display: 'inlineBlock',
    lineHeight: '32px',
    marginRight: '4px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  pageDotSpan: {
    fontSize: '18px',
    width: '32px',
    height: '40px',
    display: 'inlineBlock',
    lineHeight: '32px',
    marginRight: '4px',
    textAlign: 'center',
  },
  searchableSingleSelect: {
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.87) !important',
      opacity: '100%',
    },
    padding: 5,
  },
  applyFilter: {
    marginLeft: '20px',
    maxHeight: '46px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: '#4bbdad!important',
    },
  },
  searchImage: {
    position: 'absolute',
    right: '0px',
    top: '8px',
    [theme && theme.breakpoints.down('sm')]: {
      width: '97px',
    },
  },
  chipRoot: {
    maxWidth: 118,
    marginLeft: '5px',
    marginBottom: '1px',
    [theme && theme.breakpoints.down('sm')]: {
      maxWidth: 82,
    },
  },
  badgeFilter: {
    color: '#fff',
    marginRight: 5,
    marginTop: 5,
    height: 25,
    fontSize: 12,
    padding: '0px 8px',
  },
  badgeCheckBox: { paddingRight: 0 },
  chipRootLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
  },
  chipContainer: {
    display: 'flex',
    position: 'absolute',
    left: 0,
    borderRadius: '5px',
    overflowX: 'auto',
    overflowY: 'hidden',
    whiteSpace: 'nowrap',
    scrollDirection: 'horizontal',
    WebkitTransition: 'all .5s',
    MozTransition: 'all .5s',
    OTransition: 'all .5s',
    transition: 'all .5s',
    maxWidth: '100%',
  },
  chipContainerOpen: {
    transform: 'translate(0px, 50px)',
    WebkitTransform: 'translate(0px, 50px)',
  },
  filterExpandIcon: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black',
  },
  scrollMenuInner: {
    width: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginLeft: 2,
    },
  },
  inputMarginBottom: {
    marginBottom: 50,
    // transition: 'margin .01s',
  },
  inputMarginNone: {
    // transition: 'margin .01s',
  },
  practiceSelectEndAdornment: {
    position: 'absolute',
    right: '50px',
  },
  popper: {
    backgroundColor: 'white',
    zIndex: 1300,
    maxHeight: 'calc(50vh)',
    overflow: 'auto',
    boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    maxWidth: '400px',
    [theme && theme.breakpoints.down('sm')]: {
      maxWidth: '300px',
    },
  },
  selectedItem: {
    outline: '4px solid orange',
  },
  selectableMenuItem: {
    paddingRight: '40px',
  },
  selectedText: {
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuListText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
  greenContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
    background: 'linear-gradient(to right, #4bbdad 0%, #8cd0e1 100%), #ebebeb',
    backgroundSize: '100% 10rem',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
  },
  resourceImage: {
    position: 'absolute',
    zIndex: '0',
    right: '266px',
    top: '4px',
    [theme && theme.breakpoints.down('sm')]: {
      right: '10px',
      width: '100px',
    },
  },
  resourceHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '94%',
    alignItems: 'left',
    justifyContent: 'space-between',
    margin: '3% 3% 0 3%',
    position: 'relative',
    [theme && theme.breakpoints.down('sm')]: {
      paddingTop: '40px',
      width: '89%',
    },
  },
  homeResourceHeader: {
    display: 'flex',
    flexDirection: 'column',
    width: '94%',
    alignItems: 'left',
    justifyContent: 'space-between',
    padding: '3% 3% 0',
    backgroundImage: 'linear-gradient(to left, #8cd0e1 15%, #4bbdad)',
    [theme && theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  buttonIcon: {
    verticalAlign: 'sub',
    width: '16px',
    marginRight: '5px',
  },
  jobPostingResourceNameLarge: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '18px',
    },
  },
  jobPositionResourceHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '94%',
    alignItems: 'left',
    justifyContent: 'space-between',
    padding: '3% 4% 5% 4%',
    backgroundImage: 'linear-gradient(to left, #8cd0e1 15%, #4bbdad)',
    [theme && theme.breakpoints.down('sm')]: {
      paddingTop: '50px',
      justifyContent: 'center',
      padding: '5% 3%',
    },
  },
  dashboard: {
    position: 'relative',
    '@media (min-width: 960px)': {
      marginTop: '-30px',
      marginBottom: '-45px',
    },
  },
  prevCircle: {
    borderRadius: '50%',
    width: '45px',
    height: '45px',
    backgroundColor: orange,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    left: '-15px',
    cursor: 'pointer',
    zIndex: 1,
    position: 'absolute',
    [theme && theme.breakpoints.down('sm')]: {
      width: '35px',
      height: '35px',
      top: '50px',
      left: '-10px',
    },
  },
  nextCircle: {
    borderRadius: '50%',
    width: '45px',
    height: '45px',
    backgroundColor: orange,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginLeft: '-20px',
    cursor: 'pointer',
    [theme && theme.breakpoints.down('sm')]: {
      width: '35px',
      height: '35px',
      position: 'absolute',
      bottom: '50px',
      zIndex: '99',
    },
  },
  navIcon: {
    color: '#fff',
    fontSize: '35px',
  },
  dashletContainer: {
    display: 'flex',
    width: '60%',
    overflow: 'hidden',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  dashlet: {
    width: 190,
    height: 120,
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    fontFamily: 'Asap',
    borderRadius: 5,
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    backgroundColor: '#fff',
    transition: '0.5s',
    minWidth: 'calc(100% / 3 - 24px)',
    padding: 5,
    margin: 12,
    '&:hover': {
      transition: '0.5s',
    },
    [theme && theme.breakpoints.down('sm')]: {
      minWidth: 'calc(100% / 3 - 24px)',
    },
  },
  dashletIconValue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    width: '100%',
    position: 'relative',
  },
  dashletValue: {
    color: orange,
    fontSize: 40,
  },
  dashletIcon: {
    color: '#243060',
    marginRight: 5,
    fontSize: 40,
  },
  dashletTitle: {
    fontSize: '15px',
    marginBottom: '5px',
    fontStyle: 'italic',
    textTransform: 'uppercase',
    color: '#243060',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  dashletCaption: {
    height: '12px',
    fontSize: '10px',
    fontStyle: 'italic',
    color: '#4bbdad',
  },
  dashletToolTip: {
    position: 'absolute',
    right: 0,
    top: 0,
    fontSize: '18px',
  },
  dashletToolTipPopper: {
    fontSize: '14px',
    fontStyle: 'italic',
    maxWidth: '300px',
    padding: 0,
  },
  toolTipContent: {
    padding: '5px 12px 12px 12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  toolTipContentCloseIcon: {
    fontSize: '15px',
  },
  resourceName: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#243060',
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    [theme && theme.breakpoints.down('sm')]: {
      paddingTop: '30px',
      width: '94%',
    },
  },
  homeResourceName: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#ffffff',
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    textAlign: 'center',
    display: 'flex',
  },
  headerImageContainer: {
    '@media (min-width: 960px)': {
      marginLeft: 'auto',
    },
    [theme && theme.breakpoints.down('sm')]: {
      position: 'absolute',
      bottom: '0',
      marginLeft: '15px',
    },
  },
  headerDogImage: {
    height: '250px',
    '@media (max-width: 959px)': {
      height: '200px',
      marginBottom: '-37px',
    },
    [theme && theme.breakpoints.down('sm')]: {
      height: '150px',
      marginBottom: '-30px',
    },
  },
  jobPositionResourceName: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: '#ffffff',
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    [theme && theme.breakpoints.down('sm')]: {
      width: '92%',
    },
  },
  resourceNameLarge: {
    fontSize: '30px',
    fontWeight: 'bold',
    color: 'white',
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '18px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  resourceLogo: {
    verticalAlign: 'middle',
    marginRight: '10px',
    [theme && theme.breakpoints.down('sm')]: {
      width: '25px',
    },
  },
  resourceLogoSub: {
    verticalAlign: 'sub',
    marginRight: '10px',
    width: '38px',
  },
  resourceLogoApp: {
    verticalAlign: 'sub',
    marginRight: '10px',
    width: '38px',
    fontSize: '36px',
    [theme && theme.breakpoints.down('sm')]: {
      width: '25px',
    },
  },
  headerButton: {
    ...headerButtonStyle,
    color: '#ffffff',
    backgroundColor: orange,
    right: '0',
    cursor: 'pointer',
  },
  headerButtonCancel: {
    ...headerButtonStyle,
    color: '#ffffff',
    backgroundColor: '#243060',
    right: 200,
    cursor: 'pointer',
  },
  jobPostingHeaderButton: {
    ...headerButtonStyle,
    color: '#ffffff',
    backgroundColor: orange,
    right: '0',
    cursor: 'pointer',
    margin: '0 8px',
  },
  headerSecondButton: {
    ...headerButtonStyle,
    color: '#ffffff',
    backgroundColor: '#243060',
    right: '208px',
    cursor: 'pointer',
  },
  headerDisabledButton: {
    ...headerButtonStyle,
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    right: '0',
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
  headerDisabledSecondButton: {
    ...headerButtonStyle,
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    right: '208px',
    cursor: 'not-allowed',
    pointerEvents: 'none',
  },
  closeButton: {
    position: 'absolute',
    top: 0,
    right: 5,
  },
  resourceSub: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#243060',
    textTransform: 'uppercase',
    fontFamily: 'circe-rounded',
    marginBottom: 10,
  },
  msg: {
    fontSize: '1rem',
    color: 'rgba(0,0,0,0.54)',
    fontFamily: 'circe-rounded',
    marginTop: 30,
  },
  link: {
    fontSize: '1rem',
    color: '#243060',
    padding: '0px 4px',
    paddingBottom: 4,
    textDecoration: 'underline',
  },
  multiSelectContainer: {
    marginTop: 20,
  },
  breadcrumbContainer: {
    width: '25%',
    minWidth: '175px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  breadcrumb: {
    marginLeft: 0,
    marginRight: 0,
  },
  breadcrumbItem: {
    textDecoration: 'underline',
    fontSize: 14,
    fontFamily: 'Asap',
    color: 'black',
  },
  breadcrumbItemActive: {
    fontSize: 14,
    fontFamily: 'Asap',
    color: 'black',
  },
  fabButton: {
    position: 'fixed',
    width: 75,
    height: 75,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    fontFamily: 'Asap',
    fontSize: '11px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    cursor: 'pointer',
    justifyContent: 'center',
    alignItems: 'center',
    right: 20,
    bottom: 20,
    backgroundColor: orange,
    color: '#fff',
    borderRadius: '50%',
  },
  addNewPositionButton: {
    backgroundColor: orange,
    color: '#fff',
    width: '100%',
    padding: '8px 8px',
  },
  addNewPositionIcon: {
    marginRight: 10,
  },
  paperContainer: {
    padding: 10,
  },
  dialog: {
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
  },
  userDialog: {
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    alignItems: 'center',
    padding: '20px 30px 20px 30px',
  },
  dialogClose: {
    width: '100%',
    textAlign: 'right',
  },
  dialogTitle: {
    fontSize: 20,
    textAlign: 'center',
    color: '#4bbdad',
    marginBottom: 40,
  },
  userDialogTitle: {
    fontSize: 20,
    textAlign: 'center',
    color: '#4bbdad',
    marginBottom: 40,
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
  },
  boldWidth: {
    fontWeight: 'bold',
    width: '300px',
    overflow: 'hidden',
  },
  innerPaper: {
    padding: 10,
    position: 'relative',
    border: '1px solid #243060',
  },
  innerPaper2: {
    padding: 10,
    backgroundColor: '#f1f1f1',
    position: 'relative',
  },
  paper: {
    margin: '1% 2% 1% 2%',
    width: '94%',
    height: '95%',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0',
      backgroundColor: '#ebebeb',
    },
  },
  jobPositionPaperNoEdit: {
    margin: '1% 2% 1% 2%',
    width: '94%',
    height: '95%',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
  },
  jobPositionPaper: {
    margin: '-3% 2% 1% 2%',
    width: '94%',
    height: '95%',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
    [theme && theme.breakpoints.down('sm')]: {
      width: '88%',
    },
  },
  addMoreButtonContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme && theme.breakpoints.down('sm')]: {
      marginTop: '20px !important',
    },
  },
  addMoreButton: {
    color: '#ffffff',
    backgroundColor: '#243060',
    padding: '12px 15px',
    fontSize: '12px',
    borderRadius: '5px',
  },
  addMoreButtonIcon: {
    marginRight: '5px',
    fontSize: '18px',
  },
  paperConnections: {
    margin: '1% 2% 100px 2%',
    width: '94%',
    height: '95%',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
  },
  paperApplications: {
    margin: '1% 2% 24px 2%',
    width: '94%',
    height: '95%',
    overflowX: 'hidden',
    overflowY: 'auto',
    position: 'relative',
  },
  connectionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    marginTop: '50px',
  },
  altConnectionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: '50px',
  },
  recentAppsContainer: {
    padding: '0 0 0 2%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  altAppsContainer: {
    padding: '3%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  recentPostingsContainer: {
    padding: '0 2% 0 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  connectionsTitle: {
    fontSize: '17px',
    fontWeight: 'bold',
    alignSelf: 'flex-start',
    marginLeft: '3%',
    color: '#243060',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chevronsIndicator: {
    color: 'orange',
    margin: '0 0 3px 5px',
  },
  altConnectionsTitle: {
    fontSize: '17px',
    fontWeight: 'bold',
    alignSelf: 'flex-start',
    color: '#243060',
    marginBottom: '10px',
  },
  text: {
    fontSize: '14px',
  },
  right: {
    textAlign: 'right',
  },
  deleteIcon: {
    fontSize: '18px',
    color: '#4bbdad',
    border: '3px solid #4bbdad',
    borderRadius: '50%',
    display: 'flex',
    width: 50,
    height: 50,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 10,
  },
  paperJobPostings: {
    margin: '1% 2% 3% 2%',
    width: '95% !important',
    display: 'flex',
    flexWrap: 'wrap',
    overflowY: 'auto',
    overflowX: 'hidden',
    [theme && theme.breakpoints.down('sm')]: {
      width: '93% !important',
    },
  },
  filterContainer: {
    margin: '30px 2% 0 2%',
    width: '94%',
  },
  filterFrontContainer: {
    margin: '30px 2% 0 2%',
    width: '94%',
    zIndex: '1',
    [theme && theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginBottom: '30px',
      width: '88%',
    },
  },
  searchButton: {
    width: '300px !important',
    height: '45px',
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
    margin: '20px 20px 20px 20px',
    borderRadius: '5px',
    backgroundColor: '#243060',
    '&:hover': {
      backgroundColor: '#4bbdad!important',
    },
    [theme && theme.breakpoints.down('sm')]: {
      margin: '10px 0 40px 0',
      width: '100% !important',
    },
  },
  clearFilterText: {
    color: '#243060',
    textDecoration: 'underline',
    float: 'right',
    fontStyle: 'italic',
    textTransform: 'lowercase',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'unset',
    },
    [theme && theme.breakpoints.down('sm')]: {
      marginTop: '-40px',
    },
  },
  paperFilters: {
    display: 'flex',
    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '0 10px',
    },
  },
  filter: {
    margin: '20px 0px 20px 20px',
    [theme && theme.breakpoints.down('sm')]: {
      margin: '10px 0',
    },
  },
  filterButton: {
    marginTop: 20,
    marginRight: 20,
    marginBottom: 20,
    minWidth: 120,
    fontSize: '12px',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#4bbdad!important',
    },
  },
  nameSearch: {
    margin: '20px 0px 20px 20px',
    width: '100%',
  },
  loadingContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingText: {
    position: 'absolute',
    margin: 'auto',
    fontFamily: 'Asap',
  },
  editButton: {
    width: 168,
    height: 40,
    fontFamily: 'Asap',
    fontSize: 12,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    borderRadius: 5,
    backgroundColor: orange,
    color: '#ffffff',
  },
  button: {
    border: '2px solid #e0592a',
    color: 'black',
    fontSize: '11px',
    width: '100px',
    minHeight: '35px',
    fontFamily: 'Asap',
    borderRadius: '0px',
    boxShadow: 'none',
    alignSelf: 'center',
    margin: '5px 0px 15px 0px',
    padding: '0',
  },
  formActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 30,
  },
  simpleBtn: {
    fontFamily: 'Asap',
    fontSize: '14px',
    fontWeight: 'bold',
    color: orange,
  },
  saveButton: {
    fontFamily: 'Asap',
    color: 'white',
    fontSize: '11px',
    backgroundColor: orange,
    minWidth: '150px',
    '&:disabled': {
      backgroundColor: '#f0f0f0',
    },
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '40px',
      marginTop: '-80px',
      marginBottom: '50px',
    },
  },
  saveButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  formBtn: {
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: orange,
    color: '#fff',
    minWidth: 200,
    marginLeft: '20px',
    boxShadow: 'none',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '10px 0',
      height: '40px',
    },
  },
  formBtnDisabled: {
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    marginLeft: '20px',
    minWidth: 200,
  },
  formBtnCancel: {
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: '#243060',
    color: '#fff',
    minWidth: 200,
    marginRight: 10,
  },
  formBtn2: {
    fontFamily: 'Asap',
    fontSize: '12px',
    fontWeight: 'bold',
    backgroundColor: '#243060',
    color: '#fff',
    minWidth: 230,
    marginLeft: 10,
    boxShadow: 'none',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 0,
      height: '40px',
    },
  },
  jobPostingActionBtns: {
    [theme && theme.breakpoints.down('sm')]: {
      width: '88%',
      marginTop: '20px',
    },
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  formControlLabel: {
    marginBottom: 5,
    display: 'flex',
    alignItems: 'center',
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  formControlLabelWarnText: {
    marginBottom: 5,
    display: 'flex',
    alignItems: 'center',
    color: 'red',
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
  },
  formControl: {
    marginTop: 20,
  },
  notchedOutline: {
    borderColor: 'black !important',
  },
  filterInput: {
    '&::placeholder': {
      color: 'black !important',
      opacity: '100%',
    },
  },
  searchableInput: {
    '&::placeholder': {
      color: 'black !important',
      opacity: '100%',
    },
    cursor: 'pointer',
  },
  marginTop: {
    marginTop: '10px',
  },
  formControlLabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  talentTypeIconCard: {
    maxWidth: 45,
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  newTalentTypeIconCard: {
    maxWidth: 45,
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginLeft: '10px',
  },
  fullNameColumn: {
    marginLeft: 30,
    float: 'right',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  fullNameColumnConnections: {
    marginLeft: 0,
    display: 'inline-block',
    position: 'relative',
  },
  fullNameColumnImage: {
    height: 40,
    width: 30,
    maxHeight: 35,
    marginRight: 15,
  },
  fullNameColumnImageConnection: {
    height: 40,
    width: 30,
    maxHeight: 35,
  },
  talentTypeIconImage: {
    height: 50,
    maxHeight: 50,
  },
  cardNewItem: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 12,
    bottom: -15,
    left: -8,
    color: orange,
  },
  newItem: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    fontSize: 8,
    bottom: -15,
    left: -8,
    color: orange,
  },
  newItemIcon: {
    fontSize: 16,
  },
  editPracticeContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  editPracticeRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  editPracticeColumn1: {
    position: 'relative',
    width: '50%',
    flexDirection: 'column',
    marginRight: '20px',
  },
  editPracticeColumn2: {
    width: '50%',
    flexDirection: 'column',
  },
  hidden: {
    textIndent: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  subTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
  },
  invalidInput: {
    border: '1px solid red !important',
    borderRadius: '4px',
  },
  addNewSocialMedia: {
    color: '#243060',
    alignSelf: 'flex-end',
  },
  phoneNumberContainer: {
    flexDirection: 'column',
    width: '50%',
    marginRight: '20px',
  },
  faxNumberContainer: {
    flexDirection: 'column',
    width: '50%',
  },
  addressContainer: {
    flexDirection: 'column',
    width: '50%',
    marginRight: '20px',
  },
  cityContainer: {
    flexDirection: 'column',
    width: '100%',
  },
  connectionHeader: {
    display: 'flex',
    alignItems: 'center',
    backgroundImage: 'linear-gradient(to right, #82e0d6, #4bbdad)',
    padding: 20,
    color: '#fff',
    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  connectionsHeaderImageContainer: {
    width: 120,
    height: 120,
    borderRadius: '50%',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme && theme.breakpoints.down('sm')]: {
      marginTop: '40px',
    },
  },
  connectionHeaderText: {
    width: '45%',
    padding: 40,
    ...textOverflowProtection,
    [theme && theme.breakpoints.down('sm')]: {
      width: '80%',
      textAlign: 'center',
    },
  },
  connectionHeaderName: {
    fontSize: 40,
    color: '#fff',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '30px',
    },
  },
  contactInfoContainer: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    [theme && theme.breakpoints.down('sm')]: {
      width: '80%',
      marginBottom: '100px',
    },
  },
  connectionHeaderTalentType: {
    fontSize: 20,
    color: '#fff',
    fontStyle: 'italic',
  },
  connectionHeaderContactItem: {
    fontFamily: 'ASAP',
    color: 'white',
    marginLeft: 10,
  },
  connectionHeaderContact: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    margin: '10px 0',
  },
  connectionMessageButton: {
    backgroundColor: '#ffffff',
    width: '85px',
    height: '85px',
    '@media print': {
      opacity: 0,
    },
    [theme && theme.breakpoints.down('sm')]: {
      width: '65px',
      height: '65px',
      position: 'absolute',
      top: '15px',
      right: '15px',
    },
  },
  msgBtnContentContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  chatIconContainer: {
    height: '35px',
    [theme && theme.breakpoints.down('sm')]: {
      height: '25px',
    },
  },
  unreadMsgCount: {
    fontSize: '9px',
    position: 'absolute',
    bottom: '62px',
    left: '57px',
    zIndex: '5',
    color: '#ffffff',
    [theme && theme.breakpoints.down('sm')]: {
      bottom: '48px',
      left: '42px',
    },
  },
  unreadMsgIcon: {
    color: '#243060',
    fontSize: '13px',
    padding: '0',
    position: 'absolute',
    bottom: '63px',
    left: '53px',
    [theme && theme.breakpoints.down('sm')]: {
      bottom: '48px',
      left: '38px',
    },
  },
  chatIcon: {
    fontSize: '38px',
    color: orange,
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
  },
  newMsgTextContainer: {
    height: '25px',
  },
  newMsgText: {
    fontSize: '10px',
    color: orange,
    height: '8px',
    fontWeight: 'bold',
    fontStyle: 'italic',
    [theme && theme.breakpoints.down('sm')]: {
      fontSize: '8px',
    },
  },
  emailhyperLink: {
    fontFamily: 'ASAP',
    color: '#ffffff',
  },
  connectionDetails: {
    display: 'flex',
    padding: 40,
    fontFamily: 'Asap',
    [theme && theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      margin: '0 20px',
      backgroundColor: '#ffffff',
      marginTop: '-100px',
      padding: '15px',
    },
  },
  connectionDetailsHeader: {
    fontSize: 20,
    textTransform: 'uppercase',
    color: '#243060',
    fontWeight: 'bold',
    overflowWrap: 'break-word',
  },
  connectionTopTxt: {
    textAlign: 'center',
    padding: 30,
    fontFamily: 'Asap',
    color: '#243060',
    fontSize: 24,
    lineHeight: 1.5,
  },
  connectionDetailsFullNameArchived: {
    fontSize: 24,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    color: '#243060',
  },
  connectionDetailsFullName: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
  },
  connectionDetailsText: {
    paddingTop: 20,
    paddingBottom: 40,
    paddingRight: 40,
  },
  connectionDetailsLeft: {
    width: '70%',
  },
  experienceDetails: {
    width: '30%',
    backgroundColor: '#f7f7f7',
    borderRadius: 5,
    padding: 10,
    paddingTop: 50,
  },
  experienceItemTitle: {
    width: '40%',
    textAlign: 'right',
    fontStyle: 'italic',
    color: '#878787',
    paddingRight: 10,
  },
  experienceItemValue: {
    width: '60%',
    textTransform: 'capitalize',
  },
  connectionDetailsActions: {
    borderTop: '1px solid #cecece',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 40,
    paddingRight: 40,
    display: 'flex',
    justifyContent: 'space-between',
    '@media print': {
      opacity: 0,
    },
    [theme && theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: '10px 20px',
      borderTop: 'none',
    },
  },
  connectedOn: {
    fontSize: '14px',
    fontStyle: 'italic',
    color: orange,
    [theme && theme.breakpoints.down('sm')]: {
      marginTop: '80px',
    },
  },
  addNewResButton: {
    backgroundColor: '#e0592a',
    color: '#fff',
    padding: '0',
    fontSize: '11px',
    height: '40px',
    width: '170px',
    fontFamily: 'Asap',
    boxShadow: 'none',
    marginRight: '20px',
    marginLeft: 'auto',
  },
  connectionDetailsActionsItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 350,
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  logo: {
    maxWidth: '400px',
    maxHeight: '150px',
  },
  closeButtonContainer: {
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    zIndex: '100',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeImageButton: {
    backgroundColor: '#4bbdad',
    color: '#fff',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  uploadButton: {
    display: 'flex',
    alignItems: 'center',
  },
  editorCustomStyles: {
    formControl: {
      marginTop: 0,
    },
  },
  resultsContainer: {
    display: 'flex',
    width: '94%',
    marginTop: '1%',
  },
  resultShownText: {
    margin: '0 8px',
  },
  helpOutlineIcon: {
    marginLeft: '2px',
    height: '18px',
  },
  expandMoreIcon: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black',
    marginRight: '14px',
    marginTop: '3px',
  },
  helpPopoverText: {
    whiteSpace: 'pre-line',
  },
  helpPopover: {
    maxWidth: 400,
    padding: 10,
  },
  badgeText: {
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#243060',
    fontFamily: 'circe-rounded',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
  },
  badgeTextContainer: {
    display: 'flex',
  },
  customFormControlStyle: {
    marginTop: 0,
    height: '100%',
  },
  editorGrid: {
    [theme && theme.breakpoints.down('sm')]: {
      marginTop: '30px !important',
    },
  },
  mobileRichTextEditorStyle: {
    height: '500px',
  },
  warningText: {
    color: 'red',
  },
  viewMoreApplicationsButton: {
    fontFamily: 'Asap',
    fontWeight: 'bold',
    color: orange,
  },
  navigateNextIcon: {
    width: '17px',
  },
  hover: {
    height: '77px',
    '&:hover': {
      backgroundColor: '#a6e6dd',
      transition: '0.2s',
    },
  },
  connectionsRowStyle: {
    height: '77px',
    backgroundColor: '#f1fffd',
    '&:hover': {
      backgroundColor: '#a6e6dd',
      transition: '0.2s',
    },
  },
  overflowEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  newConnectionDetailsLeft: {
    width: '60%',
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  newExperienceDetails: {
    width: '40%',
    backgroundColor: '#f7f7f7',
    borderRadius: 5,
    padding: 10,
    paddingTop: 50,
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '0',
      marginTop: '15px',
    },
  },
  newExperienceItemTitle: {
    width: '40%',
    textAlign: 'right',
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: '#878787',
    paddingRight: 10,
    [theme && theme.breakpoints.down('sm')]: {
      border: '5px solid #ffffff',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      fontWeight: 'normal',
      height: '40px',
      padding: '5px',
    },
  },
  newExperienceItemValue: {
    width: '60%',
    textTransform: 'capitalize',
    [theme && theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
      border: '5px solid #ffffff',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'left',
      fontWeight: 'normal',
      height: '40px',
      padding: '5px',
    },
  },
  jobPositionCardContainer: {
    paddingTop: '20px',
    width: '50%',
    '&:hover': {
      cursor: 'pointer',
    },
    [theme && theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  badgesContainerView: {
    paddingBottom: '40px',
    paddingTop: '20px',
  },
  badgesContainer: {
    marginTop: 30,
    paddingTop: '20px',
  },
  badgesNarrowContainer: {
    paddingBottom: '20px',
    paddingTop: '20px',
  },
  printButton: {
    color: '#ffffff',
    backgroundColor: '#243060',
    [theme && theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  printButtonText: {
    fontSize: '12px',
    paddingLeft: '5px',
  },
  experienceItem: {
    display: 'flex',
    marginBottom: 20,
    fontFamily: 'Asap',
    [theme && theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
  '@media print': {
    nonPrint: {
      opacity: 0,
    },
    hidePrint: {
      display: 'none',
    },
    hideBoxShadows: {
      boxShadow: 'none',
    },
    newExperienceDetails: {
      width: '50%',
      fontSize: 12,
      padding: 5,
    },
    newConnectionDetailsLeft: {
      width: '50%',
    },
    newExperienceItemTitle: {
      width: '50%',
    },
    newExperienceItemValue: {
      width: '50%',
    },
    textOverflowProtection: {
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      wordBreak: 'break-all',
      hyphens: 'auto',
    },
  },
  applicationMobileCardsContainer: {
    width: '88%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  practiceUploadLogoText: {
    display: 'flex',
    alignItems: 'center',
  },
  editPracticeLogoBtn: {
    height: '100%',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    borderColor: '#243060',
  },
});

export const OnboardingPageStyle = theme => ({
  title: {
    width: '80%',
    height: '66px',
    fontSize: '20px',
    fontWeight: '700',
    margin: '0 10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
});
export const OnboardingFlowStyle = theme => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: '700',
    fontSize: 20,
    width: '100%',
    // mobile styles
    [theme.breakpoints.down('xs')]: {
      maxWidth: '80%',
      margin: '0 10%',
    },
    // tablet styles
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: '80%',
      margin: '0 10%',
    },
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      maxWidth: '60%',
      margin: '0 20%',
    },
  },
  message: {
    backgroundColor: '#FFFFFF',
    maxWidth: 500,
    width: '80%',
    minWidth: 275,
    fontSize: 20,
    textAlign: 'center',
    padding: 22,
    fontFamily: 'Asap',
    borderRadius: 5,
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
  },
  closeIcon: {
    float: 'right',
    display: 'block',
    marginLeft: 'calc(100% - 50px)',
  },
  messageBody: {
    fontSize: 14,
    margin: '8px 0',
  },
  modalButton: {
    backgroundColor: '#243060',
    width: '80%',
    color: '#FFFFFF',
    marginTop: 8,
  },
});
export const VetTechOrAssistantOnboardingStyle = theme => ({
  onboardingGridContainer: {},
  gridContainer: {
    // mobile styles
    [theme.breakpoints.down('xs')]: {
      width: 302,
    },
    // tablet styles
    [theme.breakpoints.between('sm', 'md')]: {
      margin: 0,
      width: 500,
    },
    // desktop styles
    [theme.breakpoints.up('lg')]: {
      margin: 0,
      width: 573,
    },
  },
  resourceAlert: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#243060',
  },
  title: {
    minHeight: 60,
    minWidth: 300,
    fontFamily: 'Asap',
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#243060',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleNoMarginBottom: {
    minHeight: 60,
    minWidth: 300,
    fontFamily: 'Asap',
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#243060',
    marginTop: 22,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    fontSize: '16px',
    fontStyle: 'italic',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    marginBottom: 12,
  },
});

export const SpecialistVeterianFlowComponentStyle = theme => ({
  title: {
    minHeight: 60,
    minWidth: 300,
    fontFamily: 'Asap',
    fontSize: 20,
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#243060',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
});
export const AnimalTypeSlider = theme => ({
  root: {
    color: theme.palette.primary.main,
    margin: '8px 0px 16px 0px',
  },
  mark: {
    width: 0,
  },
  markLabel: {
    transform: 'translateX(-100%)',
    fontSize: '0.7rem',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  markLabelActive: {
    transform: 'translateX(0%)',
  },
  valueLabel: {
    top: -15,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
});
export const GPVeterinarianFlowComponentStyle = theme => ({
  resourceAlert: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#243060',
  },
  title: {
    minHeight: 60,
    fontSize: '20px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
});
export const CurrentRoleComponentStyle = theme => ({
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '16px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontStyle: 'italic',
    margin: '2% 0',
  },
});
export const GeneralSliderStyle = theme => ({
  root: {
    color: orange,
    height: 8,
  },
  thumb: {
    height: 22,
    width: 22,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus,&:hover': {
      boxShadow: 'inherit',
    },
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
    color: '#bababa',
  },
});
export const VetAssistantFlowComponentStyle = theme => ({
  title: {
    minHeight: 60,
    fontSize: '20px',
    fontWeight: '700',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  description: {
    fontSize: '16px',
    fontStyle: 'italic',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
});
export const ComfortLevelSelectionComponentStyle = theme => ({
  title: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '16px',
    fontWeight: 'bold',
    fontStyle: 'italic',
    margin: '1% 0',
    textAlign: 'center',
  },
  radioGroup: {
    justifyContent: 'space-between',
  },
});
export const StudentComponentStyle = theme => ({});
export const NonClinicalFlowComponentStyle = theme => ({
  title: {
    width: '80%',
    minHeight: 60,
    fontSize: '20px',
    fontWeight: '700',
    margin: '5% 10%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  gridItem: {
    marginBottom: '5%',
  },
});
export const AboutYouComponentStyle = theme => ({
  formControl: {
    width: '100%',
  },
  textField: {
    width: '100%',
    fontSize: '14px',
    backgroundColor: '#ffffff',
  },
  input: {
    fontSize: '14px',
    backgroundColor: '#ffffff',
  },
  textFieldInput: {
    fontSize: '14px',
    MozAppearance: 'textfield',
  },
});
export const ConnectionsComponentStyle = theme => ({
  title: {
    margin: '30px 0',
    fontWeight: 'bold',
  },
  filterContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  filterButton: {
    padding: '1px 12px',
    fontSize: '0.7rem',
  },
});

export const UserProfilePageStyle = theme => ({
  grid: {
    marginBottom: '20px',
  },
  innerGrid: {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    width: '100%',
    padding: '15px',
    marginBottom: '20px',
  },
  container: {
    padding: 0,
  },
  title: {
    margin: '20px 0',
    color: '#979797',
  },
  listItemAvatar: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemText: {
    fontSize: '16px',
  },
});
export const ContactInfoStyle = theme => ({
  resourceAlert: {
    fontWeight: 'bold',
    color: '#243060',
  },
  container: {
    padding: 0,
  },
  title: {
    margin: '20px 0',
    color: '#979797',
  },
  heading: {
    fontSize: 20,
    fontWeight: 700,
  },
  subHeading: {
    fontSize: 16,
    fontWeight: 600,
  },
  searchTitle: {
    fontSize: 16,
    fontWeight: 700,
    maxWidth: '80%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  searchIcon: {
    width: '18px',
    height: '18px',
    marginRight: '5px',
    marginLeft: '-3px',
  },
  label: {
    fontSize: 16,
    fontWeight: 500,
  },
  smallLabel: {
    fontSize: 14,
  },
  grid: {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    width: '100%',
    padding: '15px',
  },
  typography: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  typographyWithHelp: {
    '& svg': {
      fontSize: '1rem',
      marginLeft: 5,
    },
    fontWeight: 'bold',
    fontStyle: 'italic',
    display: 'flex',
    alignItems: 'center',
  },
  helpPopoverText: {
    whiteSpace: 'pre-line',
  },
  helpPopover: {
    maxWidth: 400,
    padding: 10,
  },
  textField: {
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  button: {
    width: '100%',
  },
  warningIcon: {
    width: '18px',
    height: '18px',
  },
  buttonSecondary: {
    '&:hover': {
      backgroundColor: orange,
    },
    backgroundColor: orange,
    width: '100%',
  },
  notificationContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  switchContainer: {
    //paddingTop: '15px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    marginRight: '16px',
  },
  root: {
    width: 32,
    height: 17,
    padding: 0,
    margin: theme.spacing(1),

    '& .MuiSwitch-colorSecondary.Mui-disabled + .MuiSwitch-track': {
      backgroundColor: "#f7f7f7",
      backgroundImage: 'unset',
      border: `1px solid ${theme.palette.grey[400]}`,
      opacity: 0.5,
    }
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,

      '& + $track': {
        //backgroundImage: 'linear-gradient(to bottom, #4bbdad, #d0ffff 98%)',
        backgroundColor: tiffany,
        opacity: 1,
        height: 16,
        border: 'none',
      },

    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 14,
    height: 14,
  },
  track: {
    borderRadius: 16 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: '#f0f0f0',
    height: 16,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: { left: 'unset!important' },
  //disabled: {  backgroundColor: 'yellow' },
  focusVisible: {},
  expandMoreIcon: {
    fontSize: '20px',
    fontWeight: 'bold',
    color: 'black',
    marginRight: '14px',
    marginTop: '3px',
  },
  icon: {
    color: '#9e9e9e',
    margin: '3px 3px 3px 0px',
  },
  item: {
    minHeight: 36,
    alignItems: 'center',
    display: 'flex',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  row: {
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  smsVerifyText: {
    fontSize: '12px',
    color: 'red',
    paddingLeft: '8px',
  },
});
export const AboutYouPageStyle = theme => ({
  tooltip: {
    fontSize: '1rem',
  },
  container: {
    padding: 0,
  },
  title: {
    margin: '20px 0',
    color: '#979797',
  },
  grid: {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    width: '100%',
    padding: '15px',
  },
  typography: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  textField: {
    width: '100%',
    fontSize: '14px',
    backgroundColor: '#ffffff',
  },
  input: {
    fontSize: '14px',
    backgroundColor: '#ffffff',
  },
  textFieldInput: {
    fontSize: '14px',
    MozAppearance: 'textfield',
  },
  redButton: {
    backgroundColor: orange,
    color: '#fff',
    '&:hover': {
      backgroundColor: orange,
      color: '#fff',
    },
  },
});

export const ConfirmEmailStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  title: {
    fontStyle: 'italic',
    fontSize: '11px',
    color: orange,
    fontWeight: 'bold',
  },
  body: {
    fontSize: '11px',
    color: orange,
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  link: {
    fontSize: '11px',
    color: orange,
  },
});

export const SettingsStyle = theme => ({
  title: {
    margin: '30px 0',
    fontWeight: 'bold',
  },
  grid: {
    marginTop: 20,
  },
  card: {
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '20px',
  },
  cardContent: {
    padding: ' 25px 25px 15px 25px',
  },
  itemTitle: {
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  root: {
    width: 42,
    height: 25,
    padding: 0,
    marginLeft: 'auto',
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: '#D3D3D3',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  icon: {
    marginRight: '10px',
  },
  textField: {
    width: '100%',
    margin: '10px 0',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  textFieldInput: {
    fontSize: '14px',
    MozAppearance: 'textfield',
  },
  button: {
    marginTop: '20px',
    backgroundColor: '#4bbdad',
    color: '#ffffff',
  },
  cancel: {
    cursor: 'pointer',
    width: '20px',
    height: '20px',
    color: '#D3D3D3',
  },
  whiskerBotGrid: {
    marginTop: '50px',
  },
});

export const loadingStyle = theme => ({
  loadingContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingText: {
    position: 'relative',
    right: '65px',
    fontFamily: 'Asap',
  },
});

export const ConfirmMobileStyles = theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  title: {
    fontStyle: 'italic',
    fontSize: '11px',
    color: orange,
    fontWeight: 'bold',
  },
  body: {
    fontSize: '11px',
    color: orange,
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  link: {
    fontSize: '11px',
    color: orange,
  },
});

export const NotificationFrequencySelectorStyles = theme => ({
  notificationFrequencyContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  sublabel: {
    fontSize: '0.75rem',
  },
});

export const forgotPasswordDialogs = theme => ({
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: 1,
    top: 1,
    color: 'grey',
  },
  icon: {
    height: '50px',
    width: '50px',
    textAlign: 'center',
    margin: '20px 0',
    border: 'solid 3px #4bbdad',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  textFieldVerificationCode: {
    margin: '15px 0',
    width: '47px',
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  textField: {
    width: '100%',
  },
  dialogActions: {
    justifyContent: 'center',
    padding: '10px 10%',
    margin: 0,
  },
  button: {
    backgroundColor: '#243060',
    color: 'white',
    width: '100%',
    padding: '14px',
    '&:hover': {
      backgroundColor: '#1c264c',
    },
    '&:disabled': {
      backgroundColor: '#f0f0f0',
    },
    margin: 0,
  },
});

export const ExperienceDetailsPageStyle = theme => ({
  container: {
    padding: 0,
  },
  buttonSecondary: {
    '&:hover': {
      backgroundColor: orange,
    },
    backgroundColor: orange,
    width: '100%',
    marginTop: 20,
  },
  newExperienceDetails: {
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.3)',
    width: '100%',
    padding: '20px',
    marginTop: '-8px',
  },
  experienceItem: {
    display: 'flex',
    width: '100%',
  },
  newExperienceItemTitle: {
    width: '40%',
    textAlign: 'right',
    fontStyle: 'italic',
    color: '#878787',
    paddingRight: 10,
  },
  newExperienceItemValue: {
    width: '60%',
    textTransform: 'capitalize',
  },
  title: {
    margin: '20px 0',
    color: '#979797',
  },
});

