import { Typography, withStyles, Link } from '@material-ui/core';
import React, { Component } from 'react';
import { ConfirmMobileStyles } from '../../css/style';

class ConfirmMobile extends Component {
  handleClick = () => {
    const { actions, user } = this.props;
    actions.sendVerifyAccountSms(user, 'users', 'verifyMobile');
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <Typography className={classes.title}>
          CONFIRM YOUR MOBILE
        </Typography>
        <Typography className={classes.body}>
          Please check your mobile for an activation code.
        </Typography>
        <div className={classes.linkContainer}>
          <Typography className={classes.body}>
            {'Didn\'t receive the activation code?'}
            &nbsp;
          </Typography>
          <Link href="#" className={classes.link} underline="always" onClick={this.handleClick}>
            Send again.
          </Link>
        </div>
      </div>
    );
  }
}

export default withStyles(ConfirmMobileStyles)(ConfirmMobile);
