import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import types from '../actions/ActionTypes';
import { loadingStyle } from '../css/style';

const styles = loadingStyle();

class OauthResult extends Component {
  componentDidMount() {
    const {
      actions, match, location, history,
    } = this.props;
    const { search } = location;
    const { params } = match;
    const { oauthAction, oauthProvider } = params;
    if (oauthAction === 'login') {
      actions.oauthLogin(search, oauthProvider)
        .then((e) => {
          if (!e.error) {
            history.push('/');
          } else {
            history.push('/login');
          }
        });
    }
    if (oauthAction === 'signup') {
      actions.oauthRegister(search, oauthProvider)
        .then((e) => {
          if (e.type === types.REGISTER_USER_SUCCESS) {
            history.push('/userinfo');
          } else {
            history.push('/signup');
          }
        });
    }
  }

  render = () => (
    <div style={styles.loadingContainer}>
      <CircularProgress size={80} />
      <Typography style={styles.loadingText}>Loading</Typography>
    </div>
  );
}

export default withRouter(OauthResult);
