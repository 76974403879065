import React, { Component, Fragment } from 'react';
import {
  Typography,
  Modal,
  Fade,
  Button,
  Backdrop,
  TextField,
  Paper,
  Grid,
  IconButton,
} from '@material-ui/core/';
import { Close } from '@material-ui/icons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core';

import { navy, orange, iconGray, bgGray, tiffany, lightGray, green } from '../../css/style';

export const renderPhoneVerificationModal = (props) => {
  const {
    classes, resources, openPhoneVerification, onClose, onOk, handleStateChange,
  } = props;
  const { talentpiis } = resources;
  const phoneNumber = talentpiis && talentpiis.length && talentpiis[length - 1] ? talentpiis[length - 1].mobile : '';

  return (
    <Modal
      open={openPhoneVerification}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClose={onClose}
      className={classes.modal}
      closeAfterTransition
    >
      <Fade in={openPhoneVerification}>
      <div className={classes.message}>
          <Typography variant='h6' color='primary' gutterBottom style={{ fontWeight: 700 }}>
            Please check your mobile for an activation code.
          </Typography>
          <Typography gutterBottom variant='h6'>
            {phoneNumber}
          </Typography>
          <TextField
            variant='outlined'
            className={classes.textField}
            onChange={handleStateChange}
            inputProps={{ className: classes.textFieldInput }}
            InputProps={{ type: 'number' }}
          />
          <Button className={classes.modalButton} onClick={onOk}>
            OK
          </Button>
        </div>
      </Fade>
    </Modal>
  )
};

const styles = {
  connectModal: {
    fontWeight: '700',
    fontSize: 20,
    width: '100%',
    top: 'calc(50% - 115px)',
    left: 'calc(50% - 175px)',
  },
  modalHeaderIcon: {
    fontSize: '20px',
    color: `${green}`,
    marginRight: '4px',
  },
  modalHeader: {
    fontSize: '22px',
    fontWeight: 700,
    color: `${green}`,
  },
  closeButton: {
    padding: '0px',
    color: '#C4CBD4',
  }
};

export const renderConnectModal = (props) => {
  const { openConfirmationModal, currentItem, onClose, onOk } = props;

  return currentItem && (
    <Modal
      style={styles.connectModal}
      open={openConfirmationModal}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openConfirmationModal}>
        <Paper 
          //className={classes.paper}
          style={{ maxWidth: 350, padding: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={10}  style={{ display: 'flex', alignItems: 'center'}}>
              <CheckCircleIcon style={styles.modalHeaderIcon} />
              <Typography
                style={styles.modalHeader}
              >
                CONNECT NOW!
              </Typography>
            </Grid>
            <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end'}}>
              <IconButton
                aria-label="close"
                style={styles.closeButton}
                onClick={onClose}
              >
                <Close style={{ fontSize: '24px' }}/>
              </IconButton>
            </Grid>
            <Grid item xs={12}>
            <Typography>
              We will forward your contact information to
              {' '}
              <b>{currentItem?.practice?.name}</b>
              {' '}
              and put the connection info in an email for you for future reference.
            </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end'}}>
              <Button
                key="confirmDialog_Cancel"
                variant="outlined"
                onClick={onClose}
                style={{ marginRight: '8px', textTransform: 'capitalize'}}
              >
                Cancel
              </Button>

              <Button
                key="confirmDialog_Ok"
                variant="contained"
                color="secondary"
                onClick={onOk}
                style={{ textTransform: 'capitalize'}}
              >
                Confirm Connection
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  );
};
