import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
  Dialog,
  Paper,
  Typography,
  IconButton,
  Divider,
  Grid,
  Snackbar,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { navy, bgGray, lightGray } from '../../css/style';
import { DialogStyles } from '../../css/dialogStyle';
import SavedSearchForm from './SavedSearchForm';
import { IMG_STORAGE_LINK } from '../constants/Constants';
import { updateColorsUsed, findFirstNewColor } from '../../utils/Functions';
import { COLORS } from '../../config/enums';

class SavedSearchDialog extends Component {
  constructor() {
    super();
    this.state = {
      nextColor: null,
      colorsUsed: [],
      openSnackbar: false,
    };
  }

  componentDidMount() {
    const { resources, user, savedSearch } = this.props;
    const { savedsearchs } = resources || {};
    const colorsUsedArray = new Array(COLORS.length).fill(false);
    savedsearchs && savedsearchs.map((search) => {
      colorsUsedArray[COLORS.indexOf(search.color)] = true;
    });
    this.setState({
      colorsUsed: colorsUsedArray,
      nextColor: findFirstNewColor(colorsUsedArray)
    });
  }

  renderDialogHeader = () => {
    const { classes, savedSearch, handleClose } = this.props;

    return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '14px 20px'}} >
        <div style={{ display: 'flex', height: '24px', alignItems: 'center' }}>
          <Typography color="secondary" className={classes.title}>
            {`${savedSearch?.title || 'Whisker Search'}`}
          </Typography>
          <img src={`${IMG_STORAGE_LINK}wb_search.png`} className={classes.whiskerIcon} />
        </div>
        <IconButton
          aria-label="close"
          className={classes.searchFormCloseButton}
          onClick={() => handleClose()}
        >
          <Close />
        </IconButton>
      </div>
      <Divider />
    </>
  )};

  renderFormHeader = () => {
    const { classes, handleClose, savedSearch } = this.props;

    return (
      <Grid item
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '40px !important',
          width: '100%',
          justifyContent: 'flex-start',
          padding: '20px',
          backgroundColor: '#E1E5EA',
          borderBottom: `1px solid ${lightGray}`,
        }}
      >
        <IconButton
          aria-label="close"
          className={classes.searchFormCloseButton}
          onClick={() => handleClose()}
        >
          <ChevronLeftIcon style={{ fontSize: '28px', color: navy}} />
        </IconButton>
        <Typography
          color="secondary"
          style={{
            fontSize: 20,
            fontWeight: 700,
            paddingLeft: '10px',
          }}
        >{savedSearch ? 'Edit Whisker Search' : 'Whisker Search'}</Typography>
        <img src={`${IMG_STORAGE_LINK}wb_search.png`} className={classes.whiskerIcon} />
      </Grid>
  )};

  renderForm = () => {
    const {
      classes,
      handleClose,
      user,
      actions,
      resources,
      openPhoneVerification,
      savedSearch,
      enableDelete,
      form,
      isMobile,
      isExternshipUser,
    } = this.props;
    return (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
        {form ? this.renderFormHeader() : this.renderDialogHeader()}
        <div style={form && {height: '100vh' }}>
          <SavedSearchForm
            user={user}
            actions={actions}
            resources={resources}
            saveButtonText="save"
            savedSearch={savedSearch}
            openPhoneVerification={openPhoneVerification}
            handleClose={(isSaved) => {
              this.setState({ openSnackbar: isSaved });
              handleClose(isSaved);
            }}
            enableDelete={enableDelete}
            colorIndex={this.state.nextColor}
            updateColor={(color) => {
              const { colorsUsed, nextColor} = updateColorsUsed(color, this.state.colorsUsed);
              this.setState({ colorsUsed, nextColor});
            }}
            form={form}
            isMobile={isMobile}
            isExternshipUser={isExternshipUser}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      handleClose,
      open,
      form,
      savedSearch,
    } = this.props;

    return form ?
      (
        <Paper
          maxWidth="md"
          style = {{
            padding: 0,
            minWidth: '360px',
            zIndex: 100
          }}
        >
          {this.renderForm()}
        </Paper>
      ) : (
        <>
          <Dialog
            maxWidth="md"
            PaperProps={{
              style: {
                padding: 0,
                minWidth: '360px',
              },
            }}
            open={open}
            onClose={() => handleClose()}
          >
            {this.renderForm()}
          </Dialog>
          {!savedSearch && window.location.pathname !=='/matches' &&  (
          <Snackbar
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            open={Boolean(this.state.openSnackbar)}
            onClose={() => this.setState({ openSnackbar: false })}
            autoHideDuration={4000}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">To see this new search, return to the Home page.</span>}
          />
        )}
        </>
      );
  }
}

export default withStyles(DialogStyles)(withRouter(SavedSearchDialog));
