import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Cancel, Lock, Notifications } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import React, { Component } from 'react';
import { SettingsStyle } from '../../css/style';
import WhiskerBot from '../subcomponents/WhiskerBot';
import { withRouter } from 'react-router';

const cards = [
  {
    id: 1,
    icon: Lock,
    title: 'Change Password',
    switch: false,
    key1: 'password1',
    key2: 'password2',
    placeholder1: 'Type new password here',
    placeholder2: 'Re-type new password here',
  },
];

class SettingsComponent extends Component {
  constructor(props) {
    super(props);
    const { user } = props;
    this.state = {
      checked: true,
      password1: '',
      password2: '',
      email: user.email || '',
      mobile: user.talentPII && user.talentPII.mobile || '',
      hiddenContent: true,
    };
  }

  componentDidMount = async () => {
    const {
      actions,
      match,
      history,
    } = this.props;
    const { verificationUuid } = match.params;

    if (verificationUuid) {
      await actions.verifyUser(['users', 'verify', verificationUuid]).then((verifiedRecoveredUser) => {
        if (verifiedRecoveredUser) {
          if (verificationUuid.startsWith('12427')) {
            history.push('/settings');
            this.setState({ hiddenContent: false });
          } else if(verifiedRecoveredUser.isStudent){
            history.push('/externhome');
          } else {
            history.push('/');
          }
        }
      });
    } else {
      this.setState({ hiddenContent: false });
    }
  }

  handleChange = (event, key) => {
    this.setState({
      [key]: key === 'checked' ? event.target.checked : event.target.value,
    });
  };

  getType = (key) => {
    if (key.includes('password')) {
      return 'password';
    } else if (key === 'mobile') {
      return 'number';
    }
    return 'text';
  };

  handleClickSave = (key) => {
    const {
      actions,
      user,
      history,
    } = this.props;
    const { password1 } = this.state;

    if (key === 'password1') {
      actions.updateResource('user', user.token, { password: password1 },
        'users', user.id).then(() => history.push('/'));
      this.setState({
        password1: '',
        password2: '',
      });
    }
  };

  disabled = (id) => {
    const {
      password1,
      password2,
    } = this.state;

    if (id === 1) {
      if (isEmpty(password1) || isEmpty(password2) || password1 !== password2) {
        return true;
      }
      return false;
    }
  };

  render() {
    const { classes } = this.props;
    const { hiddenContent } = this.state;
    if (hiddenContent) {
      return null;
    }
    return (
      <Container>
        <Typography variant="h5" color="secondary" className={classes.title}>SETTINGS</Typography>
        <Grid container spacing={2} className={classes.grid}>
          {
            cards.map((item) => {
              const ItemIcon = item.icon;
              return (
                <Grid key={item.id} item xs={12} md={6} lg={6} container justifyContent="flex-start">
                  <Card className={classes.card}>
                    <CardContent className={classes.cardContent}>
                      <div className={classes.row}>
                        <ItemIcon className={classes.icon} />
                        <Typography color="secondary" className={classes.itemTitle}>
                          {item.title}
                        </Typography>
                      </div>
                      <TextField
                        name={item.title}
                        value={this.state[item.key1]}
                        placeholder={item.placeholder1}
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        type={item.key1.includes('password') ? 'password' : 'text'}
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                          endAdornment: this.state[item.key1]
                            ? <Cancel className={classes.cancel} onClick={() => this.setState({ [item.key1]: '' })} />
                            : null,
                        }}
                        onChange={e => this.handleChange(e, item.key1)}
                      />
                      <TextField
                        name={item.title}
                        value={this.state[item.key2]}
                        placeholder={item.placeholder2}
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        type={this.getType(item.key2)}
                        InputProps={{
                          classes: {
                            input: classes.textFieldInput,
                          },
                          endAdornment: this.state[item.key2]
                            ? <Cancel className={classes.cancel} onClick={() => this.setState({ [item.key2]: '' })} />
                            : null,
                        }}
                        onChange={e => this.handleChange(e, item.key2)}
                      />
                      <Button
                        fullWidth
                        className={classes.button}
                        disabled={this.disabled(item.id)}
                        onClick={() => this.handleClickSave(item.key1)}
                      >SAVE CHANGES</Button>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })
          }
          <Grid item xs={12} className={classes.whiskerBotGrid}>
            <WhiskerBot long />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(SettingsStyle)(withRouter(SettingsComponent));
