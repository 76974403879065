import { Paper, Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import { orange } from '../../../css/style';

const styles = {
  messageBox: {
    maxWidth: '80%',
    fontFamily: 'Lato',
    borderRadius: '15px',
    minHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #D9D9D9',
    boxShadow: 'none',
  },
  creatorName: {
    fontSize: '12px',
    color: '#4bbdad',
    fontWeight: 'bold',
  },
  messageContent: {
    fontSize: '16px',
    padding: '10px 20px 10px 20px',
  },
  messageSentTime: {
    padding: '15px 15px 1px 0',
    color: '#000000',
    fontSize: '16px',
    fontWeight: 600,
  },
  messageTime: {
    fontFamily: 'Asap',
    fontSize: 13,
    fontWeight: 400,
    color: '#404A59'
  }
};

const oldPingedSign = '$$pinged$$';
const pingedSign = '$$has pinged$$';
const leftSign = '$$has left the chat.$$';

const MessageBubble = (props) => {
  const {
    content,
    type,
    date,
    creatorName,
    senderName,
  } = props;
  const isLoggedInUser = type === 'sender';
  let info;
  let actionTextSign = null;
  if (content) {
    if (content.includes(pingedSign)) {
      actionTextSign = pingedSign;
      info = 'Messages prior to the ping invitation are private and not visible to the pinged party.';
    } else if (content.includes(oldPingedSign)) {
      actionTextSign = oldPingedSign;
    } else if (content.includes(leftSign)) {
      actionTextSign = leftSign;
    }
  }

  const actionText = actionTextSign ? actionTextSign.replace(/\$/g, '') : '';
  const contentArr = actionTextSign ? content.split(actionTextSign) : [];

  return (
    (content && actionTextSign && content.includes(actionTextSign))
      ? (
        <div
          style={{
            minHeight: '50px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            paddingLeft: '20px',
          }}
        >
          <Typography style={{
            padding: '5px 15px 5px 0px',
            fontFamily: 'Asap',
            fontSize: '16px',
            color: orange,
            position: 'relative',
          }}
          >
            { content.includes(leftSign)
              ? (
              <CancelScheduleSendIcon
                style={{
                  color: orange,
                  position: 'absolute',
                  top: '5px',
                }}
              />
              )
              : (
                <NotificationsActiveIcon
                  style={{
                    color: orange,
                    position: 'absolute',
                    top: '5px',
                  }}
                />
              )}
              {contentArr.length
                && <span style={{ paddingLeft: '30px', fontWeight: 'bold' }}>{contentArr[0]}</span>
              }
              <span style={{ fontWeight: 'bold' }}>
                {actionText}
              </span>
              {contentArr.length > 1
                && (
                <span style={{ fontWeight: 'bold' }}>
                  {contentArr[1]}
                </span>
                )
              }
          </Typography>
          {info && (
            <Typography style={{
              padding: '5px 15px 5px 15px',
              color: '#243060',
              fontSize: '12px',
            }}
            >
              {info}
            </Typography>
          )}

          <Typography style={styles.messageSentTime}>
            {moment(date).calendar()}
          </Typography>
        </div>
      )
      : (
      <div
        style={{
          minHeight: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: isLoggedInUser ? 'flex-end' : 'flex-start',
          paddingLeft: !isLoggedInUser && '20px',
          paddingRight: isLoggedInUser && '20px',
        }}
      >
        <Typography style={styles.messageSentTime}>
          <strong>{!isLoggedInUser && `${senderName}`}</strong>
          {' '}
          <span style={styles.messageTime}>{moment(date).calendar()}</span>
        </Typography>
        <Paper
          style={{
            ...styles.messageBox,
            backgroundColor: isLoggedInUser ? '#243060' : '#ffffff',
            color: isLoggedInUser ? '#ffffff' : '#000000',
          }}
        >
          <Typography style={styles.messageContent}>{content}</Typography>
        </Paper>
      </div>
      )
  );
};

export default withRouter(MessageBubble);
