import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = theme => ({
  close: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
});

class MessageSnackbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.openMessageSnackbar,
      errorMsg: props.errorMsg,
      successMsg: props.successMsg,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const isOpen = nextProps.openMessageSnackbar;
    const isMsgDiff = prevState.errorMsg !== nextProps.errorMsg || prevState.successMsg !== nextProps.successMsg;
    if (isOpen || isMsgDiff) {
      return { open: nextProps.openMessageSnackbar };
    } else {
      return { open: false };
    }
  }

  handleClose = (event, reason) => {
    const { actions } = this.props;
    this.setState({ open: false });
    actions.clearMessage();
  };

  render() {
    const { classes, errorMsg, successMsg } = this.props;
    const buttons = [];
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={this.state.open}
        //autoHideDuration={4000}
        onClose={this.handleClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={<span id="message-id">{errorMsg || successMsg}</span>}
        action={buttons}
      />
    );
  }
}

MessageSnackbar.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  openMessageSnackbar: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string,
  successMsg: PropTypes.string,
};

MessageSnackbar.defaultProps = {
  errorMsg: '',
  successMsg: '',
};

export default withStyles(styles)(MessageSnackbar);
