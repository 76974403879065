import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { SpecialistVeterianFlowComponentStyle } from '../../css/style';
import AnimalTypeComponent from './itemTypes/AnimalTypeComponent';
import SpecialityCollegeComponent from './itemTypes/SpecialityCollegeComponent';
import SpecialityCollegeItemBoardInfoComponent
  from './itemTypes/SpecialityCollegeItemBoardInfoComponent';
import SpecialityCollegeItemsComponent from './itemTypes/SpecialityCollegeItemsComponent';
import AnimalPatientTypeComponent from './itemTypes/AnimalPatientTypeComponent';
import OnboardingFlowComponent from './OnboardingFlowComponent';
import CurrentRoleComponent from './itemTypes/CurrentRoleComponent';
import SimpleSelectableIconComponent from './itemTypes/SimpleSelectableIconComponent';
import { CURRENT_ROLE_CRITERIA, JOB_TYPE_SUBCATEGORIES, STEPS } from '../../config/enums';
import { JOB_TYPE_SUB_CATEGORY } from '../constants/Constants';

class SpecialistVeterinarianOnboarding extends OnboardingFlowComponent {
  constructor(props) {
    super(props, 6);
    const st = this.state;
    st.step0 = [false];
    st.step1 = [false];
    st.step2 = [false];
    st.step3 = [false];
    st.step4 = [false];
    st.step5 = [false];
    this.state = st;

    /* Remove telemed options when a worker edits details page
    (skip the page) and assign the worker to be in-person */
    this.addSkippedStep(3);
    this.autoSaveTelemedPrefAsInPerson();
  }

  componentDidUpdate(prevProps, prevState) {
    const { step } = this.state;
    if (step === STEPS.three && prevState.step === STEPS.two) {
      this.verifyCriteriaDataFilled(JOB_TYPE_SUB_CATEGORY, step, 0);
    }
  }

  initTelemedPref = (st) => {
    const { talentprofileitems, criterias } = this.props.resources;
    st.jobTypeSubCategory = this.findTalentProfileItemValue(JOB_TYPE_SUB_CATEGORY, talentprofileitems, criterias);
    st.continueDisabled = !st.jobTypeSubCategory;
    if (st.jobTypeSubCategory) {
      const item = new Map();
      item.set(JOB_TYPE_SUB_CATEGORY, [{ criteria: JOB_TYPE_SUB_CATEGORY, strValue: 'in-person' }]);
      st['step3-onboardingItems'] = item;
    }
  }

  handleOnClickTelemedPref = (key, step) => {
    this.handleStateChange([{ criteria: JOB_TYPE_SUB_CATEGORY, strValue: key }], JOB_TYPE_SUB_CATEGORY, step, 0);
    this.setState({
      jobTypeSubCategory: key,
    });
  };

  renderStep0() {
    const { classes } = this.props;
    return (
      <div>
        <Typography color="secondary" className={classes.title}>
          WHICH SPECIALTY COLLEGE?
        </Typography>
        <Typography align="center" color="primary" gutterBottom>
          You can select more than one
        </Typography>
        <SpecialityCollegeComponent
          initState={this.getOnboardingItems('speciality')}
          handleStateChange={items => this.handleStateChange(items, 'speciality', STEPS.zero, 0)}
        />
      </div>
    );
  }

  renderStep1() {
    const { classes } = this.props;
    const colleges = this.getOnboardingItems('speciality', 0)
      .map(x => x.strValue);
    return (
      <div>
        <SpecialityCollegeItemsComponent
          colleges={colleges}
          initState={this.getOnboardingItems('speciality')}
          setOnboardingItems={items => this.setOnboardingItems('speciality', items)}
          verifyCriteriaDataFilled={() => this.verifyCriteriaDataFilled('speciality', 1, 0)}
          disableContinue={() => this.disableContinueButton()}
          handleStateChange={items => this.handleStateChange(items, 'speciality', STEPS.one, 0)}
        />
      </div>
    );
  }

  renderStep2() {
    const { classes } = this.props;
    const specialities = this.getOnboardingItems('speciality', 1)
      .map(x => `${x.strValue}-${x.str2Value}`);
    return (
      <div>
        <SpecialityCollegeItemBoardInfoComponent
          specialities={specialities}
          initState={this.getOnboardingItems('speciality')}
          setOnboardingItems={items => this.setOnboardingItems('speciality', items)}
          disableContinue={() => this.disableContinueButton()}
          enableContinue={() => this.enableContinueButton()}
        />
      </div>
    );
  }

  renderStep3() {
    const { classes } = this.props;
    return (
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography color="secondary" className={classes.title}>
            TELEMEDICINE PREFERENCE?
          </Typography>
        </Grid>
        {
          JOB_TYPE_SUBCATEGORIES.map(type => (
            <Grid item key={type.key}>
              <SimpleSelectableIconComponent
                icon={type.key}
                title={type.title.toUpperCase()}
                selected={type.key === this.state.jobTypeSubCategory}
                onClick={() => this.handleOnClickTelemedPref(type.key, STEPS.three)}
              />
            </Grid>
          ))
        }
      </Grid>
    );
  }

  renderStep4() {
    const { classes } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <Typography color="secondary" className={classes.title}>
          TELL US ABOUT YOUR CURRENT ROLE
        </Typography>
        <CurrentRoleComponent
          keyValue="current-roles"
          info={CURRENT_ROLE_CRITERIA}
          enableContinue={() => this.enableContinueButton()}
          initState={this.getOnboardingItems('current-roles')}
          handleStateChange={items => this.handleStateChange(items, 'current-roles', STEPS.four, 0)}
        />
      </div>
    );
  }

  renderStep5() {
    const { classes } = this.props;
    return (
      <Grid container spacing={1} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography color="secondary" className={classes.title}>
            WHAT TYPE OF ANIMALS MAKE UP A SIGNIFICANT PORTION OF YOUR PATIENTS?
          </Typography>
        </Grid>
        <AnimalPatientTypeComponent
          criteria="patient-type"
          initState={this.getOnboardingItems('patient-type')}
          handleStateChange={items => this.handleStateChange(items, 'patient-type', STEPS.five, 0)}
        />
      </Grid>
    );
  }
}

export default withStyles(SpecialistVeterianFlowComponentStyle)(withRouter(SpecialistVeterinarianOnboarding));
