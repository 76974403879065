import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  Icon,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { DialogStyles } from '../../css/dialogStyle';

class DeleteConfirmDialog extends Component {
  constructor() {
    super();
    this.state = {};
  }

  badgeSelected(id, value) {
    const { badges } = this.state;
    badges[id] = !badges[id];
    this.setState({ badges });
  }

  render() {
    const {
      classes,
      handleClose,
      open,
      resourceName,
      handleDelete,
      jobTitle,
      practiceName,
      hasCancelButton,
      message,
    } = this.props;
    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            padding: '24px 4px',
            minWidth: '310px',
          },
        }}
        open={open}
        onClose={() => handleClose()}
      >
        <DialogTitle className={classes.dialogContent}>
          <div className={classes.titleContainer}>
            <Icon className={classes.icon}>delete</Icon>
            <Typography color="primary" className={classes.title}>
              {`Delete ${resourceName}`}
            </Typography>
          </div>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => handleClose()}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.dialogDescription}>
            { message ? message : `Are you sure you want to remove the ${resourceName.toLowerCase()} below ?` }
          </Typography>
          <Typography className={classes.dialogDescriptionBold}>
            {jobTitle}
          </Typography>
          <Typography className={classes.dialogDescriptionBold}>
            {practiceName}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          { hasCancelButton
            && (
            <Button
              key="DeleteConfirmDialog_Cancel"
              className={`${classes.button} ${classes.cancel}`}
              onClick={() => handleClose()}
            >
              CANCEL
            </Button>
            )
          }
          <Button
            key="DeleteConfirmDialog_Ok"
            className={classes.button}
            onClick={() => handleDelete()}
            color="primary"
            style={hasCancelButton ? { width: '50%' } : {}}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


DeleteConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default withStyles(DialogStyles)(withRouter(DeleteConfirmDialog));
