import { IconButton, TextField, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import PracticeGoogleMap from './PracticeGoogleMap';
import { isEmpty } from 'lodash';

const styles = theme => ({
  searchLabel: {
    fontFamily: 'Asap',
    fontWeight: 400,
    fontSize: 13,
    margin: '10px 0',
  },
  searchInput: {
    width: '100%',
  },
  mapContainer: {
    overflow: 'visible',
    width: '100%',
    paddingBottom: 20,
  },
  searchContainerFixed: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#ffffff',
    padding: 20,
  }
})

function SearchPracticeByMap({ classes, practicesInState, onConfirm, disabled, isMobile, address, selectedPractices, isShowPractices }) {
  const [value, setValue] = useState('');
  const [markers, setMarkers] = useState([]);
  const [practices, setPractices] = useState([]);
  const [zoomIn, setZoomIn] = useState(2);

  const onClickClearIcon = () => {
    setValue('');
    setMarkers([]);
  }

  useEffect(() => {
    if (value) {
      const practicesFilter = practicesInState?.filter(x => x.id && (x?.name?.toLowerCase().includes(value)));

      const markers = !isEmpty(practicesFilter) ? practicesFilter.filter(l => l.lat && l.lng)
        .map(l => ({ id: l.id, name: l.name, position: { lat: l.lat, lng: l.lng } })) : [];

      setPractices(practicesFilter)
      setMarkers(markers)
    } else {
      if (selectedPractices?.length > 0 && disabled) {
        const practicesFilter = selectedPractices?.filter(x => x.id && (x?.name?.toLowerCase().includes(value)));

        const markers = !isEmpty(practicesFilter) ? practicesFilter.filter(l => l.lat && l.lng)
          .map(l => ({ id: l.id, name: l.name, position: { lat: l.lat, lng: l.lng } })) : [];

        setPractices(practicesFilter)
        setMarkers(markers)
      } else if (address) {
        const practicesFilter = practicesInState?.filter(x => x.id && (x?.state === address));

        const markers = !isEmpty(practicesFilter) ? practicesFilter.filter(l => l.lat && l.lng)
          .map(l => ({ id: l.id, name: l.name, position: { lat: l.lat, lng: l.lng } })) : [];

        setPractices(practicesFilter)
        setMarkers(markers)
      } else {
        setZoomIn(3)
        setPractices([])
        setMarkers([])
      }
    }
  }, [address, value, selectedPractices])

  useEffect(() => {
    if (disabled) {
      setValue('')
    }
  }, [disabled])

  const onChange = (event) => {
    setValue(event.target.value);
  }

  return (
    <div style={{ marginLeft: isMobile ? 0 : 20 }}>
      {!isMobile && (
        <div className={classes.searchContainer}>
          <Typography className={classes.searchLabel}>
            Search Practices by Map
          </Typography>
          <TextField
            disabled={disabled}
            value={value}
            variant="outlined"
            className={classes.searchInput}
            placeholder='Search Practice Name'
            onChange={onChange}
            InputProps={{
              startAdornment: (
                <img
                  src='https://storage.googleapis.com/jobs-unleashed-common/icon.ico'
                  style={{ width: 24, height: 24, transform: 'scaleX(-1) translateX(8px)' }}
                />
              ),
              endAdornment: (
                <IconButton
                  style={{ visibility: value ? 'visible' : 'hidden', padding: 8 }}
                  onClick={onClickClearIcon}
                >
                  <ClearIcon style={{ fontSize: 16 }} />
                </IconButton>
              ),
              style: { height: '36px' }
            }}
          />
        </div>
      )}
      <div className={classes.mapContainer} style={{ height: isMobile ? 'calc(100vh - 175px)' : 450, paddingTop: isMobile ? 0 : 10 }}>
        <PracticeGoogleMap
          markers={markers}
          practices={practices}
          onConfirm={onConfirm}
          disabled={disabled}
          zoomIn={zoomIn}
          state={address}
          isShowPractices={isShowPractices || !!value}
          containerStyle={{
            padding: 0,
            margin: 0,
            with: '100%',
            height: '100%',
            overflowY: 'visible',
          }}
        />
      </div>
      {isMobile && (
        <div className={classes.searchContainerFixed}>
          <Typography className={classes.searchLabel}>
            Search Practices by Map
          </Typography>
          <TextField
            disabled={disabled}
            value={value}
            variant="outlined"
            className={classes.searchInput}
            placeholder='Search City, State, Zip or Practice Name'
            onChange={onChange}
            InputProps={{
              startAdornment: (
                <img
                  src='https://storage.googleapis.com/jobs-unleashed-common/icon.ico'
                  style={{ width: 24, height: 24, transform: 'scaleX(-1) translateX(8px)' }}
                />
              ),
              endAdornment: (
                <IconButton
                  style={{ visibility: value ? 'visible' : 'hidden', padding: 8 }}
                  onClick={onClickClearIcon}
                >
                  <ClearIcon style={{ fontSize: 16 }} />
                </IconButton>
              ),
              style: { height: '36px' }
            }}
          />
        </div>
      )}
    </div>
  )
}

export default withStyles(styles)(withRouter(SearchPracticeByMap));