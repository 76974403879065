import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem, OutlinedInput, Select, Grid, withStyles,
} from '@material-ui/core';
import { monthLongList } from '../../Datepicker/dateUtils';
import OnboardingItemComponent from './OnboardingItemComponent';

import { OnboardingItemComponentStyle } from '../../../css/style';

class StudentGraduationDateComponent extends OnboardingItemComponent {
  constructor(props) {
    super(props);
    const { initState } = props;
    const st = { value: '' };
    initState.forEach(item => st.value = item.strValue);
    this.state = st;
    this.handleState();
  }

  handleState = () => {
    const {
      criteria,
      handleStateChange,
    } = this.props;
    const items = [{
      criteria,
      strValue: this.state.value,
    }];
    const { value } = this.state;
    const year = (value || '/').split('/')[0];
    const month = (value || '/').split('/')[1];
    if (year && month) handleStateChange(items);
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const year = (value || '/').split('/')[0];
    const month = (value || '/').split('/')[1];
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Select
            value={month}
            onChange={e => this.setState({ value: `${year}/${e.target.value}` }, () => this.handleState())}
            classes={{
              root: classes.select,
            }}
            input={(
              <OutlinedInput
                fullWidth
                name="month"
                id="outlined-input"
              />
            )}
          >
            {
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(m => (
                <MenuItem key={m} value={m}>
                  {monthLongList[m - 1]}
                </MenuItem>
              ))
            }
          </Select>
        </Grid>
        <Grid item xs={6}>
          <Select
            value={year}
            onChange={e => this.setState({ value: `${e.target.value}/${month}` }, () => this.handleState())}
            classes={{
              root: classes.select,
            }}
            input={(
              <OutlinedInput
                fullWidth
                name="Year"
                id="outlined-input"
              />
            )}
          >
            {
              [0, 1, 2, 3, 4, 5].map(y => (
                <MenuItem key={y} value={new Date().getFullYear() + y}>
                  {new Date().getFullYear() + y}
                </MenuItem>
              ))
            }
          </Select>
        </Grid>
      </Grid>
    );
  }
}

StudentGraduationDateComponent.propTypes = {
  criteria: PropTypes.string.isRequired,
  initState: PropTypes.array.isRequired,
  handleStateChange: PropTypes.func.isRequired,
};

StudentGraduationDateComponent.defaultProps = {
  initState: [],
};

export default withStyles(OnboardingItemComponentStyle)(StudentGraduationDateComponent);
