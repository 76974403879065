import { PropTypes } from 'prop-types';
import React, { Component, Fragment } from 'react';
import { isEqual, uniq, isEmpty, sortBy } from 'lodash';
import classNames from 'classnames';
import moment from 'moment';
import {
  Backdrop,
  Button,
  CardMedia,
  Container,
  Fade,
  Grid,
  Hidden,
  IconButton,
  Icon,
  Modal,
  Paper,
  Typography,
  Drawer,
  Toolbar,
  Fab,
  Box,
  ButtonBase,
  Divider,
  Tooltip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import FilterListIcon from '@material-ui/icons/FilterList';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import PlaceIcon from '@material-ui/icons/Place';
import TodayIcon from '@material-ui/icons/Today';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { withRouter } from 'react-router-dom';
import types from '../../actions/ActionTypes';
import { MatchDetailsPageStyle } from '../../css/matchDetailsPageStyle';
import { bgGray, navy, orangeLight } from '../../css/style';
import { badgeColor, IMG_STORAGE_LINK, DESKTOP_BREAKPOINT } from '../constants/Constants';

import DateUtilities from '../Datepicker/utils';
import { isPrivateStaff, checkIsBoostedJob, isReliefOrExternshipType, formatPostTitle, formatPostRole, getJobRoleText } from '../../utils/Functions';
import { Alert } from '@material-ui/lab';
import RefreshPageDialog from '../subcomponents/RefreshPageDialog';
import LocationGoogleMap from '../subcomponents/LocationGoogleMap';
import { orange, lightGray, disableGray, tiffany } from '../../css/style';
import CalendarContainer from '../Datepicker/CalendarContainer';
import {  renderConnectModal } from '../core/renderHelper';
import { Lens } from '@material-ui/icons';

const fastpassIcon = `${IMG_STORAGE_LINK}green-ticket.png`;

const BOOST_TITLE = 'Boosted Post';

export class MatchDetailsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matchItem: '',
      unreadCount: 0,
      messageTotal: 0,
      calendarDialog: false,
      openRefreshDialog: false,
      availableDates: [],
      takenDates: [],
      pendingDates: [],
      calendarInitDisplayDate: null,
      requestedDates: '',
      fastpassData: [],
      openMobileCalendar: true,
      isNewOnly: false,
      allMessages: [],
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      user,
      match,
      resources,
      jobposting,
      actions,
    } = nextProps;
    const { params } = match;
    const { matches } = resources;
    let item;
    const { id } = params;

    if (jobposting) {
      const jobPostingData = resources[`jobpostings/${id}`]
      if (!isEmpty(jobPostingData)) {
        item = jobPostingData[0];
      }
    } else if (matches) {
      const itemIndex = matches.map(match => match.jobPosting.id.toString())
        .indexOf(id.toString());
      item = matches[itemIndex];
      if (user && item) {
        if (item.status !== 'connected' && item.status !== 'read') {
          actions.updateResource('updateMatchStatus', user.token, {
            id: item.id,
            status: 'read',
          }, 'matches', item.id);
        }
      }
    }

    return {
      signedIn: !!user,
      currentItem: item,
      openConfirmationModal: prevState && prevState.openConfirmationModal || false,
      fastpassPractice: prevState && prevState.fastpassData &&  prevState.fastpassData.find(fp => fp.practice_id === item?.practice?.id),
    };
  }

  componentDidMount = async () => {
    const {
      actions,
      user,
      match,
      jobposting,
      resources,
    } = this.props;
    const { params } = match;
    const { id } = params;

    if (!jobposting) {
      let theMatch = [];
      const resourceMatches = resources?.matches;
      if (resourceMatches) {
        theMatch = resourceMatches.filter(match => match.jobPosting.id == id);
          this.setState({
            matchItem: theMatch.length > 0 && theMatch[0]}, () => {
              this.getMessagesData(theMatch[0]);
              this.initiateSelectedDates();
          });
      } else { 
        actions.getAllResources(user.token, 'matches').then((res) => {
          const matches = res.response;
          theMatch = matches && matches.filter(match => match.jobPosting.id == id);
          this.setState({
            matchItem: theMatch.length > 0 && theMatch[0]}, () => {
              this.getMessagesData(theMatch[0]);
              this.initiateSelectedDates();
          });
        });
      }
    } else { // jobposting
      actions.getOneResource(user.token, `jobpostings/${id}`);
      this.getMessagesData();
      this.initiateSelectedDates();
    }
    actions.addResource('log-event', user.token, { action: 'talent_details' }, 'users', 'log-event');
  }

  getMessagesData = (theMatch) => {
    const {
      actions,
      user,
      match,
      resources,
    } = this.props;
    const matchId = theMatch?.id;
    if(!resources?.messages) {
      actions.getAllResources(user.token, 'messages')
        .then(res => !res.error && this.updateMessageStates(res.response, matchId));
    } else {
      this.updateMessageStates(resources.messages, matchId);
    }
  };

  componentDidUpdate(prevProps) {
    const { resources } = this.props;

    if (prevProps.resources && resources && !isEqual(prevProps.resources.messages, resources.messages)) {
      this.updateMessageStates(resources.messages, this.state.matchItem.id);
    }
  }

  initiateSelectedDates = async () => {
    const { matchItem } = this.state;
    const { resources, match, user, actions } = this.props;
    const { params } = match;
    const { id } = params;

    const openExternJobPostings = resources && resources['jobpostings/open-externships'];

    if (matchItem
      && matchItem.jobPosting
      && isReliefOrExternshipType(matchItem.jobPosting.typeOfEmployment)) {
      let availableDates = [];
      let takenDates = [];
      let pendingDates = [];
      let userTakenDates = [];
      const jobPostingId = matchItem.jobPosting.id;

      const cData = await this.retrieveCalendarData({
        typeOfEmployment: matchItem.jobPosting.typeOfEmployment,
        jobId: jobPostingId,
      });

      if (cData) {
        availableDates = cData.availableDates;
        takenDates = cData.takenDates;
        pendingDates = cData.pendingDates;
        userTakenDates = cData.userTakenDates;
        this.setState({ calendarData: cData.calendarData });
      }
      this.setState({
        availableDates,
        takenDates,
        pendingDates,
        confirmedDates: userTakenDates,
        calendarInitDisplayDate: this.getEarliestSelectedDate(availableDates, takenDates, pendingDates),
        typeOfEmployment: matchItem.jobPosting.typeOfEmployment,
      });

      if (jobPostingId) {
        actions.getAllResources(user.token, `date-request-trackings/job-posting/${jobPostingId}`).then((dateRes) => {
          const requestTrackingDates = dateRes?.response || [];
          const declinedTrackingDates = requestTrackingDates.filter(r => r.user_id === user.id && r.status === 'declined' && r.match_id === Number(matchItem.id)).map(r => DateUtilities.midDayDate(r.date));
          this.setState({ declinedTrackingDates });
        });
      }

      this.retrieveFastpassData();
      this.getCalendarMatches();
    } else if (!isEmpty(openExternJobPostings)) {
      const thePost = openExternJobPostings.filter(jp => jp.id === Number(id));
      if (thePost && thePost.length > 0) {
        const cData = await this.retrieveCalendarData({
          typeOfEmployment: thePost[0].typeOfEmployment,
          jobId: thePost[0].id,
        });
  
        if (cData) {
          this.setState({
            availableDates: cData.availableDates,
            takenDates: [],
            pendingDates: [],
            confirmedDates: [],
            calendarInitDisplayDate: this.getEarliestSelectedDate(cData.availableDates, [], []),
            typeOfEmployment: thePost[0].typeOfEmployment,
            calendarData: cData.calendarData
          });
        }
      }
    }
  };

  retrieveCalendarData = async ({ typeOfEmployment, jobId }) => {
    const { user, actions } = this.props;

    if (!jobId || !isReliefOrExternshipType(typeOfEmployment)) return;

    let availableDates = [];
    let takenDates = [];
    let pendingDates = [];
    let userTakenDates = [];

    const calendarResp = await actions.getAllResources(user.token, `jobpostings/${jobId}/calendar`);
    if (calendarResp && calendarResp.response) {
      userTakenDates = calendarResp.response
        .filter(
          (row) =>
            row.status === "taken" &&
            row.talent_user_id === user.id &&
            row.confirmation
        )
        .map((a) => DateUtilities.midDayDate(a.date));
      const avaiUniqDates = uniq(calendarResp.response.filter(a => a.status === 'available').map(a => a.date));
      availableDates = avaiUniqDates.map(a => DateUtilities.midDayDate(a));

      // Filter out avai dates which have been taken by the user
      if (!isEmpty(userTakenDates)) {
        availableDates = availableDates.filter(date => !DateUtilities.dateIn(userTakenDates, date));
      }

      const takenUniqDates = uniq(calendarResp.response.filter(t => t.status === 'taken').map(t => t.date));
      takenDates = takenUniqDates.map(a => DateUtilities.midDayDate(a));
      takenDates = takenDates.filter(date => !DateUtilities.dateIn(availableDates, date));

      if (typeOfEmployment === 'Externship') {
        // Get pending date rows
        let pendingRows = calendarResp.response.filter(row => row.status === 'pending');
        pendingRows = pendingRows.map(r => ({
          ...r,
          date: DateUtilities.midDayDate(r.date),
        }));
        // Filter out available shifts which are not requested by the user
        pendingRows = pendingRows.filter(pendingRow => pendingRow.talent_user_id === user.id
          || !DateUtilities.dateIn(availableDates, pendingRow.date));

        pendingDates = pendingRows.map(r => r.date);
      }
    }
    return { calendarData: calendarResp.response, availableDates, takenDates, pendingDates, userTakenDates };
  };

  getCalendarMatches = async () => {
    const { actions, user, resources } = this.props;
    let calendarMatches = resources['jobPostingCalendars/getCurrentCalendarMatches'];

    if (!calendarMatches) {
      calendarMatches = await actions.getAllResources(user.token, 'jobPostingCalendars/getCurrentCalendarMatches').then(res => res.response);
    }

    const allConfirmedDates = calendarMatches
      && calendarMatches.confirmed ? Object.keys(calendarMatches.confirmed).map(d => DateUtilities.midDayDate(d)) : [];

    this.setState({ allConfirmedDates });
  };

  retrieveFastpassData = async () => {
    const { actions, user } = this.props;
    let activeFastpass = [];
    const fastpassRes = await actions.getAllResources(user.token, `fastpass/user/${user.id}`);
    activeFastpass = (fastpassRes?.response || []).filter(r => !r.deletedAt);
    this.setState({ fastpassData: activeFastpass});
  };

  getEarliestSelectedDate = (availableDates, takenDates, pendingDates) => {
    const sortedDateList = [...availableDates, ...takenDates, ...pendingDates].sort((date1, date2) => (date1 - date2));
    return sortedDateList.length ? sortedDateList[0] : null;
  };

  updateMessageStates = (messages, matchId) => {
    const { user } = this.props;
    let messageList = [...messages];
    if (isEmpty(messageList)) return;

    messageList = messageList.filter(x => x.match && !x.match.invisibleToTalent);
    const allUnreadMsgIds = messageList.filter(msg => msg.isUnread && msg.creator_id != user.id).map(msg => msg.id);

    const results = [];
    let allMessageCards = sortBy(messageList, 'id').reverse();
    allMessageCards = allMessageCards.filter((msg) => {
      if (results.findIndex(res => msg.match.id === res) === -1) {
        results.push(msg.match.id);
        return msg;
      }
    });

    this.setState({
      allUnreadMsgIds,
      allMessages: allMessageCards,
      messageActivated: matchId,
    });
  };

  onClickConnect = () => {
    const {
      user,
      history,
    } = this.props;
    if (user) {
      this.setState({
        openConfirmationModal: true,
      });
    } else {
      history.push('/login');
    }
  };

  redirectToMessages = (id) => {
    const {
      history,
      actions,
      user,
    } = this.props;
    const { isChatOnly, requestedDates } = this.state;

    actions.getAllResources(user.token, 'messages').then((response) => {
      if (response.type === types.GET_ALL_RESOURCES_SUCCESS) {
        if (isEmpty(requestedDates)) {
          history.push(`/messages/${id}`);
        } else {
          if (isChatOnly) {
            history.push(`/messages/${id}?dates=${requestedDates}&chat=true`);
          } else {
            history.push(`/messages/${id}?dates=${requestedDates}`);
          }
        }
      }
    });
  };

  connectToMatch = (item) => {
    const { actions, user } = this.props;
    const { currentItem } = this.state;

    if (item.jobPosting) { // for matches
      actions.updateResource('matches', user.token, {
        id: item.id,
        status: 'connected',
        connectDate: new Date(),
      }, 'matches', item.id)
        .then((e) => {
          actions.addResource('log-event', user.token, { action: 'talent_connect' }, 'users', 'log-event');
          if (e.type === types.UPDATE_RESOURCE_SUCCESS) {
            this.setState({ openConfirmationModal: false });
          }
          this.redirectToMessages(currentItem.id);
        });
    } else { // for open externship posts --> create a match
      let payload = {
        job_posting_id: item.id,
        status: 'connected',
        connectDate: new Date(),
        savedSearchSnapshop: '{}',
      };
      actions.addResource('matches', user.token, payload, 'matches').then((res) => {
        actions.addResource('log-event', user.token, { action: 'talent_connect' }, 'users', 'log-event');
        if (res?.id) {
          this.setState({ openConfirmationModal: false });
          this.redirectToMessages(res.id);
        }
      });
    }
  };

  getConfirmModal = () => {
    const {
      classes,
      user,
    } = this.props;
    const {
      openConfirmationModal,
      currentItem,
    } = this.state;
    return currentItem && user && (
      <Modal
        className={classes.modal}
        open={openConfirmationModal}
        onClose={e => this.setState({ openConfirmationModal: false })}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openConfirmationModal}>
          <Paper className={classes.paper} style={{ maxWidth: 350 }}>
            <Container className={classes.modal}>
              <CheckCircleIcon className={classes.modalHeaderIcon} />
              <Typography
                className={classes.modalHeader}
              >
                CONNECT NOW!
              </Typography>
              <Typography align="center">
                We will forward your contact info to
                <b>{currentItem.practice ? ` ${currentItem.practice.name} ` : ''}</b>
                and let you ask them any questions you have.
              </Typography>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={e => this.connectToMatch(currentItem)}
              >
                confirm connection
              </Button>
            </Container>
          </Paper>
        </Fade>
      </Modal>
    );
  };

  getBadge(title, id) {
    const { classes } = this.props;
    return (
      <Button
        className={classes.badgeFilter}
        key={title}
        style={{
          backgroundColor: badgeColor(id || title),
        }}
        ref={title}
      >
        {title}
      </Button>
    );
  }

  getBadges() {
    const { classes } = this.props;
    const { currentItem } = this.state;
    return currentItem && currentItem.badges.length && (
      <div>
        <Typography className={classes.title}>
          Badges
        </Typography>
        <div
          style={{
            height: 30,
            overflow: 'hidden',
            paddingRight: '50px',
            position: 'relative',
            margin: '16px 0px'
          }}
        >
          {currentItem && currentItem.badges.map(badge => this.getBadge(badge.title, badge.id))}
          {this.moreBadges()}
        </div>
      </div>
    ) || '';
  }

  moreBadges() {
    const { currentItem } = this.state;
    let lineTops = [];
    const publicRef = this.refs.public;
    const privateRef = this.refs['private-staff'];
    let firstLineTop = publicRef ? publicRef.offsetTop : null;
    if (!firstLineTop) {
      firstLineTop = privateRef ? privateRef.offsetTop : null;
    }
    if (firstLineTop && privateRef && firstLineTop !== privateRef.offsetTop) {
      lineTops.push(privateRef.offsetTop);
    }
    currentItem && currentItem.badges.map((badge) => {
      let ref = this.refs[badge.title];
      if (ref) {
        if (!firstLineTop) {
          firstLineTop = ref.offsetTop;
        }
        if (ref.offsetTop != firstLineTop) {
          lineTops.push(ref.offsetTop);
        }
      }
    });
    if (lineTops && lineTops.length > 0) {
      return (
        <span
          style={{
            position: 'absolute',
            top: 10,
            right: 0,
            fontSize: '14px',
            fontFamily: 'Asap',
          }}
        >
          +
          {lineTops.length}
          {' '}
          more
        </span>
      );
    } else {
      return null;
    }
  }

  renderFilterButton = () => {
    const { isNewOnly } = this.state;
    const { classes } = this.props;

    return (
      <Tooltip title={isNewOnly ? 'New Only' : 'All Messages'} placement='top' >
        <IconButton
          className={classNames({ [classes.filterButton]: isNewOnly })}
          aria-label="filter"
          onClick={() => this.setState({ isNewOnly: !isNewOnly })}
          style={{ padding: '4px'}}
        >
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    )
  };

  render() {
    const { width } = this.props;
    return !isWidthUp(DESKTOP_BREAKPOINT, width)
      ? this.renderMobile()
      : this.renderDesktop();
  }

  renderDesktop = () => {
    const {
      classes,
      history,
      jobposting,
      user,
    } = this.props;
    const {
      signedIn,
      currentItem,
      matchItem,
      availableDates,
      takenDates,
      pendingDates,
      fastpassData,
      fastpassPractice,
      confirmedDates,
      allConfirmedDates,
      warningMsg,
      messageActivated,
      allMessages,
      allUnreadMsgIds,
      isNewOnly,
      calendarData,
      //practiceImages,
      declinedTrackingDates,
    } = this.state;

    const cardsToDisplay = isNewOnly ? allMessages.filter(m => allUnreadMsgIds.includes(m.id)) : allMessages;

    const item = currentItem ? (jobposting ? currentItem : currentItem.jobPosting) : {};
    const isConnected = matchItem && matchItem.status === 'connected';
    const isArchived = item && item.deletedAt && !item.isDeleted;
    const isDeleted = item && item.deletedAt && item.isDeleted;
    const practiceImages = currentItem ? currentItem.practiceImages : [];
    const practice = currentItem ? currentItem.practice : {};
    const showConnect = currentItem ? currentItem.status !== 'connected' : false;
    const enableConnectButton = user && user.isVerified;
    let href = window.location.href;
    href = href.replace(/\//g, '%2F');
    href = href.replace(/:/g, '%3A');

    const showDates = item ? isReliefOrExternshipType(item.typeOfEmployment) : false;

    const isExternshipJobPosting = item.typeOfEmployment === 'Externship';

    //const boostDescription = isPrivateStaff(user) ? item.staffDescription : item.contractDescription;
    //const isBoostedJob = checkIsBoostedJob(boostDescription, item);

    const submitButtonText = !isExternshipJobPosting && !isEmpty(fastpassPractice) ? 'Claim Dates' : 'Request';

    let destination = practice?.location;
    destination = destination?.replace(/\//g, '%2F');
    destination = destination?.replace(/:/g, '%3A');
    const rotationTypes = item && item.rotationTypes && JSON.parse(item.rotationTypes);
    return (
      <div className={classes.root}>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          anchor="left"
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader} style={{ justifyContent: 'space-between', padding: '0 20px' }}>
            <Typography className={classes.drawerHeading}>Messages</Typography>
            {this.renderFilterButton()}
          </div>
          <Divider />

          <div style={{ overflowY: 'auto', overflowX: 'hidden' }}>
            {cardsToDisplay && cardsToDisplay.map(message => {
              const isToday = moment(message.createdAt).isSame(moment(), 'day');
              const savedSearch = message?.match?.savedSearchSnapshop && JSON.parse(message?.match?.savedSearchSnapshop);

              return (
                <div className={classes.messageCard} style={{backgroundColor: +messageActivated === +message.match.id ? '#E1E5EA' : 'unset'}}
                  onClick={() => {
                    this.setState({ messageActivated: message.match.id });
                    history.push(`/messages/${message.match.id}`);
                  }}
                >
                  <Grid container className={classes.cardHeader}>
                    <Grid item xs={7}>
                      <Typography
                        className={classNames(classes.cardTitle, {
                          [classes.active]: +messageActivated === +message.match.id,
                          [classes.cardTitleActive]: allUnreadMsgIds.includes(message.id)
                        })}
                      >
                        {message?.practice?.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={5} className={classes.cardSubTitle}>
                      {savedSearch?.title}
                    </Grid>
                  </Grid>
                  <Grid container className={classes.cardBody}>
                    <Grid item xs={9} className={allUnreadMsgIds.includes(message.id) ? classes.active : ''} style={{ display: 'flex', alignItems: 'center' }}>
                      { allUnreadMsgIds.includes(message.id) &&
                        ( <FiberManualRecordRoundedIcon
                            style={{ color: orangeLight, width: '12px', marginLeft: '-16px', marginRight: '5px' }}
                          />
                        )}
                      <Typography className={classes.msgJobTitle}>
                        {formatPostTitle(message.jobTitle)}                       
                      </Typography>
                    </Grid>
                    <Grid item xs={3} className={classNames(classes.contentRight, allUnreadMsgIds.includes(message.id) ? classes.active : '')}>
                      {allUnreadMsgIds.includes(message.id)
                        ? (<strong>{isToday ? moment(message.createdAt).format('LT') : moment(message.createdAt).format('L')}</strong>)
                        : (isToday ? moment(message.createdAt).format('LT') : moment(message.createdAt).format('L'))}
                    </Grid>
                  </Grid>
                  <Typography className={classes.messageText}>
                    <strong>{message.creatorName}</strong> - {message.body}
                  </Typography>
                </div>
              )
            })}
          </div>
        </Drawer>
        <div className={classes.content}>
          <Toolbar className={classes.contentToolbar}>
            <Grid container direction="row" alignContent="center" style={{ padding: '0px 20px', width: '100%', display: 'flex' }}>
              <Grid container item xs={7} className={classes.headingTitleItem}>
                <Grid item>
                  <Typography color="secondary"  className={classes.pageTitle}>
                    {formatPostTitle(item.title)}
                    {!isExternshipJobPosting && !isEmpty(fastpassPractice) && (<img className={classes.fastpassImg} src={fastpassIcon} alt='fastpass' title='Fast Pass' />)}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography className={classes.pageSubTitle}>{practice ? practice.name : '-'}</Typography>
                </Grid>
              </Grid>
              <Grid item xs={5} className={classes.headingBtnGroup}>
                <ButtonBase
                  target='_blank'
                  href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
                  style={{ marginRight: showConnect ? '20px' : 'unset' }}
                >
                  <PlaceIcon className={classes.matchIteminfoIcon} />
                  <Typography className={classes.directionLink}>
                    Get Directions
                  </Typography>
                </ButtonBase>
                {showConnect && (
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={signedIn && !enableConnectButton}
                    className={classes.connectButton}
                    onClick={() => this.onClickConnect()}
                  >
                    {signedIn ? 
                    (<>
                      <EmojiEmotionsIcon className={classes.connectFabBtnIcon} />
                      Connect Me
                      </>
                    ) : 'Sign in to Connect!'}
                  </Button>
                )}
              </Grid>
            </Grid>
          </Toolbar>
          <div className={classes.infoContainer}>
            {((isConnected && isArchived) || (isConnected && isDeleted) || showDates ) && (
            <Grid container spacing={1} className={classes.practiceContainer}>
              {isConnected && isArchived &&
                <Grid item xs={12}>
                  <Alert variant="filled" severity="warning">The underlying connected match is now archived</Alert>
                </Grid>
              }
              {isConnected && isDeleted &&
                <Grid item xs={12}>
                  <Alert variant="filled" severity="error">The underlying connected match is now deleted</Alert>
                </Grid>
              }
              { showDates && (
              <> <Divider/>
              <Grid item xs={12}>
                <CalendarContainer
                  selectedDates={availableDates || []}
                  declinedTrackingDates={declinedTrackingDates || []}
                  notAvailableDates={takenDates?.filter(date => !DateUtilities.dateIn(confirmedDates, date))}
                  confirmedDates={confirmedDates || []}
                  allConfirmedDates={allConfirmedDates || []}
                  pendingDates={pendingDates}
                  initialDate={this.state.calendarInitDisplayDate}
                  readOnly={isDeleted}
                  disabledConnect={showConnect && signedIn && !enableConnectButton}
                  onSubmit={async (selectedDates, notAvailableDates, requestedDates, isChatOnly) => {
                    // Check if the calendar data is latest
                    if (!isEmpty(requestedDates)) {
                      const cData = await this.retrieveCalendarData({
                        typeOfEmployment: matchItem?.jobPosting?.typeOfEmployment || item?.typeOfEmployment,
                        jobId: matchItem?.jobPosting?.id || item?.id,
                      });
                      if (!isEqual(cData?.calendarData, this.state.calendarData)) {
                        this.setState({ openRefreshDialog: true });
                        return;
                      }
                    }
                    if (showConnect) {
                      this.setState({
                        requestedDates: requestedDates.map(d => moment(d).format('YYYY-MM-DD')).join(','),
                        calendarDialog: false,
                        isChatOnly,
                      });
                      this.onClickConnect();
                    } else {
                      history.push(`/messages/${currentItem.id}?dates=${requestedDates.map(d => moment(d).format('YYYY-MM-DD')).join(',')}`);
                    }
                  }}
                  submitButtonText={submitButtonText}
                  chatButtonText={showConnect && !isExternshipJobPosting && !isEmpty(fastpassPractice) && 'Chat'}
                  isFastpass={!isExternshipJobPosting && !isEmpty(fastpassPractice)}
                  calendarData={calendarData}
                />
              </Grid>
              </>)}
            </Grid>
            )}
            <Grid container spacing={1} className={classes.practiceContainer}>
              <Grid item xs={12}>
                <Typography className={classes.practiceDescriptionTitle}>
                  Description
                </Typography>
                <Typography
                  className={classes.practiceDescription}
                  dangerouslySetInnerHTML={{ __html: item.description }}
                />
              </Grid>
              {/* { isBoostedJob && (
              <Grid item xs={12} className={classes.practiceContent}>
                <div className={classes.boostDescriptionContainer}>
                  <div className={classes.boostBorderContainer}>
                    <Typography className={classes.title}>
                      {BOOST_TITLE}
                    </Typography>
                    <Typography className={classes.boostDescription}>
                      {boostDescription}
                    </Typography>
                  </div>
                </div>
              </Grid>
              )} */}
              <Grid item xs={12} className={classes.practiceContent}>
                {this.getBadges()}
              </Grid>
              { item?.typeOfEmployment === 'Externship' && rotationTypes && rotationTypes.length > 0 && (<Grid item xs={12}>
                  <Typography className={classes.practiceDescriptionTitle}>
                    Externship Rotation
                  </Typography>
                  <Typography>{rotationTypes?.join(', ')}</Typography>
                </Grid>
              )}
            </Grid>    

            { practice
              && (
              <Grid container spacing={1} className={classes.practiceContainer}>
                <Grid item xs={12}>
                  <Typography className={classes.practiceDescriptionTitle} gutterBottom>
                    About The Hospital
                  </Typography>
                </Grid>
                <Grid item xs={practiceImages.length === 0 ? 12: 6} className={classes.practiceContent}>
                  <Typography
                    className={classes.practiceDescription}
                    dangerouslySetInnerHTML={{ __html: practice.description || '' }}
                  />
                </Grid>
                { practiceImages.length > 0 && (
                <Grid item xs={6} className={classes.carouselContainer}>
                    <CardMedia>
                      <Carousel
                        showArrows
                        showStatus={false}
                        showThumbs={false}
                        infiniteLoop
                        autoPlay
                        centre
                        maxWidth='516px'
                        height='344px'
                      >
                        {
                          practiceImages.map(image => (
                            <img
                              src={image.image}
                              style={{
                                maxHeight: '344px',
                                maxWidth: '516px',
                                objectFit: 'contain',
                              }}
                            />
                          ))
                        }
                      </Carousel>
                    </CardMedia>
                </Grid>
          )}
              </Grid>
            )}
            {
              practice
              && (
              <Grid container spacing={1} className={classes.practiceContainer}>
                <Grid item xs={12} className={classes.mapTitleContainer}>
                  <Typography className={classes.title}>
                    Location
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography className={classes.practiceDescription}>{practice.location || '-'}</Typography>
                </Grid>
                <Grid item xs={6} className={classes.mapTitleContainer} style={{ justifyContent: 'flex-end' }}>
                  <ButtonBase
                    target='_blank'
                    href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
                  >
                    <PlaceIcon className={classes.matchIteminfoIcon} />
                    <Typography className={classes.directionLink}>
                      Get Directions
                    </Typography>
                  </ButtonBase>
                </Grid>
                <Grid item xs={12}>
                  <LocationGoogleMap
                    markers={[{ id: practice.id, name: practice.name, position: { lat: practice.lat, lng: practice.lng, } }]}
                    handleMarkerClick={() => {}}
                  />
                </Grid>
              </Grid>
              )
            }

            {showConnect && (
              <Fab
                variant="extended"
                disabled={signedIn && !enableConnectButton}
                className={classes.connectFabBtn}
                onClick={() => this.onClickConnect()}
              >
                {signedIn ? 
                (<>
                  <EmojiEmotionsIcon className={classes.connectFabBtnIcon}/>
                  Connect Me
                  </>
                ) : 'Sign in to Connect!'}
              </Fab>
            )
            }

            {renderConnectModal({
              ...this.props,
              openConfirmationModal: this.state.openConfirmationModal,
              currentItem: this.state.currentItem,
              onClose: () => this.setState({ openConfirmationModal: false }),
              onOk: () => this.connectToMatch(this.state.currentItem),
            })}

            <RefreshPageDialog
              open={this.state.openRefreshDialog}
              handleClose={() => this.setState({ openRefreshDialog: false })}
            />
          </div>
        </div>
      </div>
    );
  }

  renderMobile = () => {
    const {
      classes,
      history,
      jobposting,
      user,
    } = this.props;
    const {
      signedIn,
      currentItem,
      matchItem,
      availableDates,
      takenDates,
      pendingDates,
      fastpassPractice,
      confirmedDates,
      allConfirmedDates,
      openMobileCalendar,
      calendarData,
      //practiceImages,
      declinedTrackingDates,
    } = this.state;

    const item = currentItem ? (jobposting ? currentItem : currentItem.jobPosting) : {};
    const isConnected = matchItem && matchItem.status === 'connected';
    const isArchived = item && item.deletedAt && !item.isDeleted;
    const isDeleted = item && item.deletedAt && item.isDeleted;
    const practiceImages = currentItem ? currentItem.practiceImages : [];
    const practice = currentItem ? currentItem.practice : {};
    const showConnect = currentItem ? currentItem.status !== 'connected' : false;
    const enableConnectButton = user && user.isVerified;
    let href = window.location.href;
    href = href.replace(/\//g, '%2F');
    href = href.replace(/:/g, '%3A');

    const showDates = item ? isReliefOrExternshipType(item.typeOfEmployment) : false;
    const isExternshipJobPosting = item.typeOfEmployment === 'Externship';

    //const boostDescription = isPrivateStaff(user) ? item.staffDescription : item.contractDescription;
    //const isBoostedJob = checkIsBoostedJob(boostDescription, item);

    const submitButtonText = !isExternshipJobPosting && !isEmpty(fastpassPractice) ? 'Claim Dates' : 'Request';

    let destination = practice?.location;
    destination = destination?.replace(/\//g, '%2F');
    destination = destination?.replace(/:/g, '%3A');
    const rotationTypes = item && item.rotationTypes && JSON.parse(item.rotationTypes);
    return (
      <div className={classes.root}>
        <div
          className={classes.mobileContent}
        >
          <Toolbar
            style={{
              display: 'flex',
              padding: 0,
              width: '100%',
              height: '87px',
              backgroundColor: bgGray,
              borderBottom: '1px solid #E1E5EA',
              position: 'fixed',
              zIndex: 10,
            }}
          >
            <Grid container direction="row" alignContent="center" style={{ padding: '20px', width: '100%', display: 'flex' }}>
              <Grid item xs={1} className={classes.headingBtnGroup} style={{ justifyContent: 'center' }}>
                <ButtonBase onClick={() => user?.isStudent ? history.push('/externhome') : history.push('/matches')}>
                  <ChevronLeftIcon style={{ fontSize: '36px', color: navy}}/>
                </ButtonBase>
              </Grid>
              <Grid container item xs={10} className={classes.headingTitleItem}>
                <Grid item style={{ paddingLeft: '4px' }}>
                  <Typography color="secondary"  className={classes.pageTitle}>
                    Details
                  </Typography>
                </Grid>
                <Grid item>
                  <ButtonBase
                    target='_blank'
                    href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
                  >
                    <PlaceIcon className={classes.matchIteminfoIcon} />
                    <Typography className={classes.directionLink}>
                      Get Directions <Lens style={{ fontSize: '7px', margin: '0px 4px' }}/> {practice?.location || ''}
                    </Typography>
                  </ButtonBase>
                </Grid>
              </Grid>
              <Grid item xs={1} className={classes.itemEnd}>
                <Box className={classes.calendarIconBox}
                    style={{ borderColor:  openMobileCalendar ? disableGray : tiffany }}>
                  <IconButton onClick={() =>this.setState({ openMobileCalendar: true })} size='small'
                    disabled={openMobileCalendar}
                  >
                    <TodayIcon style={{ fontSize: '24px', color:  openMobileCalendar ? disableGray : navy }}/>
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Toolbar>
          <div className={classes.mobileInfoContainer}>
            <Grid container className={classes.practiceContainer} style={{ padding: 0 }}>
              {isConnected && isArchived &&
                <Grid item xs={12}>
                  <Alert variant="filled" severity="warning">The underlying connected match is now archived</Alert>
                </Grid>
              }
              {isConnected && isDeleted &&
                <Grid item xs={12}>
                  <Alert variant="filled" severity="error">The underlying connected match is now deleted</Alert>
                </Grid>
              }
              { showDates && openMobileCalendar &&
              (<>
                <Grid item xs={12} style={{ borderBottom: `1px solid ${lightGray}` }}>
                  <CalendarContainer
                    isMobile
                    selectedDates={availableDates || []}
                    declinedTrackingDates={declinedTrackingDates || []}
                    notAvailableDates={takenDates?.filter(date => !DateUtilities.dateIn(confirmedDates, date))}
                    confirmedDates={confirmedDates || []}
                    allConfirmedDates={allConfirmedDates || []}
                    pendingDates={pendingDates}
                    initialDate={this.state.calendarInitDisplayDate}
                    readOnly={isDeleted}
                    disabledConnect={showConnect && signedIn && !enableConnectButton}
                    onSubmit={async (selectedDates, notAvailableDates, requestedDates, isChatOnly) => {
                      // Check if the calendar data is latest
                      if (!isEmpty(requestedDates)) {
                        const cData = await this.retrieveCalendarData({
                          typeOfEmployment: matchItem?.jobPosting?.typeOfEmployment || item?.typeOfEmployment,
                          jobId: matchItem?.jobPosting?.id || item?.id,
                        });

                        if (!isEqual(cData?.calendarData, this.state.calendarData)) {
                          this.setState({ openRefreshDialog: true });
                          return;
                        }
                      }

                      if (showConnect) {
                        this.setState({
                          requestedDates: requestedDates.map(d => moment(d).format('YYYY-MM-DD')).join(','),
                          calendarDialog: false,
                          isChatOnly,
                        });
                        this.onClickConnect();
                      } else {
                        history.push(`/messages/${currentItem.id}?dates=${requestedDates.map(d => moment(d).format('YYYY-MM-DD')).join(',')}`);
                      }
                    }}
                    submitButtonText={submitButtonText}
                    chatButtonText={showConnect && !isExternshipJobPosting && !isEmpty(fastpassPractice) && 'Chat'}
                    isFastpass={!isExternshipJobPosting && !isEmpty(fastpassPractice)}
                    calendarData={calendarData}
                  />
                </Grid>
                <Grid item className={classes.closeIconContainer}>
                  <Box className={classes.topDrawerCloseBtn}>
                    <IconButton onClick={() => this.setState({ openMobileCalendar: false })} size='small'>
                      <KeyboardArrowUpIcon style={{ fontSize: '16px'}}/>
                    </IconButton>
                  </Box>
                </Grid>
              </>)}

              <Grid item xs={12}
                className={classes.practiceContainer}
              >
                <Grid item xs={12}>
                  <Typography className={classes.practiceDescriptionTitle}>
                    Description
                  </Typography>
                  <Typography
                    className={classes.practiceDescription}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                </Grid>

                {/* { isBoostedJob && (
                <Grid item xs={12} className={classes.practiceContent}>
                  <div className={classes.boostDescriptionContainer}>
                    <div className={classes.boostBorderContainer}>
                      <Typography className={classes.title}>
                        {BOOST_TITLE}
                      </Typography>
                      <Typography className={classes.boostDescription}>
                        {boostDescription}
                      </Typography>
                    </div>
                  </div>
                </Grid>
                )} */}
                <Grid item xs={12} className={classes.practiceContent} style={{marginTop: '16px'}}>
                  {this.getBadges()}
                </Grid>
                { item?.typeOfEmployment === 'Externship' && rotationTypes && rotationTypes.length > 0 && (<Grid item xs={12}>
                    <Typography className={classes.practiceDescriptionTitle}>
                      Externship Rotation
                    </Typography>
                    <Typography className={classes.practiceDescription}>{rotationTypes?.join(', ')}</Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>    

            { practice
              && (
              <>
              
              <Grid container spacing={1} className={classes.practiceContainer}>
                <Grid item xs={12}>
                  <Typography className={classes.practiceDescriptionTitle} gutterBottom>
                    About The Hospital
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={practiceImages.length === 0 ? 12: 6} className={classes.practiceContent}>
                  <Typography
                    className={classes.practiceDescription}
                    dangerouslySetInnerHTML={{ __html: practice.description || '' }}
                  />
                </Grid>
                { practiceImages.length > 0 && (
                <Grid item xs={12} lg={6} className={classes.carouselContainer}>
                    <CardMedia>
                      <Carousel
                        showArrows
                        showStatus={false}
                        showThumbs={false}
                        infiniteLoop
                        autoPlay
                        centre
                        maxWidth='516px'
                        height='344px'
                      >
                        {
                          practiceImages.map(image => (
                            <img
                              src={image.image}
                              style={{
                                maxHeight: '344px',
                                maxWidth: '516px',
                                objectFit: 'contain',
                              }}
                            />
                          ))
                        }
                      </Carousel>
                    </CardMedia>
                </Grid>
          )}
              </Grid>
              </>
              )
            }
            {
              practice
              && (
              <>
              
              <Grid container className={classes.practiceContainer}>
                <Grid item xs={12} className={classes.mapTitleContainer}>
                  <Typography className={classes.title} style={{ marginLeft: '4px' }}>
                    Location
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.mapTitleContainer}>
                  <ButtonBase
                    target='_blank'
                    href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
                  >
                    <PlaceIcon className={classes.matchIteminfoIcon} />
                    <Typography className={classes.directionLink}>
                      Get Directions <Lens style={{ fontSize: '7px', margin: '0px 4px' }}/> {practice?.location || ''}
                    </Typography>
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} style={{ margin: '8px 16px'}}>
                  <LocationGoogleMap
                    markers={[{ id: practice.id, name: practice.name, position: { lat: practice.lat, lng: practice.lng, } }]}
                    handleMarkerClick={() => {}}
                  />
                </Grid>
              </Grid>
              </>
              )
            }

            {showConnect && (
              <Fab
                variant="extended"
                disabled={signedIn && !enableConnectButton}
                className={classes.connectFabBtn}
                style={{ bottom: '70px' }}
                onClick={() => this.onClickConnect()}
              >
                {signedIn ? 'Connect Me' : 'Sign in to Connect!'}
              </Fab>
            )
            }
            {renderConnectModal({
              ...this.props,
              openConfirmationModal: this.state.openConfirmationModal,
              currentItem: this.state.currentItem,
              onClose: () => this.setState({ openConfirmationModal: false }),
              onOk: () => this.connectToMatch(this.state.currentItem),
            })}

            <RefreshPageDialog
              open={this.state.openRefreshDialog}
              handleClose={() => this.setState({ openRefreshDialog: false })}
            />
        </div>
        </div>
      </div>
    );
  }
}

MatchDetailsPage.propTypes = {
  jobposting: PropTypes.bool,
};

export default withStyles(MatchDetailsPageStyle)(withWidth()(withRouter(MatchDetailsPage)));
