import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import OnboardingItemComponent from './OnboardingItemComponent';
import SimpleSelectableIconComponent from './SimpleSelectableIconComponent';
import { DVM_STUDENT_DEFAULT_SEARCHES } from '../../../config/enums';
import { OnboardingSearchesSimpleSelectableSquareStyle, SearchesComponentStyle } from '../../../css/style';

export class SearchesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Externship: true,
    };
    this.handleState();
  }

  handleState = () => {
    const { criteria, handleStateChange } = this.props;
    const items = [];
    DVM_STUDENT_DEFAULT_SEARCHES.forEach((type) => {
      if (this.state[type.key]) {
        items.push(type.key);
      }
    });
    handleStateChange(items);
  }

  typeClicked(type) {
    const st = {};
    st[type] = !this.state[type];
    this.setState(st, () => this.handleState());
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        className={classes.gridContainer}
        spacing={1}
      >
        {
          DVM_STUDENT_DEFAULT_SEARCHES.map(type => (
            <Grid
              item
              key={type.key}
            >
              <SimpleSelectableIconComponent
                icon={type.key}
                style={OnboardingSearchesSimpleSelectableSquareStyle}
                selectedIcon={`${type.key}-selected`}
                title={type.title.toUpperCase()}
                selected={this.state[type.key]}
                onClick={() => this.typeClicked(type.key)}
              />
            </Grid>
          ))
        }
      </Grid>
    );
  }
}

SearchesComponent.propTypes = {
  criteria: PropTypes.string.isRequired,
  initState: PropTypes.array.isRequired,
  handleStateChange: PropTypes.func.isRequired,
};

SearchesComponent.defaultProps = {
  initState: [],
};
export default withStyles(SearchesComponentStyle)(SearchesComponent);
