import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import OnboardingItemComponent from './OnboardingItemComponent';
import SimpleSelectableIconComponent from './SimpleSelectableIconComponent';
import { ANIMAL_PATIENT_TYPES } from '../../../config/enums';
import { AnimalPatientStyle } from '../../../css/style';

export class AnimalPatientTypeComponent extends OnboardingItemComponent {
  constructor(props) {
    super(props);
    const { initState } = props;
    const st = {};
    initState.forEach(item => st[item.strValue] = true);
    this.state = st;
    this.handleState();
  }

  handleState = () => {
    const { criteria, handleStateChange } = this.props;
    const items = [];
    ANIMAL_PATIENT_TYPES.forEach((type) => {
      if (this.state[type]) {
        items.push({ criteria, strValue: type });
      }
    });
    handleStateChange(items);
  }

  typeClicked(type) {
    const st = {};
    st[type] = !this.state[type];
    this.setState(st, () => this.handleState());
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        className={classes.gridContainer}
        spacing={1}
      >
        {
          ANIMAL_PATIENT_TYPES.map(type => (
            <Grid
              item
              key={type}
            >
              <SimpleSelectableIconComponent
                icon={type}
                selectedIcon={`${type}-selected`}
                title={type.toUpperCase()}
                selected={this.state[type]}
                onClick={() => this.typeClicked(type)}
              />
            </Grid>
          ))
        }
      </Grid>
    );
  }
}

AnimalPatientTypeComponent.propTypes = {
  criteria: PropTypes.string.isRequired,
  initState: PropTypes.array.isRequired,
  handleStateChange: PropTypes.func.isRequired,
};

AnimalPatientTypeComponent.defaultProps = {
  initState: [],
};

export default withStyles(AnimalPatientStyle)(AnimalPatientTypeComponent);
