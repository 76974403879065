import { Button, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { SocialBarStyle } from '../../css/style';
import UsersApi from '../../services/api/UsersApi';
import { IMG_STORAGE_LINK } from '../constants/Constants';

const oauth = (action, provider) => {
  UsersApi.oauth(action, provider);
};
const SocialBar = (props) => {
  const { classes, text, action } = props;

  const getUserData = (response, noAccessCallback) => {
    if (response.authResponse) {
      UsersApi.oauthCallback(`?accessToken=${response.authResponse.accessToken}`, 'facebook', action);
    } else {
      noAccessCallback();
    }
  }
  const loginFB = () => {
    FB.login((response) => {
      getUserData(response, () => console.log('User cancelled login or did not fully authorize.'));
    }, { scope: 'email' });
  };

  const loginFacebook = () => {
    FB.getLoginStatus((response) => {
      getUserData(response, loginFB);
    });
  };

  return (
    <div className={classes.socialBarContainer}>
      <Button
        variant="text"
        onClick={loginFacebook}
        className={`${classes.socialButton} ${classes.facebook}`}
      >
        <Typography className={classes.socialButtonContent}>
          <img className={classes.socialButtonLogo} src={`${IMG_STORAGE_LINK}icons/facebook.svg`} />
          {`${text} Facebook`}
        </Typography>
      </Button>
      <Button
        className={`${classes.socialButton} ${classes.twitter}`}
        onClick={e => oauth(action, 'twitter')}
      >
        <Typography className={classes.socialButtonContent}>
          <img className={classes.socialButtonLogo} src={`${IMG_STORAGE_LINK}icons/twitter.svg`} />
          {`${text} Twitter`}
        </Typography>
      </Button>
      <Button
        className={`${classes.socialButton} ${classes.google}`}
        onClick={e => oauth(action, 'google')}
      >
        <Typography className={classes.socialButtonContent}>
          <img className={classes.offSetLeft} src={`${IMG_STORAGE_LINK}icons/google-plus.svg`} />
          {`${text} Google`}
        </Typography>
      </Button>
      <Button
        className={`${classes.socialButton} ${classes.linkedin}`}
        onClick={e => oauth(action, 'linkedin')}
      >
        <Typography className={classes.socialButtonContent}>
          <img className={`${classes.socialButtonLogo} ${classes.shrinkLogo}`} src={`${IMG_STORAGE_LINK}icons/linkedin-1.svg`} />
          {`${text} LinkedIn`}
        </Typography>
      </Button>
    </div>
  );
};

export default withStyles(SocialBarStyle)(SocialBar);
