import { Grid, Slider, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { CurrentRoleComponentStyle, GeneralSliderStyle } from '../../../css/style';

const PrettoSlider = withStyles(GeneralSliderStyle)(Slider);
const defaultSliderValue = 1;

class CurrentRoleComponent extends Component {
  constructor(props) {
    super(props);
    const {
      info, handleStateChange, initState, keyValue,
    } = props;
    const items = [];
    info.forEach((value, index) => {
      let sliderValue = defaultSliderValue;
      if (initState.length > index) {
        sliderValue = initState[index].numValue;
      }
      items.push({
        criteria: value.criteria,
        numValue: sliderValue,
      });
    });
    this.state = { items };
    handleStateChange && handleStateChange(items);
  }

  componentDidMount() {
    const { enableContinue } = this.props;
    enableContinue && enableContinue();
  }

  handleState(value, criteria) {
    const { handleStateChange, keyValue } = this.props;
    const { items } = this.state;
    const updatedItems = items.map((item) => {
      if (item.criteria === criteria) {
        return {
          ...item,
          numValue: value,
        };
      }
      return item;
    });

    handleStateChange(updatedItems);
    this.setState({
      items: updatedItems,
    });
  }

  render() {
    const { classes, info } = this.props;
    const { items } = this.state;
    return (
      <Grid container spacing={1} justifyContent="center" alignItems="stretch">
        {
          info.map((value, index) => {
            const { criteria } = value;
            return (
              <Grid item key={criteria} xs={12}>
                <Typography color="primary" className={classes.title}>
                  {value.title}
                </Typography>
                <div>
                  <PrettoSlider
                    valueLabelDisplay="auto"
                    aria-label="pretto slider"
                    defaultValue={items[index].numValue}
                    valueLabelDisplay='off'
                    max={20}
                    marks={[{ value: 0, label: '0' }, { value: items[index].numValue, label: `${items[index].numValue}` }]}
                    onChange={(e, value) => this.handleState(value, criteria)}
                  />
                </div>
              </Grid>
            );
          })
        }
      </Grid>
    );
  }
}
CurrentRoleComponent.propTypes = {
  keyValue: PropTypes.string.isRequired,
  info: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleStateChange: PropTypes.func.isRequired,
  enableContinue: PropTypes.func.isRequired,
  initState: PropTypes.array.isRequired,
};

CurrentRoleComponent.defaultProps = {
  initState: [],
};

export default withStyles(CurrentRoleComponentStyle)(withRouter(CurrentRoleComponent));
