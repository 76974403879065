import React, { Component } from 'react';
import {
  Button, Container, Grid, TextField, Typography, withStyles,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Switch from '@material-ui/core/Switch';
import CheckIcon from '@material-ui/icons/Check';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { isEqual, last } from 'lodash';
import { withRouter } from 'react-router';
import { ContactInfoStyle } from '../../css/style';
import ConfirmEmail from '../subcomponents/ConfirmEmail';
import ConfirmMobile from '../subcomponents/ConfirmMobile';
import { pruneMobileInput, handleTrailingSpaces } from '../../utils/Functions';
import { IMG_STORAGE_LINK } from '../constants/Constants';

const data = [
  {
    id: 'firstName',
    title: 'First Name',
  },
  {
    id: 'lastName',
    title: 'Last Name',
  },
  {
    id: 'nickname',
    title: 'Nickname',
    help: `This nickname will be used for participating in forum posts and for any other messaging.
    We don't share personal information (your name, for example) without your express permission (such as when you request to be connected to a practice regarding a job opportunity)`,
  },
  {
    id: 'email',
    title: 'Email Address',
    disabled: true,
  },
  {
    id: 'recipients',
    title: 'CC Email',
    help: 'Add a second email address to be copied on notification messages (in case you don\'t check your professional email account address as often as you\'d like).',
  },
  {
    id: 'mobile',
    title: 'SMS Notifications',
  },
];

class ContactInfoComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  initState() {
    const { user, resources } = this.props;
    const { talentpiis } = resources;
    const talentpii = talentpiis && talentpiis.length && talentpiis[0];
    this.setState({
      firstName: talentpii ? talentpii.firstName : '',
      lastUpdate: talentpii ? talentpii.updatedAt : '',
      lastName: talentpii ? talentpii.lastName : '',
      nickname: user.nickname || '',
      email: user.email,
      mobile: talentpii ? talentpii.mobile : '',
      recipients: user.recipients || '',
    });
  }

  handleChange = (id, event) => {
    let input = event.target.value;
    if (id == 'mobile') {
      input = pruneMobileInput(input);
    }
    this.setState({ [id]: input });
  };

  componentDidMount() {
    const {
      actions, user, resources, childRef,
    } = this.props;
    childRef(this);
    actions.getAllResources(user.token, 'talentpiis');
    this.initState();
  }

  // checks if the talentPII has updated and updates internal state
  componentDidUpdate(prevProps, prevState) {
    const { talentpiis } = this.props.resources;
    if (!isEqual(prevProps.resources.talentpiis, talentpiis)
      && talentpiis && talentpiis.length && talentpiis[0]) {
      const talentpii = this.props.resources.talentpiis[0];
      this.setState({
        lastUpdate: talentpii.updatedAt,
        firstName: talentpii.firstName,
        lastName: talentpii.lastName,
        mobile: talentpii.mobile,
      });
    }
  }

  validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z ]{2,}))$/;
    return re.test(String(email)
      .toLowerCase());
  };

  handleOnClick = () => {
    const { actions, user, resources } = this.props;
    const { talentpiis } = resources;
    const userCreatedAt = user.createdAt;
    const talentpiiId = talentpiis && talentpiis.length && talentpiis[0] && talentpiis[0].id;
    const isMobileSetYet = talentpiis && talentpiis.length && talentpiis[0] && talentpiis[0].isMobileSetYet;
    const {
      email,
      firstName,
      lastName,
      mobile,
      mobileVerificationCode,
      nickname,
      lastUpdate,
      recipients,
    } = this.state;
    const validFirstName = handleTrailingSpaces(firstName);
    const validLastName = handleTrailingSpaces(lastName);
    const updatedUser = {
      email: handleTrailingSpaces(email),
      nickname: handleTrailingSpaces(nickname),
      mobileVerificationCode,
      name: `${validFirstName} ${validLastName}`,
      recipients: handleTrailingSpaces(recipients),
    };
    const updatedTalentPII = {
      firstName: validFirstName,
      lastName: validLastName,
      mobile,
    };
    if (!user.superUser && isMobileSetYet && !mobileVerificationCode && new Date().getTime() - new Date(lastUpdate).getTime() < 5 * 60 * 1000) {
      return actions.setError('You should wait 5 minutes before updating contact info');
    }

    if (recipients && !this.validateEmail(recipients)) {
      return actions.setError('Please input a valid CC email address');
    }

    actions.updateResource('talentpiis', user.token, updatedTalentPII, 'talentpiis', talentpiiId)
      .then(x => actions.updateResource('user', user.token, updatedUser, 'users', user.id))
      .then(x => this.setState({
        mobileVerificationCode: '',
        email: updatedUser.email,
        nickname: updatedUser.nickname,
        recipients: updatedUser.recipients,
      }))
      .then(() => actions.addResource('log-event', user.token, { action: 'talent_profile' }, 'users', 'log-event'));
  };

  renderConfirmEmail = () => {
    const { user, actions } = this.props;
    if (user && !user.isVerified) {
      return (<ConfirmEmail user={user} actions={actions} />);
    }
    return null;
  };

  renderConfirmMobile = () => {
    const { user, actions } = this.props;
    const { needMobileVerificationCode } = user;

    if (needMobileVerificationCode) {
      return (<ConfirmMobile user={user} actions={actions} />);
    }
    return null;
  };

  render() {
    const { classes, user, history } = this.props;
    const { needMobileVerificationCode, isMobileVerified, isVerified, externalId } = user;
    const { help, helpMsg } = this.state;
    return (
      <Container className={classes.container}>
        <Typography className={classes.title}>
          CONTACT INFO
        </Typography>
        <Grid direction="column" container justifyContent="center" alignItems="center">
          <Grid
            className={classes.grid}
            direction="row"
            alignContent="space-between"
            container
            spacing={2}
          >
            {externalId && (<Typography className={classes.resourceAlert}>{'>>>To edit unavailable fields, please update information in Workday.<<<'}</Typography>)}
            {
              data.map((item) => {
                const endAdornment = item.id === 'email' && !isVerified
                  ? <img className={classes.warningIcon} src={`${IMG_STORAGE_LINK}icons/warning.svg`} alt="warning" />
                  : item.id === 'mobile' && needMobileVerificationCode
                    ? <img className={classes.warningIcon} src={`${IMG_STORAGE_LINK}icons/warning.svg`} alt="warning" />
                    : item.id === 'mobile' && isMobileVerified
                      ? <CheckIcon color='primary' />
                      : null;
                const disabled = user.externalId && (item.id === 'firstName' || item.id === 'lastName' || item.id === 'email');
                return (
                  <Grid
                    key={item.id}
                    item
                    xs={12}
                    sm={item.id === 'firstName' || item.id === 'lastName' ? 6 : 12}
                  >
                    <Typography color="secondary" className={classes.typographyWithHelp}>
                      {item.title}
                      {item.help && (
                        <HelpOutlineIcon
                          onClick={e => this.setState({
                            help: e.target,
                            helpMsg: item.help,
                          })}
                        />
                      )}
                    </Typography>
                    <TextField
                      name={item.title}
                      id={item.title}
                      value={this.state[item.id] || ''}
                      className={classes.textField}
                      margin="normal"
                      variant="outlined"
                      disabled={item.disabled && !user.superUser || disabled}
                      InputProps={{
                        classes: {
                          input: classes.textFieldInput,
                        },
                        endAdornment,
                      }}
                      onChange={e => this.handleChange(item.id, e)}
                    />
                    {item.id === 'email' && this.renderConfirmEmail()}
                    {item.id === 'mobile' && this.renderConfirmMobile()}
                  </Grid>
                );
              })
            }
            {needMobileVerificationCode && (
              <Grid item xs={12}>
                <Typography color="secondary" className={classes.typography}>
                  Mobile Verification Code
                </Typography>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  InputProps={{
                    classes: {
                      input: classes.textFieldInput,
                    },
                  }}
                  onChange={e => this.handleChange('mobileVerificationCode', e)}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                className={classes.buttonSecondary}
                onClick={e => this.handleOnClick()}
              >
                SAVE CHANGES
              </Button>
            </Grid>
            <Popover
              open={!!help}
              anchorEl={help}
              onClose={e => this.setState({ help: null })}
            >
              <Paper className={classes.helpPopover}>
                <Typography className={classes.helpPopoverText}>{helpMsg}</Typography>
              </Paper>
            </Popover>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(ContactInfoStyle)(withRouter(ContactInfoComponent));
