import React from 'react';
import {
  TextField,
  IconButton,
} from '@material-ui/core/';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';

export default function MatchesSearchBar({
  value,
  onChange,
  placeholder,
  onClickClearIcon,
  className,
}) {
  return (
    <TextField
      value={value}
      onChange={event => onChange(event.target.value)}
      variant="outlined"
      //style={{ width: '372px', height: '36px', }}
      className={className}
      placeholder={placeholder}
      InputProps={{
        startAdornment: (
          <img
            src='https://storage.googleapis.com/jobs-unleashed-common/icon.ico'
            style={{ width: 24, height: 24, transform: 'scaleX(-1) translateX(8px)' }}
          />
        ),
        endAdornment: (
          <IconButton
            style={{ visibility: value ? 'visible' : 'hidden', padding: 8 }}
            onClick={onClickClearIcon}
          >
            <ClearIcon style={{ fontSize: 16 }} />
          </IconButton>
        ),
        style: { height: '36px' }
      }}
    />
  );
}
