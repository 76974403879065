import handleResponse from '../ResponseHandler';

const baseUrl = window.api_host;

export function generatePath(resourcePath) {
  return resourcePath.join('/');
}

export function generateHeader(token) {
  const apiHeader = {};
  apiHeader.Authorization = `${token}`;
  apiHeader['Content-Type'] = 'application/json';
  return apiHeader;
}

export default class ResourcesAPI {
  static addResource(apiToken, resource, resourcePath) {
    const path = baseUrl + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'POST',
        headers: generateHeader(apiToken),
        body: JSON.stringify(resource),
      })
    );
    return handleResponse(request);
  }

  static deleteResource(apiToken, resourcePath) {
    const path = baseUrl + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'DELETE',
        headers: generateHeader(apiToken),
      })
    );
    return handleResponse(request, true);
  }

  static updateResource(apiToken, resource, resourcePath) {
    const path = baseUrl + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'PUT',
        headers: generateHeader(apiToken),
        body: JSON.stringify(resource),
      })
    );
    return handleResponse(request);
  }

  static getAllResources(apiToken, resourcePath) {
    const path = baseUrl + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'GET',
        headers: generateHeader(apiToken),
      })
    );
    return handleResponse(request);
  }

  static getResource(apiToken, resourcePath) {
    const path = baseUrl + generatePath(resourcePath);
    const request = (
      new Request(path, {
        method: 'GET',
        headers: generateHeader(apiToken),
      })
    );
    return handleResponse(request);
  }
}
