import { TextField } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography } from '@material-ui/core/index';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { OnboardingPageStyle } from '../../../css/style';
import OnboardingItemComponent from './OnboardingItemComponent';

const notListed = 'not-listed';

class SpecialityCollegeItemBoardInfoComponent extends OnboardingItemComponent {
  constructor(props) {
    super(props);
    const { initState } = props;
    const st = {};
    initState.map((item) => {
      st[`${item.strValue}-${item.str2Value}`] = String(item.boolValue || false);
    });
    this.state = st;
    this.handleState();
  }

  componentDidMount() {
    const { enableContinue } = this.props;
    // enableContinue && enableContinue();
  }

  handleState() {
    const {
      setOnboardingItems, specialities, enableContinue, disableContinue,
    } = this.props;
    const collegesFilled = new Map();

    const items = specialities.map((collegeSpeciality) => {
      const items = collegeSpeciality.split('-');
      let college = items[0];
      const speciality = items.reverse()[0];
      collegesFilled.set(collegeSpeciality, false);
      if (collegeSpeciality.indexOf(notListed) >= 0) {
        college = this.state[`college${collegeSpeciality}`];
      }
      if (!college) {
        return null;
      }
      collegesFilled.set(collegeSpeciality, true);
      return {
        criteria: 'speciality',
        strValue: college,
        str2Value: speciality,
        boolValue: this.state[collegeSpeciality] === 'true',
      };
    })
      .filter(item => item);
    setOnboardingItems && setOnboardingItems(items);
    const allCollegesHasValues = ![...collegesFilled.values()]
      .filter(x => !x).length;

    if (allCollegesHasValues) {
      enableContinue();
    } else {
      disableContinue();
    }
  }

  typeChanged(type, value) {
    const st = {};
    st[type] = value;
    this.setState(st, () => this.handleState());
  }

  render() {
    const { specialities, classes } = this.props;
    return (
      <div>
        {
          specialities.map((collegeSpeciality) => {
            const speciality = collegeSpeciality.split('-')
              .reverse()[0];
            const askForCollege = collegeSpeciality.indexOf(notListed) >= 0;
            return (
              <div key={collegeSpeciality}>
                <Typography
                  color="secondary"
                  className={classes.title}
                >
                  {speciality.toUpperCase()}
                </Typography>
                <RadioGroup
                  style={{ justifyContent: 'center' }}
                  aria-label="position"
                  name="position"
                  value={this.state[collegeSpeciality] || 'false'}
                  onChange={e => this.typeChanged(collegeSpeciality, e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="true"
                    control={<Radio color="primary" />}
                    label="Board Certified"
                  />
                  <FormControlLabel
                    value="false"
                    control={<Radio color="primary" />}
                    label="Practice Limited"
                  />
                </RadioGroup>
                {askForCollege && (
                  <TextField
                    placeholder="Certifying Organization"
                    className={classes.textField}
                    fullWidth
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      classes: {
                        input: classes.textFieldInput,
                      },
                    }}
                    onChange={e => this.typeChanged(`college${collegeSpeciality}`, e.target.value)}
                  />
                )}
              </div>
            );
          })}
      </div>
    );
  }
}

SpecialityCollegeItemBoardInfoComponent.propTypes = {
  setOnboardingItems: PropTypes.func.isRequired,
  specialities: PropTypes.array.isRequired,
  initState: PropTypes.array.isRequired,
};

SpecialityCollegeItemBoardInfoComponent.defaultProps = {
  initState: [],
};

export default withStyles(OnboardingPageStyle)(SpecialityCollegeItemBoardInfoComponent);
