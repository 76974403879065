import { Button, Icon, List, ListItem } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { DrawerViewStyle } from '../../../css/style';
import { pageInfo, userMenu, IMG_STORAGE_LINK } from '../../constants/Constants';

const closeIcon = `${IMG_STORAGE_LINK}ic-close.svg`;

class DrawerView extends Component {
  onClickSelectedItem = (page) => {
    const { user, history } = this.props;
    const isSignUpStudentUser = localStorage.getItem(`student_user_${user?.id}`) === 'true';
    if (page.value) {
      if(page.key === 'matches' && (user?.isStudent || isSignUpStudentUser)) {
        history.push('/externhome');
      } else {
        history.push(page.value);
      }
      this.props.onClickDrawerOpenAndClose();
      try {
        window.scrollTo(0, 0);
        document.getElementById('main')
          .scrollTo(0, 0);
      } catch (e) {
        console.log(e);
      }
    } else {
      window.location = page.redirect;
    }
  };

  renderDrawerHead = () => {
    const { classes, onClickDrawerOpenAndClose, history } = this.props;
    let isExtern = false;

    if (this.props.location?.pathname?.includes('externlogin') || this.props.location?.pathname?.includes('extern-signup')) {
      isExtern = true;
    }

    return (
      <div className={classes.drawerHead}>
        <Button className={classes.drawerHeadCloseButton} onClick={onClickDrawerOpenAndClose}>
          <img src={closeIcon} alt="close navigation menu" />
        </Button>
        <Typography className={classes.drawerHeadCreateAccount}>
          To find your perfect match,
          <br />
          create an account below and
          <br />
          join our community!
          <Button
            className={classes.drawerHeadCreateAccountButton}
            onClick={() => {
              onClickDrawerOpenAndClose();
              if (isExtern) {
                this.props.history.push('/extern-signup')
              } else {
                window.location = 'https://jobs.jobvite.com/pathwaypartners/job/ohyCnfwy';
              }
            }}
          >
            Create An Account
          </Button>
        </Typography>
        <div className={classes.drawerHeadSignIn}>
          <hr className={classes.drawerHeadHorizontalRule} />
          <Typography className={classes.drawerHeadMemberSignIn}>
            Already a Member?
            <Button
              className={classes.drawerHeadMemberSignInButton}
              onClick={() => {
                if (isExtern) {
                  history.push('/externlogin');
                } else {
                  history.push('/login');
                }
                onClickDrawerOpenAndClose();
              }}
            >
              Sign In
            </Button>
          </Typography>
        </div>
      </div>
    );
  };

  renderDrawerHeadLoggedIn = () => {
    const { classes, onClickDrawerOpenAndClose, isTemporaryUser } = this.props;
    return (
      <div className={isTemporaryUser ? classes.drawerNoHead : classes.drawerHead}>
        <Button className={classes.drawerHeadCloseButton} onClick={onClickDrawerOpenAndClose}>
          <img src={closeIcon} alt="close navigation menu" />
        </Button>
        {!isTemporaryUser && (
          <List className={classes.list}>
            {userMenu.map(page => (
              <ListItem key={page.key} className={classes.listItem} disableGutters>
                <Button
                  className={classes.drawerHeaderButton}
                  onClick={() => this.onClickSelectedItem(page)}
                >
                  {page.title}
                </Button>
              </ListItem>
            ))}
          </List>
        )}
      </div>
    );
  };

  renderDrawerFooter() {
    const { classes, user, onOpenReleaseNoteDialog } = this.props;
    return (
      <div>
        { user && user.releaseNote && user.releaseNote.id && (
          <div onClick={onOpenReleaseNoteDialog} className={classes.drawerVersion}>
            Current Version: {user.releaseNote.version}
            {user && user.releaseNote && !user.isReadReleaseNote && (
              <Icon className={classes.animatedIcon}>
                lens
              </Icon>
            )}
          </div>
        )}
        <div className={classes.drawerFooter}>
          <Link className={classes.drawerFooterLink} href="https://google.com" to="/">Terms & Conditions</Link>
          <Link className={classes.drawerFooterLink} href="https://google.com" to="/">Privacy Policy</Link>
        </div>
      </div>
    );
  }

  renderDrawerBody = () => {
    const { classes, user, isTemporaryUser } = this.props;
    return (
      <div className={classes.drawerBody}>
        <List className={classes.list}>
          {pageInfo.map(page => (!page.hideInMenu && ((!!user && !page.hideInDrawer) || (!user && !page.hideWhenLogin))) && (
            <ListItem key={page.key} className={classes.listItem} disableGutters>
              <Button
                className={classes.drawerButton}
                onClick={() => this.onClickSelectedItem(page)}
              >
                {page.title}
              </Button>
            </ListItem>
          ))}
        </List>
        {!isTemporaryUser && this.renderUserInfo(classes)}
      </div>
    );
  };

  renderUserInfo = () => {
    const {
      classes, user, actions, onClickDrawerOpenAndClose,
    } = this.props;
    return user && (
      <div className={classes.drawerUserInfo}>
        <Typography className={classes.drawerUserInfoNickName}>{user.name}</Typography>
        <Link
          color="primary"
          className={classes.drawerSignoutLink}
          to='/login'
          onClick={(e) => {
            actions.logoutUser();
            onClickDrawerOpenAndClose();
          }}
        >
          sign out
        </Link>
      </div>
    );
  };

  render() {
    const {
      classes, open, onClickDrawerOpenAndClose, user,
    } = this.props;

    return (
      <Drawer
        variant="temporary"
        open={open}
        onClose={onClickDrawerOpenAndClose}
        transitionDuration={{
          enter: 400,
          exit: 400,
        }}
      >
        <div className={classes.drawerInner}>
          {!user && this.renderDrawerHead(classes, onClickDrawerOpenAndClose)}
          {user && this.renderDrawerHeadLoggedIn(classes, onClickDrawerOpenAndClose)}
          {this.renderDrawerBody(classes)}
          {this.renderDrawerFooter(classes)}
        </div>
      </Drawer>
    );
  }
}

DrawerView.propTypes = {
  open: PropTypes.bool.isRequired,
  onClickDrawerOpenAndClose: PropTypes.func.isRequired,
};

export default withStyles(DrawerViewStyle)(withRouter(DrawerView));
