import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CertificationTypeStyle, SimpleSelectableSquareNoIconStyle } from '../../../css/style';
import { CERTIFICATIONS } from '../../../config/enums';
import OnboardingItemComponent from './OnboardingItemComponent';
import SimpleSelectableIconComponent from './SimpleSelectableIconComponent';

class CertificationTypeComponent extends OnboardingItemComponent {
  constructor(props) {
    super(props);
    const { initState } = props;
    const st = {};
    initState.forEach(optionState => st[optionState.strValue] = true);
    this.state = st;
    this.handleState();
  }

  /**
   * This handler adds the certification
   * to the the onboarding items for its given step
   */
  handleState = () => {
    const { handleStateChange, criteria, enableContinue } = this.props;
    const items = [];
    CERTIFICATIONS.forEach((cert) => {
      if (this.state[cert.key]) {
        items.push({ criteria, strValue: cert.key });
      }
    });
    handleStateChange(items);
  }

  /**
   * @param {Certification} type
   * Saves the selected certification to local state
   * Calls handleState to write changes to onboarding flow state
   */
  typeClicked(type) {
    const st = {};
    st[type] = !this.state[type];
    this.setState(st, () => this.handleState());
  }

  render() {
    const { classes } = this.props;
    return (
      <Grid
        container
        spacing={2}
        className={classes.gridContainer}
      >
        {
        CERTIFICATIONS.map(cert => (
          <Grid item xs={4} key={cert.key}>
            <SimpleSelectableIconComponent
              style={SimpleSelectableSquareNoIconStyle}
              title={cert.key !== 'CT / UT No Cert' ? cert.key : cert.title}
              selected={this.state[cert.key]}
              onClick={() => this.typeClicked(cert.key)}
            />
          </Grid>
        ))
        }
      </Grid>
    );
  }
}

CertificationTypeComponent.propTypes = {
  criteria: PropTypes.string.isRequired,
  initState: PropTypes.array.isRequired,
  handleStateChange: PropTypes.func.isRequired,
};

CertificationTypeComponent.defaultProps = {
  initState: [],
};


export default withStyles(CertificationTypeStyle)(CertificationTypeComponent);
