import { Grid, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { NON_CLINICAL_CURRENT_ROLE_CRITERIA } from '../../config/enums';
import { NonClinicalFlowComponentStyle } from '../../css/style';
import AboutYouComponent from './itemTypes/AboutYouComponent';
import CurrentRoleComponent from './itemTypes/CurrentRoleComponent';
import OnboardingFlowComponent from './OnboardingFlowComponent';

class NonClinicalOnboarding extends OnboardingFlowComponent {
  constructor(props) {
    super(props, 1);
    const st = this.state;
    st.step0 = [false, true];
    this.state = st;
  }

  renderStep0() {
    const { classes } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <Typography color="secondary" className={classes.title}>
          ABOUT YOU
        </Typography>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} className={classes.gridItem}>
            <AboutYouComponent
              criteria='job-category'
              initState={this.getOnboardingItems('job-category')}
              handleStateChange={(items, index) => this.handleStateChange(items, 'job-category', 0, index)}
            />
          </Grid>
          <Grid item xs={12} className={classes.gridItem}>
            <CurrentRoleComponent
              keyValue="non-clinical-current-role"
              info={NON_CLINICAL_CURRENT_ROLE_CRITERIA}
              enableContinue={() => {}}
              initState={this.getOnboardingItems('non-clinical-current-role')}
              handleStateChange={items => this.handleStateChange(items, 'non-clinical-current-role', 0, 1)}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(NonClinicalFlowComponentStyle)(withRouter(NonClinicalOnboarding));
