import { isEmpty, union } from 'lodash';
import {
  Button, Container, Grid, Typography, withStyles,
} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { ExperienceDetailsPageStyle } from '../../css/style';
import {
  EXPERIENCE_LEVELS_CONFIG,
  EXPERIENCE_TYPE_CONFIG,
  EXPERIENCE_LEVEL_VET_TECH_SPECIAL,
  TALENT_ROLES_CONFIG,
  TALENT_PROFILE_ROLE_MAPPING,
} from '../constants/Constants';
import { JOB_CATEGORIES } from '../../config/enums';

class ExperienceDetailsPage extends Component {
  getTalentExperience() {
    const {
      talentprofileitems,
      talents,
      criterias,
      vetSchools,
    } = this.props;

    if (!talentprofileitems || !talents || !criterias) {
      return [];
    }
    const talent = talents[0];
    let {
      type,
      isStudent,
    } = talent;
    if (!type) type = 'vet-tech-or-assistant';
    let typeTitle = type.replace(/-/g, ' ');

    if (type.toLowerCase() === 'vet-tech-or-assistant') {
      typeTitle = 'Vet Tech / Vet Assistant';
    }

    if (type.toLowerCase() === 'dvm-doctor-of-veterinary-medicine') {
      typeTitle = 'DVM';
    }
    if (isStudent) {
      if (type.toLowerCase() === 'dvm-doctor-of-veterinary-medicine') {
        typeTitle = 'Veterinary Student Extern';
      } else {
        typeTitle = `${typeTitle} Student Extern`;
      }
    }

    const newTalentProfileItems = talentprofileitems.map((profileItem) => {
      const criteria = criterias.find(criteria => criteria.id === profileItem.criteria_id);
      return {
        ...profileItem,
        criteria,
      };
    });
    const items = newTalentProfileItems.reduce((g, i) => {
      const name = i.criteria && i.criteria.name || '-';
      g[name] = g[name] || [];
      let val = i.strValue === 'CT / UT No Cert' ? 'Other / None' : i.strValue;
      if (i.str2Value && i.str2Value !== i.strValue) {
        val = `${i.str2Value} (${i.strValue})`;
      }
      if (i.numValue != null) {
        val = i.numValue;
      }
      if (name == 'job-category') {
        const jobCat = i && JOB_CATEGORIES.filter(item => item.key == i.strValue);
        val = jobCat.length > 0 ? jobCat[0].category : i.strValue;
      }

      if (name === 'extra-roles') {
        val = this.setExtraRoles(i);
      }

      g[name].push(val);
      return g;
    }, {});

    let extraRoles = [];
    if (!isEmpty(items['extra-roles'])) {
      const extraRoleItems = items['extra-roles'][0].map((item) => {
        const subTypeObj = TALENT_ROLES_CONFIG[item.talentType];
        if (subTypeObj) {
          const subRole = TALENT_PROFILE_ROLE_MAPPING[subTypeObj.dataSubKey]?.items?.find(i => (i.key === item[subTypeObj.subKey] || item[subTypeObj.subKey]?.includes(i.key)));
          return item.talentType === 'specialist-veterinarian' ? `Specialist (${subRole?.title})` :  (subRole?.title || subRole?.category);
        }
      });
      extraRoles = [{
        key: 'Additional role(s)',
        value: !isEmpty(extraRoleItems) ? extraRoleItems.join(', ') : '-',
      }];
    }

    const itemKeys = Object.keys(EXPERIENCE_TYPE_CONFIG)
      .filter(item => EXPERIENCE_LEVELS_CONFIG[type][item] || isStudent && EXPERIENCE_TYPE_CONFIG[item].isStudent);

    const talentItems = [{
      key: 'Employee type',
      value: typeTitle,
    }, ...itemKeys
      .filter(item => items[item])
      .map((k) => {
        // Display School Name instead of School ID
        if (k === 'school-id') {
          return {
            key: 'School name',
            value: items[k].map((v) => {
              const schoolObj = vetSchools && vetSchools.find(s => s.id == v);
              return schoolObj ? schoolObj.name : 'Other';
            }),
          };
        } else if (k === 'school-name') {
          return {};
        } else {
          return {
            key: EXPERIENCE_TYPE_CONFIG[k].title,
            value: items[k].map((v) => {
              if (k == 'sub-category' && /gp-|er-/g.test(v)) {
                const strValues = v.split('-');
                return `${strValues[0].toUpperCase()}-${strValues[1]}`;
              }
              return v;
            })
              .join(', '),
          };
        }
      }),
    ];
    let experienceItem = talentItems;

    if (isStudent && this.studentYearDetail(criterias, talentprofileitems) !== 0) {
      experienceItem.splice(5, 0, { key: 'Student Year', value: this.studentYearDetail(criterias, talentprofileitems) });
    }

    if (talentItems.filter(item => item.key === 'Sub-category' && item.value === 'vet-assistant').length > 0) {
      const newKeys = Object.keys(EXPERIENCE_LEVEL_VET_TECH_SPECIAL)
        .map(item => EXPERIENCE_TYPE_CONFIG[item].title);
      experienceItem = talentItems.filter(item => !newKeys.includes(item.key));
    }
    return experienceItem.concat(extraRoles);
  }

  setExtraRoles = (extraRolesCri) => {
    if (isEmpty(extraRolesCri)) return [];
    const extraRolesDataItems = JSON.parse(extraRolesCri.longTextValue);
    let extraRoles = [];
    !isEmpty(extraRolesDataItems) && extraRolesDataItems.forEach((item, index) => {
      if (!isEmpty(item.profileItems)) {
        const exSpecialty = item.profileItems.find(i => i.criteria === 'speciality') || {};
        const exJobCategory = item.profileItems.find(i => i.criteria === 'job-category') || {};
        const exSubCategory = item.profileItems.find(i => i.criteria === 'sub-category') || {};
        const exVetSubCategory = item.profileItems.find(i => i.criteria === 'vet-sub-category') || {};
        extraRoles.push({
          key: index + 1,
          specialty: exSpecialty.str2Value,
          jobCategory: exJobCategory.strValue,
          subCategory: exSubCategory.strValue,
          vetSubCategory: exVetSubCategory.strValue,
          talentType: item.talentType,
        });
      } else {
        extraRoles.push({
          key: index + 1,
          talentType: item.talentType,
        });
      }
    });
    return extraRoles;
  };

  studentYearDetail(criterias, talentprofileitems) {
    let graduationDate = criterias.find(x => x.name === 'graduation-date') && (criterias.find(x => x.name === 'graduation-date')).id;
    if (graduationDate) {
      graduationDate = talentprofileitems.find(x => x.criteria_id === graduationDate);
      graduationDate = graduationDate && graduationDate.strValue;
      graduationDate = graduationDate && graduationDate.split('/');
      const graduationYear = graduationDate && +graduationDate[0];
      const currentDate = new Date();
      const yearDiff = graduationYear - currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      if (yearDiff === 0) return 'Fourth-year';
      else if (yearDiff === 1) return this.getStudentYear(currentMonth, 'Third-year', 'Fourth-year');
      else if (yearDiff === 2) return this.getStudentYear(currentMonth, 'Second-year', 'Third-year');
      else if (yearDiff === 3) return this.getStudentYear(currentMonth, 'First-year', 'Second-year');
      else if (yearDiff === 4) return this.getStudentYear(currentMonth, 0, 'First-year');
    }
    return 0;
  }

  getStudentYear(currentMonth, condition1, condition2) {
    return currentMonth < 8 ? condition1 : condition2;
  }

  render() {
    const {
      classes,
      history,
      actions,
      user,
      talents,
    } = this.props;

    const talentInfo = talents && talents[0];
    const isStudent = talentInfo?.isStudent;

    return (
      <Container className={classes.container}>
        <Typography className={classes.title}>
          {'PROFILE DETAILS'}
        </Typography>
        <div className={classes.newExperienceDetails}>
          {this.getTalentExperience()
            .map(exp => !isEmpty(exp) && (
              <div key={exp.key} className={classes.experienceItem}>
                <Typography className={classes.newExperienceItemTitle}>
                  {`${exp.key}:`}
                </Typography>
                <Typography className={classes.newExperienceItemValue}>{exp.value || '-'}</Typography>
              </div>
            ))}
          <Grid
            direction="row"
            alignContent="space-between"
            container
            spacing={2}
          >
            <Grid item xs={12}>
              <Button
                color="secondary"
                variant="contained"
                className={classes.buttonSecondary}
                onClick={(e) => {
                  if (user.externalId) {
                    history.push('/onboarding');
                  } else {
                    history.push({
                      pathname: '/talent',
                      state: { from: isStudent ? "isEditExternUser" : "" }
                    });
                  }
                  actions.addResource('log-event', user.token, { action: 'talent_profile' }, 'users', 'log-event');
                }}
              >
                EDIT EXPERIENCE DETAILS
              </Button>
            </Grid>
          </Grid>
        </div>
      </Container>
    );
  }
}

ExperienceDetailsPage.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  criterias: PropTypes.arrayOf(PropTypes.object),
  talents: PropTypes.arrayOf(PropTypes.object),
  talentprofileitems: PropTypes.arrayOf(PropTypes.object),
};

ExperienceDetailsPage.defaultProps = {
  criterias: null,
  talents: null,
  talentprofileitems: null,
};

export default withStyles(ExperienceDetailsPageStyle)(withRouter(ExperienceDetailsPage));
