import {
  Button, Container, Grid, TextField,
  Typography, withStyles, Tooltip,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React, { Component } from 'react';
import { ABOUT_YOU_CRITERIA } from '../../config/enums';
import { AboutYouPageStyle } from '../../css/style';

class AboutYouPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { resources } = this.props;
    const talent = resources.talents && resources.talents[0];
    if (talent) {
      this.setState({
        about: talent.about,
        brief: talent.brief,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { resources } = nextProps;
    const { talents } = resources;
    if (talents && talents.length && !prevState.id) {
      return talents[0];
    }
    return null;
  }

  handleChange = (criteria, event) => {
    this.setState({
      [criteria]: event.target.value,
    });
  };

  handleOnClick = () => {
    const { user, actions } = this.props;
    actions.updateResource('talents', user.token, this.state, 'talents', user.talent_id)
      .then(() => actions.addResource('log-event', user.token, { action: 'talent_profile' }, 'users', 'log-event'));
  };

  render() {
    const { classes } = this.props;
    return (
      <Container className={classes.container}>
        <Typography className={classes.title}>
          MORE ABOUT YOU
        </Typography>
        <Grid direction="column" container justifyContent="center" alignItems="center">
          <Grid
            className={classes.grid}
            direction="row"
            alignContent="space-between"
            container
            spacing={2}
          >
            {
              ABOUT_YOU_CRITERIA.map(item => (
                <Grid key={item.criteria} item xs={12}>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography color="secondary" className={classes.typography}>
                      {item.title}
                    </Typography>
                    <Tooltip title={item.information} placement='bottom-end' classes={{ tooltip: classes.tooltip }}>
                      <InfoIcon color='secondary' />
                    </Tooltip>
                  </div>
                  <TextField
                    name={item.title}
                    value={this.state[item.criteria] || ''}
                    multiline
                    minRows={4}
                    maxRows={4}
                    className={classes.textField}
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      classes: {
                        input: classes.textFieldInput,
                      },
                    }}
                    onChange={e => this.handleChange(item.criteria, e)}
                  />
                </Grid>
              ))
            }
            <Grid item xs={12}>
              <Button
                color="primary"
                className={classes.redButton}
                variant="contained"
                style={{ width: '100%' }}
                onClick={() => this.handleOnClick()}
              >
                SAVE CHANGES
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default withStyles(AboutYouPageStyle)(AboutYouPage);
