import React, { Component } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { loadingStyle } from '../../css/style';

const styles = loadingStyle();

class LoadingBar extends React.Component {
  render() {
    const { size = 80, noText = false } = this.props;
    return (
        <div style={styles.loadingContainer}>
            <CircularProgress size={size} />
            {!noText && (<Typography style={styles.loadingText}>Loading</Typography>)}
        </div>
    );
  }
}

export default LoadingBar;