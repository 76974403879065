import React, { Component } from 'react';
import { withRouter } from 'react-router';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  withStyles,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import RefreshIcon from '@material-ui/icons/Refresh';
import PropTypes from 'prop-types';
import { DialogStyles } from '../../css/dialogStyle';

class RefreshPageDialog extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const {
      classes,
      open = false,
      handleClose = () => {},
    } = this.props;
    return (
      <Dialog
        maxWidth="md"
        PaperProps={{
          style: {
            padding: '24px 4px',
            minWidth: '310px',
          },
        }}
        open={open}
        onClose={() => handleClose()}
      >
        <DialogTitle className={classes.dialogContent}>
          <div className={classes.titleContainer}>
            <RefreshIcon className={classes.refreshIcon}/>
          </div>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => handleClose()}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Typography className={classes.dialogDescription} style={{ fontSize: '16px' }}>
            The calendar information is not up to date, please refresh the page and try again.
          </Typography>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            key="refresh_btn"
            className={classes.refreshButton}
            onClick={() => window.location.reload()}
          >
            REFRESH
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}


RefreshPageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default withStyles(DialogStyles)(withRouter(RefreshPageDialog));
