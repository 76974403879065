import InputAdornment from '@material-ui/core/InputAdornment';
import React, { Fragment } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { TextField, IconButton, Icon, Paper } from '@material-ui/core';
import { IMG_STORAGE_LINK } from '../../constants/Constants';
import { orange } from '../../../css/style';

const styles = theme => ({
  container: {
    backgroundColor: '#E1E5EA',
    width: '100%',
    padding: '16px 20px',
  },
  inputBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'space-between',
    backgroundColor: '#ffffff',
    borderRadius: '25px',
    padding: 0,
    boxShadow: '0 4px 4px 0 rgba(0, 0, 0, 0.25)'
  },
  textInputField: {
    width: '90%',
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
    height: '50px',
    borderRadius: '25px',
    backgroundColor: 'transparent',
  },
  sendButton: {
    backgroundColor: 'transparent',
    color: orange,
    padding: '5px',
    height: '50px',
    width: '50px',
    '&:hover': {
      color: '#d78c00',
    },
  },
});

const MessageInputBox = (props) => {
  const {
    classes,
    onClickSendButton,
    onChangeTextField,
    textFieldValue,
    calendarMessage,
    disabled,
  } = props;
  return (
    <div className={classes.container}>
      <div className={classes.inputBoxContainer}>
        <TextField
          placeholder="Type a message"
          className={classes.textInputField}
          InputProps={{
            disableUnderline: true,
            startAdornment: calendarMessage && (
              <InputAdornment style={{ marginRight: 0, marginLeft: 8 }} position="start">
                <img style={{ width: 20 }} src={`${IMG_STORAGE_LINK}calendar.png`} />
              </InputAdornment>
            ),
          }}
          inputProps={{
            style: {
              padding: '15px',
              paddingLeft: calendarMessage ? 5 : 15,
              color: calendarMessage ? orange : 'rgba(0,0,0,0.85)',
            },
          }}
          value={textFieldValue}
          onChange={onChangeTextField}
          onKeyPress={event => (event.key === 'Enter') && onClickSendButton()}
          disabled={disabled}
        />
        <IconButton
          className={classes.sendButton}
          disabled={disabled}
          style={{ color: disabled ? '#b3b3b3' : '#364D96' }}
          onClick={e => onClickSendButton()}
        >
          <Icon>send</Icon>
        </IconButton>
      </div>
    </div>
  );
};

export default withStyles(styles)(MessageInputBox);
