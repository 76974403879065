import { Container, Grid, Typography, withStyles } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { IMG_STORAGE_LINK } from '../../constants/Constants';
import { UserInfoStyle } from '../../../css/style';
import BackButton from '../../subcomponents/BackButton';
import EmailBar from '../../subcomponents/EmailBar';
import { handleTrailingSpaces } from '../../../utils/Functions';

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
    };
  }

  handleEmailSignUpClick = (firstName, lastName, location) => {
    const { actions, history, user } = this.props;
    const regex = /^[a-zA-Z ]+$/g;
    let errorMsg = [];

    if (firstName.length === 0 && lastName.length === 0) {
      errorMsg.push('Please enter your first and last name');
    } else if (firstName.length === 0) {
      errorMsg.push('Please enter your first name');
    } else if (lastName.length === 0) {
      errorMsg.push('Please enter your last name');
    } else if (!(firstName.match(regex) && lastName.match(regex))) {
      errorMsg.push('Name should only contain letters');
    }

    if (this.props.location?.state?.from !== "isExternUser" && location.address.length === 0) {
      errorMsg.push('Please enter your location');
    }

    if (errorMsg.length > 0) {
      actions.setError(errorMsg.join(', '));
      return;
    }

    const validFirstName = handleTrailingSpaces(firstName);
    const validLastName = handleTrailingSpaces(lastName);
    actions.addResource('talentpiis', user.token, {
      firstName: validFirstName,
      lastName: validLastName,
    }, 'talentpiis')
      .then((e) => {
        actions.updateResource('user', user.token, { nickname: validFirstName }, 'users', user.id);
        sessionStorage.setItem('firstWBSearchLocation', JSON.stringify(location));
        if (this.props.location?.state?.from === "isExternUser") {
          history.push({
            pathname: "/talent",
            state: { from: "isExternUser" }
          })
        } else {
          history.push('/talent');
        }
      });
  }

  render() {
    const { classes, resources, actions, history } = this.props;
    const { talentpiis } = resources;
  
    return (
      <Container maxWidth='lg' className={classes.userInfoGridContainer}>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          direction="row-reverse"
          className={classes.userInfoGridContainer}
        >
          <Grid
            item
            xs={12}
            lg={6}
            container
            justifyContent='center'
            className={classes.emailBarContainer}
          >
            <Typography className={classes.infoTitle}>
              HI THERE, WHAT'S YOUR NAME?
            </Typography>
            <EmailBar
              className={classes.emailBar}
              emailPlaceHolder="Your Name"
              passwordPlaceHolder="Your Last Name"
              buttonText="Continue"
              onClick={this.handleEmailSignUpClick}
              buttonColor="secondary"
              isNameBar
              locationField
              defaultEmail={(talentpiis && talentpiis[talentpiis.length - 1]) ? talentpiis[talentpiis.length - 1].firstName : ''}
              defaultPassword={(talentpiis && talentpiis[talentpiis.length - 1]) ? talentpiis[talentpiis.length - 1].lastName : ''}
              isExtern={this.props.location?.state?.from === "isExternUser"}
            />
          </Grid>
          <div className={classes.divider}>
            {/* <span className={classes.dividerText}>
              or
            </span> */}
          </div>
          <Grid item lg={6} container className={classes.socialBarContainer}>
            <img src={`${IMG_STORAGE_LINK}banner-vertical.png`} />
            {/* <Hidden lgUp>
              <Typography className={classes.socialMediaTitle}>
                Social Media
              </Typography>
            </Hidden>
            <SocialBar
              text="Connect your"
            /> */}
          </Grid>
          <Grid item xs={10}>
            <BackButton onClick={() => {
              this.setState({ firstName: '', lastName: '' });
              actions.logoutUser();
              history.goBack();
            }} />
          </Grid>
        </Grid>
      </Container>
    );
  }
}

UserInfo.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(UserInfoStyle)(withRouter(UserInfo));
