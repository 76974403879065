import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  Icon,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import isEmpty from 'lodash/isEmpty';
import { IMG_STORAGE_LINK } from '../../constants/Constants';
import { forgotPasswordDialogs } from '../../../css/style';

const textFieldInputs = [
  {
    id: 'input1', autoFocus: true, position: 1,
  },
  {
    id: 'input2', autoFocus: false, position: 2,
  },
  {
    id: 'input3', autoFocus: false, position: 3,
  },
  {
    id: 'input4', autoFocus: false, position: 4,
  },
  {
    id: 'input5', autoFocus: false, position: 5,
  },
];
const initialState = {
  email: '',
  password: '',
  verificationCode: '',
  input1: '',
  input2: '',
  input3: '',
  input4: '',
  input5: '',
};

class ResetPasswordDialog extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  handleCloseDialog = () => {
    const { handleCloseDialog } = this.props;
    this.setState(initialState);
    handleCloseDialog('forgotPasswordDialogOpen');
  };

  handleChange = (event) => {
    const { type } = this.props;
    if (type === 'forgotPassword') {
      this.setState({ email: event.target.value });
    } else {
      this.setState({ password: event.target.value });
    }
  };

  setInputRef = (input, position) => {
    switch (position) {
      case 2:
        this.input2 = input;
        break;
      case 3:
        this.input3 = input;
        break;
      case 4:
        this.input4 = input;
        break;
      case 5:
        this.input5 = input;
        break;
      default:
        this.input1 = input;
        break;
    }
  };

  handleTokenInputChange = (e, fieldName) => {
    if (!this.state[fieldName]) {
      switch (fieldName) {
        case 'input1':
          this.input2.focus();
          break;
        case 'input2':
          this.input3.focus();
          break;
        case 'input3':
          this.input4.focus();
          break;
        case 'input4':
          this.input5.focus();
          break;
        default:
          break;
      }
    }

    this.setState({
      [fieldName]: e.target.value,
    });
  };

  renderInputs = () => {
    const { classes, type, requireVerification } = this.props;

    if (type === 'forgotPassword') {
      return (
        <TextField
          name="Email Address"
          placeholder="Email Address"
          rowsMax={1}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          onChange={e => this.handleChange(e)}
        />
      );
    }

    return (
      <div>
        <div className={classes.textFieldContainer}>
          {
            requireVerification
            && textFieldInputs.map(fieldInput => (
              <TextField
                key={fieldInput.id}
                margin="normal"
                variant="outlined"
                className={classes.textFieldVerificationCode}
                autoFocus={fieldInput.autoFocus}
                inputProps={{
                  style: { textAlign: 'center', MozAppearance: 'textfield' },
                  maxLength: 1,
                }}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1);
                }}
                inputRef={input => this.setInputRef(input, fieldInput.position)}
                id={fieldInput.id}
                type="number"
                onChange={e => this.handleTokenInputChange(e, fieldInput.id)}
              />
            ))
          }
        </div>
        <TextField
          name="New Password"
          placeholder="New Password"
          rowsMax={1}
          className={classes.textField}
          margin="normal"
          variant="outlined"
          type="password"
          onChange={e => this.handleChange(e)}
        />
      </div>
    );
  };

  isDisabled = () => {
    const { type, requireVerification } = this.props;
    const {
      email, input1, input2, input3, input4, input5, password,
    } = this.state;

    if (type === 'forgotPassword' && !isEmpty(email)) {
      return false;
    }
    if (!requireVerification && password) {
      return false;
    }
    return isEmpty(input1 && input2 && input3 && input4 && input5 && password);
  };

  render() {
    const {
      classes, open, handleOnClick, description,
      title, type, disableClose, disableEscapeKeyDown, disableBackdropClick,
    } = this.props;
    const {
      email, input1, input2, input3, input4, input5, password,
    } = this.state;
    const inputs = {
      email,
      verificationCode: Number(input1 + input2 + input3 + input4 + input5),
      password,
    };
    const minHeight = type === 'forgotPassword' ? '450px' : '553px';

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: { padding: '24px 4px', minWidth: '310px', minHeight },
        }}
        open={open}
        onClose={(_, reason) => {
          if (reason === 'backdropClick') {
            disableBackdropClick()
          }

          this.handleCloseDialog('forgotPasswordDialogOpen')
        }}
        disableEscapeKeyDown={disableEscapeKeyDown}
      >
        <DialogTitle style={{ padding: '10px 10%' }}>
          <div className={classes.titleContainer}>
            <Icon className={classes.icon}>
              <img src={`${IMG_STORAGE_LINK}lock-outline.svg`} />
            </Icon>
            <Typography color="primary" className={classes.title}>{title}</Typography>
          </div>
          {!disableClose
            && (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => this.handleCloseDialog('forgotPasswordDialogOpen')}
              >
                <CloseIcon />
              </IconButton>
            )
          }
        </DialogTitle>
        <DialogContent style={{ padding: '10px 10%' }}>
          <Typography gutterBottom align="center">
            {description}
          </Typography>
          {this.renderInputs()}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            className={classes.button}
            onClick={() => handleOnClick(type, inputs)}
            color="primary"
            disabled={this.isDisabled()}
            fullWidth
          >
            {type === 'forgotPassword' ? 'SEND EMAIL' : 'SAVE'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ResetPasswordDialog.propTypes = {
  type: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  requireVerification: PropTypes.bool,
  disableClose: PropTypes.bool,
  disableBackdropClick: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
};

ResetPasswordDialog.defaultProps = {
  requireVerification: true,
  disableClose: false,
  disableBackdropClick: false,
  disableEscapeKeyDown: false,
};

export default withStyles(forgotPasswordDialogs)(withRouter(ResetPasswordDialog));
