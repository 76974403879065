import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withRouter } from 'react-router-dom';
import { JOB_CATEGORIES } from '../../../config/enums';
import { AboutYouComponentStyle } from '../../../css/style';

const JOB_CATEGORY = 'job-category';

class AboutYouComponent extends Component {
  constructor(props) {
    super(props);
    const { initState, handleStateChange } = props;
    let initJob = '';
    if (initState.length > 0) {
      initJob = initState[0].strValue;
      handleStateChange(initState, 0);
      handleStateChange(initState, 1);
    }
    const isOther = initJob && JOB_CATEGORIES.filter(item => item.key === initJob).length === 0;
    this.state = {
      jobCategory: isOther ? 'other' : initJob,
      other: isOther ? initJob : '',
    };
  }

  componentDidMount() {
    this.setState({
      labelWidth: ReactDOM.findDOMNode(this.InputLabelRef).offsetWidth,
    });
  }

  handleChange(event) {
    const { handleStateChange } = this.props;
    const { jobCategory } = this.state;
    const itemObj = {
      criteria: 'job-category',
      strValue: jobCategory,
    };

    // Job category 'other' requires text input to be filled out
    if (event.target.value === 'other') {
      this.setState({ jobCategory: event.target.value });
      itemObj.strValue = this.state.other;
      handleStateChange([itemObj], 0);
      return;
    }

    if (event.target.name === JOB_CATEGORY) {
      itemObj.strValue = event.target.value;
      handleStateChange([itemObj], 0);
      this.setState({
        jobCategory: event.target.value,
      });
    } else if (event.target.name === 'other') {
      itemObj.strValue = event.target.value;
      handleStateChange([itemObj], 0);
      this.setState({ other: event.target.value });
    }
  }

  render() {
    const {
      jobCategory,
      other,
    } = this.state;
    const { classes } = this.props;
    return (
      <Grid container spacing={1} justifyContent="center" alignItems="stretch">
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel
              ref={(ref) => {
                this.InputLabelRef = ref;
              }}
              classes={{
                root: classes.input,
              }}
              htmlFor="outlined-input"
            >
              Job Category
            </InputLabel>
            <Select
              value={jobCategory}
              onChange={e => this.handleChange(e)}
              classes={{
                root: classes.input,
              }}
              input={(
                <OutlinedInput
                  labelWidth={this.state.labelWidth}
                  name={JOB_CATEGORY}
                  id="outlined-input"
                />
              )}
            >
              {
                JOB_CATEGORIES.map(category => (
                  <MenuItem key={category.key} value={category.key}>
                    {category.category}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        {
          this.state.jobCategory === 'other' ? (
            <Grid item xs={12}>
              <TextField
                name="other"
                placeholder="What is your job category"
                className={classes.textField}
                margin="normal"
                variant="outlined"
                value={other}
                InputProps={{
                  classes: {
                    input: classes.textFieldInput,
                  },
                }}
                onChange={e => this.handleChange(e)}
              />
            </Grid>
          ) : null
        }
      </Grid>
    );
  }
}

AboutYouComponent.propTypes = {
  handleStateChange: PropTypes.func.isRequired,
  initState: PropTypes.array.isRequired,
};

AboutYouComponent.defaultProps = {
  initState: [],
};

export default withStyles(AboutYouComponentStyle)(withRouter(AboutYouComponent));
