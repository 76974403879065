import React, { Component, Fragment } from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isEqual, isEmpty, uniq, cloneDeep,  } from 'lodash';

import {
  Icon,
  Typography,
  Paper,
  Grid,
  Button,
  Container,
  Box,
  IconButton,
  AppBar,
  withStyles,
  Select,
  OutlinedInput,
  Checkbox,
  InputAdornment,
  ListItemIcon,
  Badge,
  Divider,
  FormControl,
} from '@material-ui/core/';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EventIcon from '@material-ui/icons/Event';
import CloseIcon from '@material-ui/icons/Close';
import MatchesSearchBar from '../subcomponents/MatchesSearchBar';
import {  navy, orange, tiffany, disableGray, textColor, bgGray } from '../../css/style';
import { } from '../../css/style';
import { IMG_STORAGE_LINK, SEARCH_SHORT_TEXT } from '../constants/Constants';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { LocationSearchInput } from '../subcomponents/LocationSearchInput';
import { MatchFilterStyle } from './styles/matchFilterStyle';
import { ArrowDropDown } from '@material-ui/icons';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      width: 250,
      transform: 'translateY(50px)',
    },
  },
};
const selectInput = {
  marginRight: '8px',
  color: textColor,
  fontSize: '16px',
  fontWeight: '600',
  paddingRight: '0px',
};

const singleMenuProps = (selectedValue, textMaxWidth) => ({
  mobile: {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
        width: '100%',
        transform: `translateY(calc(90vh - ${
          selectedValue
            ? (ITEM_HEIGHT * 5 + ITEM_PADDING_TOP + 38)
            : (ITEM_HEIGHT * 5 + ITEM_PADDING_TOP)}px))`,

      },
    },
    BackdropProps: {
      style: {
        backgroundColor: '#000',
        opacity: '0.5',
      }
    }
  },
  desktop: {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
        width: textMaxWidth || 250,
        transform: selectedValue ? 'translateY(88px)' : 'translateY(50px)',
        
      },
    },
  }
});


export class MatchFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorRef: null,
      selectedItems: ['all'],
      rotationTypeSelected: '',
      openSearchDropDown: false,
      searchItems: [],
      badgeTitleSelected: '',
      selectedPracticeId: '',
    };
  }

  componentDidMount() {
    const { filterSearchTitles, filterBtns, filterPracticeId } = this.props;
    const selectedItems = !isEmpty(filterSearchTitles) && filterSearchTitles.map(t => ({
      title: t,
      color: filterBtns.find(b => b.title === t)?.color,
      count: filterBtns.find(b => b.title === t)?.count,
    }));
    const searchItems =  filterBtns?.map(r => ({
      title: r[0],
      color: r[1],
      count: r[2],
    }));

    this.setState({ selectedItems: selectedItems || [], openSearchDropDown: false, searchItems, selectedPracticeId: filterPracticeId || ''});
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { searchItems, selectedPracticeId } = prevState;
    let returnSearchItems = {};

    if (!isEmpty(nextProps.filterBtns)) {
      const nextPropsSearchItems =  nextProps.filterBtns?.map(r => ({
        title: r[0],
        color: r[1],
        count: r[2],
      }));
      if (!isEqual(nextPropsSearchItems, searchItems)) {
        returnSearchItems =  { searchItems: nextPropsSearchItems };
      }
    }
    if (nextProps.filterPracticeId && nextProps.filterPracticeId !== selectedPracticeId) {

      return { ...returnSearchItems,
        selectedPracticeId: nextProps.filterPracticeId,
        selectedPracticeName: nextProps.matchPractices.find(p => p.id === nextProps.filterPracticeId)?.name,
      };
    }
    return !isEmpty(returnSearchItems) ? returnSearchItems : null;
  }

  renderSearchMenu = () => {
    const { classes, searchHandle = () => { }, isMobile } = this.props;
    const { selectedItems = [], openSearchDropDown, searchItems  } = this.state;

    return (
      <div>
        <FormControl style={{ margin: 8, minWidth: !isEmpty(selectedItems) ?  192 : 175 }}>
        <Select
          multiple
          displayEmpty
          value={[...selectedItems]}
          open={openSearchDropDown}
          onOpen={() => this.setState({ openSearchDropDown: true })}
          onClose={() => this.setState({ openSearchDropDown: false })}
          onChange={(e) => {
            const { target: { value } } = e;
            let filteredItems =  cloneDeep(value).filter(i => !isEmpty(i));
            if (value.indexOf('') >= 0) {
              this.setState({ openSearchDropDown: false });
              return;
            }

            // Find duplicated objects in value collection
            const filteredTitles = filteredItems.map(i => i.title);
            const duplicatedTitles = filteredTitles.filter((itemTitle, index) => filteredTitles.indexOf(itemTitle) !== index);

            if (!isEmpty(duplicatedTitles)) {
              filteredItems = filteredItems.filter(item => duplicatedTitles.indexOf(item.title) < 0);
            }
            this.setState({ selectedItems: [...filteredItems] });
          }}
          inputProps={{ 'aria-label': 'Without label' }}
          MenuProps={isMobile ? {
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 6 + 32 + 32 + 8,
                width: '100%',
                transform: `translateY(calc(90vh - ${(ITEM_HEIGHT * 6 + 32 + 32 + 8)}px))`,
              },
              className: classes.searchMenu,
            },
            BackdropProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 10 + 32 + 32 + 8,
                backgroundColor: '#000',
                opacity: '0.5',
              }
            }
          } : {
            PaperProps: {
              style: {
                width: 250,
                transform: 'translateY(40px)',
              },
              className: classes.searchMenu,
            },
          }}
          className={classes.searchSelect}
          input={<OutlinedInput id="select-multiple-checkbox"/>}
          renderValue={selected => (
          selected.length
            ? (<>
                <img src={`${IMG_STORAGE_LINK}wb_search.png`} className={classes.whiskerIcon} />
                <span className={classes.searchExtraMenu}>My Searches</span>
                <InputAdornment position="end" className={classes.inputEndAdornment}>
                  <Badge badgeContent={selected.length} color="secondary" className={classes.chipBadge} />
                </InputAdornment>
              </>)
            : (<>
              <img src={`${IMG_STORAGE_LINK}wb_search.png`} className={classes.whiskerIcon} />
              <span className={classes.searchExtraMenu}>My Searches</span> </>)
          )}
        >
          <div
            className={classes.menuIcon}
            value=''
            onClick={() => {}}
          > 
            {isMobile && <Typography className={classes.searchExtraMenu} style={{ padding: '4px 8px' }}>My Searches</Typography>}
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={() => this.setState({ openSearchDropDown: false })}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {[...searchItems].map((item) => (
            <MenuItem
              key={`${item.title}_btn`}
              value={item}
              dense={false}
              className={isMobile ? classes.mMenuItem : classes.menuItem}
            >
              <ListItemIcon style= {{ minWidth: '42px' }}>
                <Checkbox checked={selectedItems && selectedItems.filter(v => isEqual(v, item)).length > 0} style={{ padding: '5px' }} />
              </ListItemIcon>
                <div
                  key={`${item.title}_icon`}
                  style={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    backgroundColor: `#${item.color}`,
                    marginRight: '4px',
                  }}
                />
                <Typography>{`${SEARCH_SHORT_TEXT[item.title] || item.title} (${item.count})`}</Typography>
            </MenuItem>
          ))}
          <Divider />
          <div
            className={classes.menuBtn}
            value=''
            onClick={() => {}}
          >
            <Button
              variant='outlined'
              color="secondary"
              className={classes.searchFilterBtn}
              onClick={e => {
                this.setState({ selectedItems: []});
                searchHandle([]);
              }}
              key='bnt_reset'
            >
              <span style={{ color: navy, textTransform: 'capitalize' }}>Reset</span>
            </Button>
            <Button
              variant='contained'
              color="secondary"
              className={classes.searchFilterBtn}
              onClick={e => {
                searchHandle(selectedItems);
              }}
              key='bnt_apply'
              style={{marginLeft: '16px'}}
            >
              <span style={{ color: 'white', textTransform: 'capitalize' }}>Apply</span>
            </Button>
          </div>
        </Select>
      </FormControl>
    </div>
    );
  };

  renderBadgeMenu = () => {
    const {
      classes,
      badgeTitles,
      isMobile,
      onChangeBadgeTitles = () => { },
    } = this.props;
    const { badgeTitleSelected } = this.state;
    const length = badgeTitleSelected?.length * 10 + 20;
    const textMaxWidth = length > 110 ?  length : 110;

    let maxWidth = 110;
    badgeTitles && badgeTitles.forEach(item => {
      const length = item?.length * 10 + 20;
      maxWidth = length > maxWidth ? length : maxWidth;
    });

    const badgeMenuProps = singleMenuProps(badgeTitleSelected,`${maxWidth}px`);

    return badgeTitles?.length > 1 && (<div>
      <Select
        key='badgesFilter'
        variant="outlined"
        value={badgeTitleSelected}
        onChange={(event) => {
          const value = event.target.value;
          this.setState({ badgeTitleSelected: value });
          onChangeBadgeTitles(value)
        }}
        className={classes.badgeSelect}
        displayEmpty
        MenuProps={isMobile ? badgeMenuProps.mobile : badgeMenuProps.desktop}
        input={<OutlinedInput
          key="select-badge-input"
          style={{ ...selectInput,
            //maxWidth: badgeTitleSelected ? '165px' : '100px'
            maxWidth: badgeTitleSelected ? `${textMaxWidth}px` : '110px',
            width: badgeTitleSelected ? `${textMaxWidth}px` : '110px',
          }}
        />}
      >
        <MenuItem value="" key='badge_all'>
          Badges
        </MenuItem>
        {
          badgeTitles && badgeTitles.map(item => <MenuItem
            key={item}
            value={item}
            style={{ textTransform: 'capitalize' }}
          >
            {item}
          </MenuItem>)
        }
      </Select></div>
    );
  };

  renderPracticeMenu = () => {
    const {
      classes,
      matchPractices = [],
      isMobile,
      onChangePractices = () => { },
    } = this.props;
    const { selectedPracticeId, selectedPracticeName = '' } = this.state;
    let textMaxWidth = selectedPracticeName?.length * 10 + 15 > 300 ? 300 : selectedPracticeName?.length * 10 + 15;
    textMaxWidth = textMaxWidth > 110 ?  textMaxWidth : 110;

    let practiceOptions = [...matchPractices];
    
    practiceOptions.unshift({ id: '', name: 'Practice' });

    let maxWidth = 110;
    practiceOptions.forEach(item => {
      maxWidth = item.name?.length * 10 + 15 > maxWidth ? item.name?.length * 10 + 15 : maxWidth;
    });
    const practiceMenuProps = singleMenuProps(selectedPracticeId,`${maxWidth}px`);

    return (<div>
      <Select
        key='practicesFilter'
        variant="outlined"
        value={selectedPracticeId}
        onChange={(event) => {
          const value = event.target.value;
          this.setState({ selectedPracticeId: value, selectedPracticeName: practiceOptions.find(item => item.id === value)?.name || '' });
          onChangePractices(value)
        }}
        className={classes.badgeSelect}
        displayEmpty
        MenuProps={isMobile ? practiceMenuProps.mobile : practiceMenuProps.desktop}
        IconComponent={!selectedPracticeId ? ArrowDropDown : "span"}
        input={<OutlinedInput
          style={{ ...selectInput, 
            maxWidth: selectedPracticeId ? `${textMaxWidth}px` : '110px',
            width: selectedPracticeId ? `${textMaxWidth}px` : '110px',
          }}
          key="select-practice-input"
        />}
        endAdornment={
          (<IconButton
            key='close-btn'
            aria-label="close"
            className={classes.filterCloseButton}
            style={{ display: selectedPracticeId ? 'block' : 'none' }}
            onClick={() => {
              this.setState({ selectedPracticeId: '', selectedPracticeName: '' });
              onChangePractices('');
            }}
          >
            <CloseIcon />
          </IconButton>) 
        }
      >
        {
          practiceOptions && practiceOptions.map(item => <MenuItem
            key={`${item.value}_${item.id}`}
            value={item.id}
            style={{ textTransform: 'capitalize' }}
          >
            {item.name}
          </MenuItem>)
        }
      </Select></div>
    );
  };

  renderExternshipFilter = () => {
    const {
      classes,
      searchTerm, setSearchTerm = () => { },
      rotationTypes,
      onChangeRotationType = () => { },
      searchByStateHandle,
      searchByState,
    } = this.props;

    return (
      <AppBar position="absolute" className={classes.appBar}>
        <Paper square className={classes.searchPaper}>
          <Grid container>
            <Grid item xs={6} className={classes.itemStart}>
              <div style={{ marginTop: 10, width: 300, marginRight: '16px' }}>
                <LocationSearchInput
                    key="searchByState"
                    variant="outlined"
                    address={searchByState}
                    onChange={(addressObj) => {
                      searchByStateHandle(addressObj?.state || '');
                    }}
                    fullWidth
                    placeholder='Search by state'
                    isSearchBar
                    stateSearch
                />
              </div>
              <div className={classes.rotationSelectContainer}>
                <div className={classes.rotationIcon} style={{ color: !!this.state.rotationTypeSelected ? '#4bbdad' : 'rgba(0,0,0,.5)'}}><AddBoxIcon /></div>
                <Select
                  key='rotationTypeSelected'
                  variant="outlined"
                  value={this.state.rotationTypeSelected}
                  onChange={(event) => {
                    const value = event.target.value;
                    this.setState({ rotationTypeSelected: value });
                    onChangeRotationType(value)
                  }}
                  className={classes.rotationSelect}
                  displayEmpty
                  MenuProps={MenuProps}
                  input={<OutlinedInput />}
                >
                  <MenuItem value="">
                    <em>Specialty</em>
                  </MenuItem>
                  {
                    rotationTypes && rotationTypes.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)
                  }
                </Select>
              </div>
            </Grid>

            <Grid item xs={6} className={classes.itemEnd}>
              <MatchesSearchBar
                value={searchTerm}
                onChange={value => setSearchTerm(value)}
                placeholder='Search description'
                onClickClearIcon={() => setSearchTerm('')}
                className={classes.matchSeachBar}
              />
            </Grid>
          </Grid>
        </Paper>
      </AppBar>
    );
  };

  renderFilter = () => {
    const {
      classes,
      searchTerm, setSearchTerm = () => { },
      badgeTitles,
      onChangeBadgeTitles = () => { },
      searchByStateHandle,
      searchByState,
      filterBtns,
    } = this.props;

    return (
      <AppBar position="absolute" className={classes.appBar}>
        <Paper square className={classes.searchPaper}>
          <Grid container>
            <Grid item xs={6} className={classes.itemStart}>
              <div>
                {this.renderSearchMenu()}
              </div>
              {this.renderPracticeMenu()}
              {this.renderBadgeMenu()}
            </Grid>

            <Grid item xs={6} className={classes.itemEnd}>
              <MatchesSearchBar
                value={searchTerm}
                onChange={value => setSearchTerm(value)}
                placeholder='Search job description'
                onClickClearIcon={() => setSearchTerm('')}
                className={classes.matchSeachBar}
              />
            </Grid>
          </Grid>
        </Paper>
      </AppBar>
    );
  };

  renderDesktop = () => {
    const {
      classes, practiceFilterMatchIds, filterSearchTitles, filterBtns, resetFilter = () => { },
      searchTerm, setSearchTerm = () => { },
      searchHandle = () => { },
      isExternshipUser,
    } = this.props;
    return isExternshipUser ? this.renderExternshipFilter() : this.renderFilter();
  };

  renderMobile = () => {
    const {
      classes,
      isExternshipUser,
    } = this.props;

    return (
      <AppBar position="absolute" className={classes.appBar}>
        <Paper square className={classes.searchPaper} style={!isExternshipUser ? { minHeight: '95px', display: 'flex', alignItems: 'center', } : {}}>
          <Grid container>
            {isExternshipUser &&
            (<><Grid item xs={8} className={classes.itemStart}>
              <div className={classes.matchTitleContainer}>
                <img src={`${IMG_STORAGE_LINK}wb_search.png`} className={classes.whiskerIcon} />
                <Typography color="secondary" className={classes.title}>Open Externships</Typography>
              </div>
            </Grid><Grid item xs={4} className={classes.itemEnd}>
                {this.renderCalendarIon()}
              </Grid></>
            )}
            {!isExternshipUser &&
            (<><Grid item xs={12}></Grid><Grid item xs={1} className={classes.itemStart}>
                {this.renderCalendarIon()}
              </Grid>
              <Grid item xs={11} className={classes.itemStart}>
                {this.renderSearchMenu()}
                {this.renderPracticeMenu()}
                {this.renderBadgeMenu()}
              </Grid></>
            )}
          </Grid>
        </Paper>
      </AppBar>
    );
  };

  renderCalendarIon = () => {
    const {
      classes, disableCalendarIcon, handleSetState = () => { }, filterDates,
    } = this.props;

    return (
      <IconButton
        disabled={disableCalendarIcon}
        onClick={() => handleSetState()}
        className={classes.calendarBtn}
      >
        {
          !disableCalendarIcon && !isEmpty(filterDates) && (
            <>
              <Typography
                style={{
                  fontSize: '12px',
                  position: 'absolute',
                  bottom: '20px',
                  left: '25px',
                  zIndex: '5',
                  color: '#ffffff',
                }}
              >
                {filterDates.length}
              </Typography>
              <Icon
                style={{
                  color: navy,
                  fontSize: '22px',
                  padding: '0',
                  position: 'absolute',
                  bottom: '18px',
                  left: '18px',
                }}
              >
                lens
              </Icon>
            </>
          )
        }
        <EventIcon />
      </IconButton>
    );
  };   

  render() {
    const { isMobile } = this.props;
    return isMobile ?
      this.renderMobile() :
      this.renderDesktop();
  }
}

MatchFilter.propTypes = {
  searchHandle: PropTypes.func,
};

MatchFilter.defaultProps = {
  searchHandle: () => {},
};

export default withStyles(MatchFilterStyle)(withRouter(MatchFilter));
